import { configureStore } from "@reduxjs/toolkit";
import { accountSlice } from "./reducers/account-reducer";
import { pageSlice } from "./reducers/page-reducer";
import { smallNavSlice } from "./reducers/small-nav-reducer";

const store = configureStore({
  reducer: {
    account: accountSlice.reducer,
    page: pageSlice.reducer,
    smallNav: smallNavSlice.reducer
  }
});

type RootState = ReturnType<typeof store.getState>;
export const accountSelector = (state: RootState) => state.account.account;
export const pageSelector = (state: RootState) => state.page;
export const smallNavSelector = (state: RootState) => state.smallNav;

export default store;
