import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useAuthHook } from "../../hooks/auth-hook";
import { accountSelector, smallNavSelector } from "../../store";
import logoWhite from "../../resources/logo-white.png";
import {
  photographerEventsUrl,
  photographerPriceQuotesItemsTemplatesUrl,
  photographerPriceQuotesTemplatesUrl,
  profileSettingsUrl
} from "../app-body";
import { AccountType } from "../../generated/swagger/auth";
import eventsIcon from "../../resources/calendar-icon.png";
import EventsClickedIcon from "../../resources/calendar-clicked-icon.png";
import { showHamburgerMenu } from "../../store/reducers/small-nav-reducer";
import priceQuotesItemsIcon from "../../resources/price-quote-item-template-icon.png";
import priceQuotesItemsClickedIcon from "../../resources/price-quote-item-template-clicked-icon.png";
import priceQuotesTemplatesIcon from "../../resources/price-quote-template-icon.png";
import priceQuotesTemplatesClickedIcon from "../../resources/price-quote-template-clicked-icon.png";
import profileSettingsIcon from "../../resources/profile-settings-logo.png";
import profileSettingsClickedIcon from "../../resources/profile-settings-clicked-logo.png";

import NavIconLink from "./nav-icon-link";
import { Button } from "picky-style";

function SmallSideNav() {
  const { t } = useTranslation();
  const account = useSelector(accountSelector);
  const smallNavState = useSelector(smallNavSelector);
  const { customerLogin, photographerLogin, logout } = useAuthHook();
  const dispatch = useDispatch();

  const width = smallNavState.showHamburgerMenu ? 100 : 0;

  const renderButtons = () => {
    if (account) {
      return (
        <Button
          text={t("components.menu.auth.logout")}
          onClick={logout}
          $wide
        />
      );
    } else {
      return (
        <>
          <Button
            onClick={photographerLogin}
            text={t("components.menu.auth.photographer-login")}
            $primary={false}
            color="white"
          />
          <Button
            onClick={customerLogin}
            text={t("components.menu.auth.customer-login")}
          />
        </>
      );
    }
  };

  const close = () => {
    dispatch(showHamburgerMenu(false));
  };

  const renderMenu = () => {
    if (!account) {
      return <></>;
    }

    if (account.type === AccountType.Photographer) {
      return (
        <ul>
          <li>
            <NavIconLink
              isMobile={true}
              name={t("components.menu.photographer.events")}
              url={photographerEventsUrl}
              icon={eventsIcon}
              iconClicked={EventsClickedIcon}
            />
          </li>
          <li>
            <NavIconLink
              isMobile={true}
              name={t(
                "components.menu.photographer.price-quotes-items-templates"
              )}
              url={photographerPriceQuotesItemsTemplatesUrl}
              icon={priceQuotesItemsIcon}
              iconClicked={priceQuotesItemsClickedIcon}
            />
          </li>
          <li>
            <NavIconLink
              isMobile={true}
              name={t("components.menu.photographer.price-quotes-templates")}
              url={photographerPriceQuotesTemplatesUrl}
              icon={priceQuotesTemplatesIcon}
              iconClicked={priceQuotesTemplatesClickedIcon}
            />
          </li>
          <li>
            <NavIconLink
              isMobile={true}
              name={t("components.menu.photographer.profile-settings")}
              url={profileSettingsUrl}
              icon={profileSettingsIcon}
              iconClicked={profileSettingsClickedIcon}
            />
          </li>
        </ul>
      );
    } else {
      return <></>;
    }
  };

  return (
    <div className="sidenav" style={{ width: `${width}%` }}>
      <a
        href="#"
        className="closebtn"
        onClick={(e) => {
          e.preventDefault();
          close();
        }}
      >
        &times;
      </a>
      <img src={logoWhite} className="logo" />

      {renderMenu()}

      <div className="buttons">{renderButtons()}</div>
    </div>
  );
}

export default SmallSideNav;
