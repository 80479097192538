import {
  Api,
  PhotographerPriceQuoteTemplate,
  PriceQuote,
  PriceQuoteTemplateSummary
} from "../../generated/swagger/price-quotes";
import { gateway } from "../../constants";

const api = new Api({ baseUrl: gateway.priceQuotes });

const getPhotographerPriceQuotesTemplates = (
  authorization: string,
  pageNumber: number,
  pageSize: number
): Promise<PriceQuoteTemplateSummary[]> => {
  return api.photographer
    .getPriceQuoteTemplates(
      {
        "page-number": pageNumber,
        "page-size": pageSize
      },
      { headers: { authorization } }
    )
    .then((response) => response.data["price-quote-template-summaries"] || []);
};

const getPhotographerPriceQuotesTemplate = (
  authorization: string,
  templateName: string
): Promise<PhotographerPriceQuoteTemplate | undefined> => {
  return api.photographer
    .getPriceQuoteTemplate(
      { "template-name": templateName },
      {
        headers: { authorization }
      }
    )
    .then((response) => response.data["price-quote-template"]);
};

const createPhotographerPriceQuotesTemplate = (
  authorization: string,
  request: PhotographerPriceQuoteTemplate
): Promise<PhotographerPriceQuoteTemplate> => {
  return api.photographer
    .createPriceQuoteTemplate(
      { "price-quote-template": request },
      { headers: { authorization } }
    )
    .then((response) => response.data["price-quote-template"] || {});
};

const updatePhotographerPriceQuotesTemplate = (
  authorization: string,
  templateName: string,
  request: PriceQuote
): Promise<PriceQuote> => {
  return api.photographer
    .updatePriceQuoteTemplate(
      { "template-name": templateName },
      { "price-quote-template": request },
      { headers: { authorization } }
    )
    .then((response) => response.data["price-quote-template"] || {});
};

const deletePhotographerPriceQuotesTemplate = (
  authorization: string,
  templateName: string
): Promise<void> => {
  return api.photographer
    .deletePriceQuoteTemplate(
      { "template-name": templateName },
      {
        headers: { authorization }
      }
    )
    .then((response) => response.data);
};

export const priceQuotesTemplateApi = {
  photographer: {
    getPhotographerPriceQuotesTemplates,
    getPhotographerPriceQuotesTemplate,
    createPhotographerPriceQuotesTemplate,
    updatePhotographerPriceQuotesTemplate,
    deletePhotographerPriceQuotesTemplate
  }
};
