import { Button } from "picky-style";
import { CSSProperties, useState } from "react";
import { Modal, Form } from "react-bootstrap";
import ValidateModal from "./validate-modal/validate-modal";
import { t } from "i18next";

export interface TextBoxModalLocale {
  cancel: string;
  approve: string;
  reset: string;
  genericError: string;
}

interface TextBoxModalProps {
  modalSize?: "sm" | "lg" | "xl";
  title: string;
  validateTitle?: string;
  validateMassage?: string;
  subTitle?: JSX.Element;
  placeholder?: string;
  numberOfRows?: number;
  locale: TextBoxModalLocale;
  value?: string;
  textAreaStyle?: CSSProperties;
  initialData?: string;
  disabled?: boolean;
  onClose: () => void;
  onApprove?: (text: string) => Promise<void> | void;
  renderAdditionalContent?: (text: string) => JSX.Element;
}
function TextBoxModal({
  modalSize,
  title,
  validateTitle,
  validateMassage,
  subTitle,
  placeholder,
  numberOfRows,
  locale,
  value,
  textAreaStyle,
  initialData,
  disabled,
  onClose,
  onApprove,
  renderAdditionalContent
}: TextBoxModalProps) {
  const [state, setState] = useState({
    text: value,
    errorMessage: undefined as string | undefined
  });

  return (
    <Modal show size={modalSize}>
      <Modal.Header>
        <Modal.Title className="modal-title">{title}</Modal.Title>
        {disabled ? undefined : (
          <ValidateModal
            title={validateTitle || ""}
            confirmationMessage={validateMassage || ""}
            locale={{
              cancel: t("generic.actions.cancel"),
              confirm: t("generic.actions.confirm")
            }}
            primary={false}
            color="grey"
            size="small"
            text={locale.reset}
            onApprove={() => {
              setState((curr) => {
                return {
                  ...curr,
                  text: initialData || value
                };
              });
            }}
          />
        )}
      </Modal.Header>
      <Modal.Body>
        <Form style={{ textAlign: "center" }}>
          <Form.Label>{subTitle}</Form.Label>
          <Form.Control
            style={textAreaStyle}
            placeholder={placeholder}
            as="textarea"
            rows={numberOfRows}
            disabled={disabled}
            onChange={(e) =>
              setState((curr) => {
                return {
                  ...curr,
                  text: e.target.value
                };
              })
            }
            value={state.text}
          />
          {state.errorMessage && (
            <Form.Label color="red">{state.errorMessage}</Form.Label>
          )}
        </Form>
      </Modal.Body>
      <Modal.Footer style={{ margin: "auto" }}>
        <div style={{ display: "flex" }}>
          <Button
            onClick={onClose}
            color="grey"
            text={locale.cancel}
            size="small"
          />
          {!disabled && (
            <Button
              onClick={async () => {
                if (!state.text) return;
                const approve = onApprove && onApprove(state.text);

                return approve instanceof Promise
                  ? approve
                      .then(() =>
                        setState((curr) => {
                          return {
                            ...curr,
                            errorMessage: undefined
                          };
                        })
                      )
                      .catch(() =>
                        setState((curr) => {
                          return {
                            ...curr,
                            errorMessage: locale.genericError
                          };
                        })
                      )
                  : approve;
              }}
              text={locale.approve}
              size="small"
            />
          )}
        </div>
      </Modal.Footer>
      {renderAdditionalContent && renderAdditionalContent(state.text || "")}
    </Modal>
  );
}
export default TextBoxModal;
