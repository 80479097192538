import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { pageSelector, accountSelector } from "../../store";
import {
  Account,
  AccountType,
  PhotographerData
} from "../../generated/swagger/auth";
import { Col, Form, Row } from "react-bootstrap";
import { useState } from "react";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import he from "react-phone-number-input/locale/he.json";
import PhotographerProfileSettings from "./photographer/photographer-profile-settings";
import { getAuthorizationHeader, updateCognitoAttributes } from "../../amplify";
import { mediaApi } from "../../gateway/media";
import { base64ToByteArray } from "../../utils/base64ToFile";
import { Button } from "picky-style";

interface Message {
  value: string;
  isError: boolean;
}

function ProfileSettings() {
  const { t } = useTranslation();
  const pageState = useSelector(pageSelector);
  const account = useSelector(accountSelector);
  const dispatch = useDispatch();

  const [state, setState] = useState({
    account: { ...account } as Account,
    message: undefined as Message | undefined,
    loading: false
  });

  const isDirty = !(
    account?.phone === state.account.phone &&
    account?.given_name === state.account.given_name &&
    account?.family_name === state.account.family_name &&
    account?.photographer_data?.business_name ===
      state.account.photographer_data?.business_name &&
    account?.photographer_data?.business_id ===
      state.account.photographer_data?.business_id &&
    account?.photographer_data?.business_logo ===
      state.account.photographer_data?.business_logo
  );

  const renderDesktopTitle = () => {
    return (
      <>
        <div className="table-headline">
          <label>{t("pages.profile-settings.generic.title")}</label>
        </div>
      </>
    );
  };

  const personalDetailsHtml = (
    <>
      <h4 style={{ paddingInlineStart: "1svw", textAlign: "start" }}>
        {t("pages.profile-settings.generic.form.personal-group.title")}
      </h4>
      <div style={{ padding: "1svw" }} className="rounded-accordion-body">
        <Row style={{ width: "auto", justifyContent: "center" }}>
          <Col xs={3} className="my-1 col-padding">
            <Form.Group className="align-right">
              <Form.Label className="label-margin-right">
                <b>
                  {t(
                    "pages.profile-settings.generic.form.personal-group.first-name"
                  )}
                </b>
              </Form.Label>
              <Form.Control
                type="text"
                onChange={(value) =>
                  setState((curr) => {
                    return {
                      ...curr,
                      account: {
                        ...curr.account,
                        given_name: value.target.value
                      }
                    };
                  })
                }
                value={state.account?.given_name || ""}
              />
            </Form.Group>
          </Col>
          <Col xs={3} className="my-1 col-padding">
            <Form.Group className="align-right">
              <Form.Label className="label-margin-right">
                <b>
                  {t(
                    "pages.profile-settings.generic.form.personal-group.last-name"
                  )}
                </b>
              </Form.Label>
              <Form.Control
                type="text"
                onChange={(value) =>
                  setState((curr) => {
                    return {
                      ...curr,
                      account: {
                        ...curr.account,
                        family_name: value.target.value
                      }
                    };
                  })
                }
                value={state.account?.family_name || ""}
              />
            </Form.Group>
          </Col>
          <Col xs={3} className="my-1 col-padding">
            <Form.Group className="align-right">
              <Form.Label className="label-margin-right">
                <b>
                  *
                  {t(
                    "pages.profile-settings.generic.form.personal-group.phone"
                  )}
                </b>
              </Form.Label>
              <PhoneInput
                required
                international={false}
                labels={he}
                defaultCountry="IL"
                value={state.account?.phone}
                onChange={(value) =>
                  setState((curr) => {
                    return {
                      ...curr,
                      account: {
                        ...curr.account,
                        phone: value
                      }
                    };
                  })
                }
                style={{ direction: "ltr" }}
                numberInputProps={{
                  className: "form-control",
                  style: { direction: "rtl" },
                  required: true
                }}
              />
            </Form.Group>
          </Col>
          <Col xs={3} className="my-1 col-padding">
            <Form.Group className="align-right">
              <Form.Label className="label-margin-right">
                <b>
                  {t(
                    "pages.profile-settings.generic.form.personal-group.email"
                  )}
                </b>
              </Form.Label>
              <Form.Control
                disabled={true}
                type="text"
                onChange={(value) =>
                  setState((curr) => {
                    return {
                      ...curr,
                      account: {
                        ...curr.account,
                        email: value.target.value
                      }
                    };
                  })
                }
                value={state.account?.email || ""}
              />
            </Form.Group>
          </Col>
        </Row>
      </div>
    </>
  );

  const accountTypeDetailsHtml = (() => {
    switch (account?.type) {
      case AccountType.Photographer:
        return (
          <>
            <PhotographerProfileSettings
              value={state.account.photographer_data}
              onChange={(photographer_data: PhotographerData) => {
                setState((curr) => {
                  return {
                    ...curr,
                    account: {
                      ...curr.account,
                      photographer_data
                    }
                  };
                });
              }}
            />
          </>
        );
      case AccountType.Customer:
        return <></>;
      default:
        return <></>;
    }
  })();

  const messageHtml = (() => {
    if (!state.message) return undefined;
    const color = state.message.isError ? "red" : "green";

    return <p style={{ color }}>{state.message.value}</p>;
  })();

  const handleSubmit = async (submitEvent: React.FormEvent) => {
    submitEvent.preventDefault();

    if (!isValidPhoneNumber(state.account.phone || "")) {
      setState((curr) => {
        return {
          ...curr,
          message: {
            isError: true,
            value: t("errors.invalid-phone")
          }
        };
      });

      return;
    }

    if (account) {
      if (!state.account || !state.account.email || !state.account.phone) {
        setState((curr) => {
          return {
            ...curr,
            message: {
              isError: true,
              value: t("errors.generic")
            }
          };
        });
        return;
      }
      setState({ ...state, loading: true });
      const updateAttributes: { [key: string]: string | undefined } = {};

      if (account.phone !== state.account.phone) {
        updateAttributes["phone_number"] = state.account.phone;
      }

      if (account.given_name !== state.account.given_name) {
        updateAttributes["given_name"] = state.account.given_name;
      }

      if (account.family_name !== state.account.family_name) {
        updateAttributes["family_name"] = state.account.family_name;
      }
      if (account?.type === AccountType.Photographer) {
        if (
          !state.account.photographer_data?.business_id ||
          !state.account.photographer_data.business_name
        ) {
          setState((curr) => {
            return {
              ...curr,
              message: {
                isError: true,
                value: t("errors.generic")
              }
            };
          });
          return;
        }
        if (
          account.photographer_data?.business_name !==
          state.account.photographer_data.business_name
        ) {
          updateAttributes["custom:business_name"] =
            state.account.photographer_data.business_name;
        }

        if (
          account.photographer_data?.business_id !==
          state.account.photographer_data.business_id
        ) {
          updateAttributes["custom:business_id"] =
            state.account.photographer_data.business_id;
        }

        if (
          state.account.photographer_data.business_logo &&
          account.photographer_data?.business_logo !==
            state.account.photographer_data.business_logo
        ) {
          const authorization = await getAuthorizationHeader();

          try {
            const businessLogo = await base64ToByteArray(
              state.account.photographer_data.business_logo
            );

            const logoUrl =
              await mediaApi.photographer.uploadPhotographerBusinessLogo(
                authorization,
                businessLogo
              );

            updateAttributes["custom:business_logo"] =
              `${logoUrl}?p=${new Date().getTime()}`;
            setState({ ...state, loading: false });
          } catch (error) {
            console.log(error);
            setState((curr) => {
              return {
                ...curr,
                message: {
                  isError: true,
                  value: t("errors.generic")
                },
                loading: false
              };
            });
            return;
          }
        }
      }
      const result = await updateCognitoAttributes(
        dispatch,
        account,
        updateAttributes
      );

      if (!result) {
        setState((curr) => {
          return {
            ...curr,
            errorMessage: t("errors.generic"),
            loading: false
          };
        });
      } else {
        setState((curr) => {
          return {
            ...curr,
            account: result,
            message: undefined,
            loading: false
          };
        });
      }
    }
  };

  const padding = pageState.isMobile ? "20px" : "50px";

  return (
    <>
      <div className="page-action-bar" />
      <div className="data-div">
        {!pageState.isMobile && renderDesktopTitle()}

        <div style={{ padding }}>
          <Form onSubmit={handleSubmit}>
            {personalDetailsHtml}
            {accountTypeDetailsHtml}

            <Row
              className="divider origin-row"
              style={{
                paddingTop: "40px",
                maxWidth: "90%",
                margin: "auto",
                marginBottom: "2svh"
              }}
            ></Row>

            <div className="center">
              <Button
                onClick={() =>
                  setState((curr) => {
                    return { ...curr, account: { ...account } as Account };
                  })
                }
                text={t("pages.profile-settings.actions.cancel")}
                color="grey"
                disabled={!isDirty}
              />
              <Button
                text={t("pages.profile-settings.actions.approve")}
                disabled={!isDirty}
                $isSubmit
                $loading={state.loading}
              />
            </div>

            {messageHtml}
          </Form>
        </div>
      </div>
    </>
  );
}

export default ProfileSettings;
