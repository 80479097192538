import { useEffect, useMemo, useState } from "react";
import { Form, Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { getAuthorizationHeader } from "../../../../amplify";
import { eventsApi } from "../../../../gateway/events";
import {
  EventType,
  PhotographerEventSummary
} from "../../../../generated/swagger/events";
import PlacesAutocomplete from "../../../places-auto-complete";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import he from "react-phone-number-input/locale/he.json";
import {
  photographerEventManageUrl,
  photographerEventsUrl,
  photographerPriceQuoteEditorUrl
} from "../../../app-body";
import { useSelector } from "react-redux";
import { pageSelector } from "../../../../store";
import { validateEmail } from "../../../../constants";
import Select from "react-select";
import { eventTypes } from "../../../../data/event-constants";
import Loading from "../../../loading";
import FormDateInput from "../../../form-date-input";
import { Button } from "picky-style";

function PhotographerEventEditor() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const pageState = useSelector(pageSelector);
  const params = useParams();
  const id = params["*"];

  const [event, setEvent] = useState({
    loading: id ? true : false,
    data: id ? undefined : ({} as PhotographerEventSummary),
    errorMessage: undefined as string | undefined,
    continueToPriceQuote: false,
    formLoading: false
  });

  const getEvent = async (
    id: number
  ): Promise<PhotographerEventSummary | undefined> => {
    const authorization = await getAuthorizationHeader();
    const photographerEventSummary =
      await eventsApi.photographer.getPhotographerEvent(authorization, id);

    return photographerEventSummary;
  };

  useEffect(() => {
    if (id) {
      getEvent(Number(id))
        .then((res) =>
          setEvent((curr) => {
            return {
              ...curr,
              loading: false,
              data: res
            };
          })
        )
        .catch(() =>
          setEvent((curr) => {
            return {
              ...curr,
              loading: false,
              data: undefined
            };
          })
        );
    }
  }, [id]);

  const eventOptions = useMemo(
    () =>
      eventTypes.map((eventType) => {
        return {
          label: t(`pages.events.enums.type.${eventType}`),
          value: eventType
        };
      }),
    []
  );

  if (event.loading) {
    return <Loading text={t("generic.fetching-data")} />;
  }

  if (!event.data) {
    return (
      <div className="center-page">
        <h1>{t("errors.page-not-found")}</h1>
      </div>
    );
  }

  const action = id ? "edit" : "create";

  const getTitleName = (): string => {
    return action === "create"
      ? "pages.events.editor.new"
      : "pages.events.editor.edit";
  };

  const renderMobileTitle = () => {
    return <div className="page-title">{t(getTitleName())}</div>;
  };

  const renderDesktopTitle = () => {
    return (
      <>
        <div className="table-headline">
          <label>{t(getTitleName())}</label>
        </div>
        <br />
      </>
    );
  };

  const handleSubmit = async (submitEvent: React.FormEvent) => {
    if (!isValidPhoneNumber(event.data?.customer_phone || "")) {
      submitEvent.preventDefault();
      setEvent({
        ...event,
        loading: false,
        errorMessage: t("errors.invalid-phone")
      });

      return;
    }

    if (
      !event.data ||
      !event.data.name ||
      !event.data.customer_email ||
      !event.data.customer_phone ||
      !event.data.location ||
      !event.data.event_time ||
      !event.data.event_type
    ) {
      submitEvent.preventDefault();
      setEvent({
        ...event,
        loading: false,
        errorMessage: t("errors.generic")
      });
      return;
    }

    setEvent({
      ...event,
      loading: true,
      errorMessage: undefined,
      formLoading: true
    });

    try {
      const authorization = await getAuthorizationHeader();
      let eventId: number | undefined;

      if (action === "create") {
        const createdEvent =
          await eventsApi.photographer.createPhotographerEvent(authorization, {
            name: event.data.name,
            customer_email: event.data.customer_email,
            customer_phone: event.data.customer_phone,
            location: event.data.location,
            setup_location: event.data.setup_location,
            number_of_invites: event.data.number_of_invites,
            event_time: event.data.event_time,
            event_type: event.data.event_type,
            notes: event.data.notes
          });

        eventId = createdEvent.id;
      } else {
        const updatedEvent =
          await eventsApi.photographer.updatePhotographerEvent(
            authorization,
            Number(id),
            {
              name: event.data.name,
              customer_email: event.data.customer_email,
              customer_phone: event.data.customer_phone,
              location: event.data.location,
              setup_location: event.data.setup_location,
              number_of_invites: event.data.number_of_invites,
              event_time: event.data.event_time,
              event_type: event.data.event_type,
              notes: event.data.notes
            }
          );

        eventId = updatedEvent.id;
      }

      const url = event.continueToPriceQuote
        ? photographerPriceQuoteEditorUrl
        : photographerEventManageUrl;

      setEvent({
        ...event,
        formLoading: false
      });

      navigate(url.replace(":eventId", (eventId || 0).toString()));
    } catch (e) {
      submitEvent.preventDefault();

      setEvent({
        ...event,
        loading: false,
        errorMessage: t("errors.generic"),
        formLoading: false
      });
    }
  };

  const abort = () => {
    navigate(photographerEventsUrl);
  };

  return (
    <>
      {pageState.isMobile && renderMobileTitle()}
      <div className="page-action-bar" />
      <div className="data-div">
        {!pageState.isMobile && renderDesktopTitle()}
        <Form style={{ height: "100%" }} onSubmit={handleSubmit}>
          <Row className="align-items-center">
            <Col xs={2} className="my-1 col-padding">
              <Form.Control
                required
                type="text"
                placeholder={t("pages.events.generic.name")}
                onChange={(e) =>
                  setEvent((curr) => {
                    return {
                      ...curr,
                      data: {
                        ...curr.data,
                        name: e.target.value
                      }
                    };
                  })
                }
                value={event.data.name || ""}
              />
            </Col>
            <Col xs={2} className="my-1 col-padding">
              <PlacesAutocomplete
                required={true}
                placeholder={t("pages.events.generic.location")}
                initialValue={event.data.location || ""}
                onChange={(newValue: string) =>
                  setEvent((curr) => {
                    return {
                      ...curr,
                      data: {
                        ...curr.data,
                        location: newValue
                      }
                    };
                  })
                }
              />
            </Col>
            <Col xs={2} className="my-1 col-padding">
              <PlacesAutocomplete
                required={false}
                placeholder={t("pages.events.generic.setup-location")}
                initialValue={event.data.setup_location || ""}
                onChange={(newValue: string) =>
                  setEvent((curr) => {
                    return {
                      ...curr,
                      data: {
                        ...curr.data,
                        setup_location: newValue
                      }
                    };
                  })
                }
              />
            </Col>
            <Col xs={2} className="my-1 col-padding">
              <FormDateInput
                required
                placeholder={t("pages.events.generic.event-time")}
                onChange={(time) =>
                  setEvent((curr) => {
                    return {
                      ...curr,
                      data: {
                        ...curr.data,
                        event_time: time
                      }
                    };
                  })
                }
                value={event.data.event_time}
              />
            </Col>
          </Row>

          <Row className="align-items-center">
            <Col xs={2} className="my-1 col-padding">
              <Form.Control
                type="number"
                placeholder={t("pages.events.generic.number-of-invites")}
                onChange={(e) =>
                  setEvent((curr) => {
                    return {
                      ...curr,
                      data: {
                        ...curr.data,
                        number_of_invites: Number(e.target.value)
                      }
                    };
                  })
                }
                value={event.data.number_of_invites || ""}
              />
            </Col>
            <Col xs={2} className="my-1 col-padding">
              <PhoneInput
                international={false}
                labels={he}
                defaultCountry="IL"
                placeholder={t("pages.events.generic.customer-phone")}
                value={event.data.customer_phone}
                onChange={(e) =>
                  setEvent((curr) => {
                    return {
                      ...curr,
                      data: {
                        ...curr.data,
                        customer_phone: e
                      }
                    };
                  })
                }
                style={{ direction: "ltr" }}
                numberInputProps={{
                  className: "form-control",
                  style: { direction: "rtl" },
                  required: true
                }}
              />
            </Col>
            <Col xs={2} className="my-1 col-padding">
              <Form.Control
                required
                type="email"
                placeholder={t("pages.events.generic.customer-email")}
                isInvalid={
                  event.data.customer_email
                    ? !validateEmail(event.data.customer_email)
                    : false
                }
                onChange={(e) =>
                  setEvent((curr) => {
                    return {
                      ...curr,
                      data: {
                        ...curr.data,
                        customer_email: e.target.value
                      }
                    };
                  })
                }
                value={event.data.customer_email || ""}
              />
            </Col>
            <Col xs={2} className="my-1 col-padding">
              <Select
                className="text-end"
                required
                name="event-type-select"
                options={eventOptions}
                placeholder={t("pages.events.generic.event-type")}
                onChange={(e) =>
                  setEvent((curr) => {
                    return {
                      ...curr,
                      data: {
                        ...curr.data,
                        event_type: e?.value as EventType | undefined
                      }
                    };
                  })
                }
                value={eventOptions.filter(
                  (option) => option.value === event.data?.event_type
                )}
                noOptionsMessage={() => {
                  return <>{t("generic.empty-results")}</>;
                }}
              />
            </Col>
          </Row>

          <Row className="align-items-center">
            <Col xs={8} className="my-1 col-padding">
              <Form.Control
                as="textarea"
                rows={5}
                placeholder={t("pages.events.generic.notes")}
                onChange={(e) =>
                  setEvent((curr) => {
                    return {
                      ...curr,
                      data: {
                        ...curr.data,
                        notes: e.target.value
                      }
                    };
                  })
                }
                value={event.data.notes || ""}
              />
              <br />
            </Col>
          </Row>

          <Row className="align-items-center editor-buttons">
            <Col
              style={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "center"
              }}
            >
              <Button
                onClick={abort}
                text={t("pages.events.editor.actions.cancel")}
                color="grey"
              />
              <Button
                text={t(`pages.events.editor.actions.${action}`)}
                $loading={event.formLoading}
                $isSubmit
              />
              <Button
                text={t(`pages.events.editor.actions.approve-and-continue`)}
                onClick={() => {
                  setEvent((curr) => {
                    return {
                      ...curr,
                      continueToPriceQuote: true
                    };
                  });
                }}
                $primary={false}
                $loading={event.formLoading}
                $isSubmit
              />
            </Col>
          </Row>
        </Form>
        {event.errorMessage && (
          <p style={{ color: "red" }}>{event.errorMessage}</p>
        )}
      </div>
    </>
  );
}

export default PhotographerEventEditor;
