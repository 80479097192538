import { useState } from "react";
import { Col, Form, InputGroup, Modal, Row } from "react-bootstrap";
import {
  ItemType,
  PriceQuoteItem
} from "../../../../../generated/swagger/price-quotes";
import { priceQuotesItemsApi } from "../../../../../gateway/price-quotes/price-quotes-items-templates";
import { getAuthorizationHeader } from "../../../../../amplify";
import { itemTypes } from "../../../../../data/price-quotes-constants";
import Select from "react-select";
import { useTranslation } from "react-i18next";
import CurrencyInput from "react-currency-input-field";
import { Button } from "picky-style";

interface PhotographerItemsTemplatesModalProps {
  initialData: PriceQuoteItem;
  close: (priceQuoteItem: PriceQuoteItem) => void;
}

function PhotographerItemsTemplatesModal({
  initialData,
  close
}: PhotographerItemsTemplatesModalProps) {
  const { t } = useTranslation();

  const [itemTemplate, setItemTemplate] = useState({
    data: { ...initialData },
    loading: false,
    errorMessage: undefined as string | undefined,
    conflictError: undefined as string | undefined
  });

  const action = initialData.name ? "edit" : "create";

  const getTitleName = (): string => {
    return action === "create"
      ? t("pages.price-quotes-items-templates.editor.new")
      : t("pages.price-quotes-items-templates.editor.edit");
  };

  const itemTypesOptions = itemTypes.map((itemType) => {
    return {
      label: t(`pages.price-quotes-items-templates.enums.type.${itemType}`),
      value: itemType
    };
  });

  const handleSubmit = async (submitEvent: React.FormEvent) => {
    submitEvent.preventDefault();
    submitEvent.stopPropagation();

    try {
      setItemTemplate((curr) => {
        return {
          ...curr,
          loading: true
        };
      });

      const authorization = await getAuthorizationHeader();

      if (action === "create") {
        const createdItem =
          await priceQuotesItemsApi.photographer.createPhotographerPriceQuotesItemTemplate(
            authorization,
            itemTemplate.data
          );

        close(createdItem);
      } else {
        if (initialData.name) {
          const updatedItem =
            await priceQuotesItemsApi.photographer.updatePhotographerPriceQuotesItemTemplate(
              authorization,
              initialData.name,
              itemTemplate.data
            );
          updatedItem && close(updatedItem);
        }
      }
    } catch (e) {
      if (e instanceof Response) {
        if (e.status === 409) {
          setItemTemplate((curr) => {
            return {
              ...curr,
              conflictError: t(
                "pages.price-quotes-items-templates.errors.already-exists"
              ),
              errorMessage: undefined,
              loading: false
            };
          });

          return;
        }
      }

      setItemTemplate({
        ...itemTemplate,
        errorMessage: t("errors.general"),
        conflictError: undefined,
        loading: false
      });
    }
  };

  return (
    <Modal show>
      <Modal.Header>
        <Modal.Title className="modal-title">{getTitleName()}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Row className="align-items-center">
            <Col xs={2} className="my-1 col-padding" style={{ flex: "1" }}>
              <InputGroup hasValidation>
                <Form.Control
                  required
                  maxLength={200}
                  type="text"
                  placeholder={t(
                    "pages.price-quotes-items-templates.generic.name"
                  )}
                  onChange={(e) =>
                    setItemTemplate((curr) => {
                      return {
                        ...curr,
                        data: {
                          ...curr.data,
                          name: e.target.value
                        }
                      };
                    })
                  }
                  value={itemTemplate.data?.name || ""}
                  isInvalid={itemTemplate.conflictError ? true : false}
                />
                <Form.Control.Feedback type="invalid">
                  {itemTemplate.conflictError}
                </Form.Control.Feedback>
              </InputGroup>
            </Col>
          </Row>

          <Row className="align-items-center">
            <Col xs={2} className="my-1 col-padding" style={{ flex: "1" }}>
              <Select
                required
                name="item-type-select"
                options={itemTypesOptions}
                placeholder={t(
                  "pages.price-quotes-items-templates.generic.item-type"
                )}
                onChange={(e) => {
                  setItemTemplate((curr) => {
                    return {
                      ...curr,
                      data: {
                        ...curr.data,
                        "item-type": e?.value as ItemType | undefined
                      }
                    };
                  });
                }}
                value={itemTypesOptions.filter(
                  (option) => option.value === itemTemplate.data?.["item-type"]
                )}
                noOptionsMessage={() => {
                  return <>{t("generic.empty-results")}</>;
                }}
              />
            </Col>
            <Col xs={2} className="my-1 col-padding" style={{ flex: "1" }}>
              <CurrencyInput
                required
                className="form-control"
                suffix={t("currencies.ils")}
                decimalsLimit={2}
                step={0.1}
                placeholder={t(
                  "pages.price-quotes-items-templates.generic.price"
                )}
                onValueChange={(value) =>
                  setItemTemplate((curr) => {
                    return {
                      ...curr,
                      data: {
                        ...curr.data,
                        price: Number(value)
                      }
                    };
                  })
                }
                defaultValue={itemTemplate.data?.price || ""}
              />
            </Col>
          </Row>

          <div className="center">
            <Button
              text={t(
                "pages.price-quotes-items-templates.editor.actions.cancel"
              )}
              onClick={(e) => close(initialData)}
              color="grey"
            />
            <Button
              text={t("pages.price-quotes-items-templates.editor.actions.save")}
              $isSubmit
              $loading={itemTemplate.loading}
            />
          </div>
          {itemTemplate.errorMessage && (
            <p style={{ color: "red" }}>{itemTemplate.errorMessage}</p>
          )}
        </Form>
      </Modal.Body>
    </Modal>
  );
}

export default PhotographerItemsTemplatesModal;
