import { useMemo, useState } from "react";
import { Col, Form, InputGroup, Row, Table } from "react-bootstrap";
import { Trans, useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  PhotographerPriceQuoteTemplate,
  DiscountType,
  PriceQuoteItem,
  PhotographerPriceQuote
} from "../../../../../../generated/swagger/price-quotes";
import { pageSelector } from "../../../../../../store";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import he from "react-phone-number-input/locale/he.json";
import CurrencyInput from "react-currency-input-field";
import Select from "react-select";
import {
  discountTypes,
  itemTypes
} from "../../../../../../data/price-quotes-constants";

import PhotographerPriceQuotesItemPickerModal from "./photographer-price-quotes-item-picker-modal";
import WidthResponsive from "../../../../../width-responsive";
import PhotographerDesktopLineItemTable from "./photographer-desktop-line-item-table";
import PhotographerMobileLineItemTable from "./photographer-mobile-line-item-table";
import { Button } from "picky-style";

interface Locale {
  title: string;
  name: string;
  namePlaceholder: string;
  submit: string;
  abort: string;
}

interface Render {
  beforeForm?: JSX.Element;
  expiration: JSX.Element;
  additionalButtons?: JSX.Element[];
}

enum Modals {
  None,
  LoadItem,
  AddLine
}

export interface PhotographerPriceQuotesFormProps<
  PQ extends PhotographerPriceQuoteTemplate | PhotographerPriceQuote
> {
  locale: Locale;
  isRequired: boolean;
  value: PQ;
  render: Render;
  backUrl: string;
  conflictError?: string;
  errorMessage?: string;
  onChange: (value: PQ) => void;
  onError: (error?: string) => void;
  onSubmit: () => void;
}

function PhotographerPriceQuotesForm<
  PQ extends PhotographerPriceQuoteTemplate | PhotographerPriceQuote
>({
  locale,
  isRequired,
  value,
  render,
  backUrl,
  conflictError,
  errorMessage,
  onChange,
  onError,
  onSubmit
}: PhotographerPriceQuotesFormProps<PQ>) {
  const { t } = useTranslation();
  const pageState = useSelector(pageSelector);
  const navigate = useNavigate();

  const [state, setState] = useState({
    modal: Modals.None
  });

  const itemTypesOptions = useMemo(
    () =>
      itemTypes.map((itemType) => {
        return {
          label: t(`pages.price-quotes-items-templates.enums.type.${itemType}`),
          value: itemType
        };
      }),
    []
  );

  const DiscountTypesOptions = useMemo(
    () =>
      discountTypes.map((discountType) => {
        return {
          label: t(
            `components.photographer.price-quotes-form.enums.discount-type.${discountType}`
          ),
          value: discountType
        };
      }),
    []
  );

  const renderMobileTitle = () => {
    return <div className="page-title">{locale.title}</div>;
  };

  const renderDesktopTitle = () => {
    return (
      <>
        <div className="table-headline">
          <label>{locale.title}</label>
        </div>
        <br />
      </>
    );
  };

  const abort = () => {
    navigate(backUrl);
  };

  const lineItemsTotalPrice =
    value["line-items"]?.reduce(
      (a, b) => a + (b.price || 0) * (b.quantity || 0),
      0
    ) || 0;

  const discount = (() => {
    const discountValue = value.discount?.value || 0;

    if (discountValue <= 0) {
      return 0;
    }

    if (value.discount?.type === DiscountType.Fixed) {
      return discountValue;
    } else {
      return (discountValue / 100) * (lineItemsTotalPrice || 0);
    }
  })();

  const tax = ((value.tax || 0) / 100) * (lineItemsTotalPrice - discount);

  const totalPrice = lineItemsTotalPrice - discount + tax;

  const handleSubmit = async (submitEvent: React.FormEvent) => {
    submitEvent.preventDefault();

    const phone = value["photographer-phone"] || "";

    const shouldValidatePhone = isRequired || phone.length > 0;

    if (shouldValidatePhone && !isValidPhoneNumber(phone)) {
      onError(t("errors.invalid-phone"));
      return;
    }

    onSubmit();
  };

  const maxDiscountValue = (() => {
    if (value.discount?.type == DiscountType.Percentage) return 100;
    else return lineItemsTotalPrice;
  })();

  const maxDownPaymentValue = totalPrice;

  const headerHtml = (
    <Row>
      <Col className="my-1 col-padding">
        <Form.Group className="align-right">
          <Form.Label className="label-margin-right">
            <b>{locale.name}</b>
          </Form.Label>
          <InputGroup hasValidation>
            <Form.Control
              required
              type="text"
              placeholder={locale.namePlaceholder}
              onChange={(e) => onChange({ ...value, name: e.target.value })}
              value={value.name || ""}
              isInvalid={conflictError !== undefined}
            />
            <Form.Control.Feedback type="invalid">
              {conflictError}
            </Form.Control.Feedback>
          </InputGroup>
        </Form.Group>
      </Col>
      <Col xs="auto" className="my-1 col-padding">
        <Form.Group className="align-right">
          <Form.Label className="label-margin-right">
            <b>
              {t(
                "components.photographer.price-quotes-form.generic.photographer-phone"
              )}
            </b>
          </Form.Label>
          <PhoneInput
            international={false}
            labels={he}
            defaultCountry="IL"
            required={isRequired}
            placeholder={t(
              "components.photographer.price-quotes-form.generic.photographer-phone-placeholder"
            )}
            onChange={(e) => onChange({ ...value, "photographer-phone": e })}
            value={value["photographer-phone"] || ""}
            style={{ direction: "ltr" }}
            numberInputProps={{
              className: "form-control",
              style: { direction: "rtl" },
              required: true
            }}
          />
        </Form.Group>
      </Col>
      <Col xs="2" className="my-1 col-padding">
        {render.expiration}
      </Col>
    </Row>
  );

  const tableActions = (
    <div className="flex-space-between margin-top-1">
      <Form.Label className="label-margin-right">
        <b>
          {t(
            "components.photographer.price-quotes-form.generic.line-items.title"
          )}
        </b>
      </Form.Label>

      <div className="price-quotes-form-action-buttons">
        {pageState.isMobile && (
          <Button
            text={t(
              "components.photographer.price-quotes-form.actions.add-line"
            )}
            onClick={() => {
              setState((currState) => {
                return {
                  ...currState,
                  modal: Modals.AddLine
                };
              });
            }}
            $primary={false}
            color="turquoise"
            size="tiny"
            $loadable={false}
          />
        )}
        <Button
          text={t("components.photographer.price-quotes-form.actions.add-item")}
          onClick={() => {
            setState((currState) => {
              return {
                ...currState,
                modal: Modals.LoadItem
              };
            });
          }}
          $primary={false}
          color="turquoise"
          size="tiny"
          $loadable={false}
        />
        {render.additionalButtons}
      </div>
    </div>
  );

  const summaryHtml = (
    <div className="price-quote-summary-container">
      <div className="price-quote-summary">
        <Row
          className="flex-space-between"
          style={{ width: "100%", marginBottom: "20px" }}
        >
          <Col style={{ padding: "0" }}></Col>
          <Col
            style={{
              display: "flex",
              alignItems: "center",
              padding: "0"
            }}
          >
            <Col>
              <b>
                {t(
                  "components.photographer.price-quotes-form.generic.total-before-discount"
                )}
              </b>
            </Col>
            <Col>
              <b>{lineItemsTotalPrice.toFixed(2) + t("currencies.ils")}</b>
            </Col>
          </Col>
        </Row>
        <Row
          style={{
            display: "flex",
            alignItems: "center",
            width: "100%",
            marginBottom: "20px"
          }}
        >
          <Col
            style={{
              display: "flex",
              alignItems: "center",
              padding: "0",
              marginLeft: "5px"
            }}
          >
            <Col style={{ textAlign: "end", marginLeft: "15px" }}>
              <b>
                {t(
                  "components.photographer.price-quotes-form.generic.discount"
                )}
              </b>
            </Col>
            <Col style={{ minWidth: "75px" }}>
              <Select
                name="discount-type-select"
                placeholder=""
                options={DiscountTypesOptions}
                onChange={(e) =>
                  onChange({
                    ...value,
                    discount: { ...value.discount, type: e?.value, value: 0 }
                  })
                }
                value={(() => {
                  const filteredValue = DiscountTypesOptions.filter(
                    (option) => option.value === value.discount?.type
                  );

                  return filteredValue.length > 0
                    ? filteredValue
                    : [DiscountTypesOptions[0]];
                })()}
                noOptionsMessage={() => {
                  return <>{t("generic.empty-results")}</>;
                }}
              />
            </Col>
          </Col>
          <Col
            style={{
              display: "flex",
              alignItems: "center",
              padding: "0"
            }}
          >
            <Col style={{ width: "100px" }}>
              <Form.Control
                type="text"
                min={0}
                max={maxDiscountValue}
                onChange={(e) =>
                  onChange({
                    ...value,
                    discount: {
                      ...value.discount,
                      value: Number(Number(e.target.value).toFixed(2))
                    }
                  })
                }
                value={value.discount?.value || 0}
                isInvalid={
                  (value.discount?.value || 0) < 0 ||
                  (value.discount?.value || 0) > maxDiscountValue
                }
              />
            </Col>
            <Col>
              <b> {discount.toFixed(2) + t("currencies.ils")}</b>
            </Col>
          </Col>
        </Row>

        <Row style={{ marginBottom: "20px", width: "100%" }}>
          <Col style={{ textAlign: "end", alignItems: "center", padding: "0" }}>
            <input
              style={{ height: "17px", width: "17px" }}
              type="checkbox"
              onChange={() => {
                onChange({
                  ...value,
                  tax: value.tax ? 0 : 17
                });
              }}
              checked={!!value.tax}
            />
          </Col>
          <Col style={{ display: "flex", padding: "0" }}>
            <Col style={{ display: "flex" }}>
              <Col>
                <b>
                  {t("components.photographer.price-quotes-form.generic.tax")}
                </b>
              </Col>
              <Col style={{ textAlign: "start" }}>17%</Col>
            </Col>

            <Col>
              <b>{tax.toFixed(2) + t("currencies.ils")}</b>
            </Col>
          </Col>
        </Row>
        <br />
        <Row style={{ width: "100%" }}>
          <Col></Col>
          <Col
            style={{
              border: "solid",
              borderRadius: "20px",
              borderWidth: "thin"
            }}
          >
            <Row
              style={{
                display: "flex",
                alignItems: "center",
                width: "100%",
                margin: 0
              }}
            >
              <Col>
                <b>
                  <Trans i18nKey="components.photographer.price-quotes-form.generic.total-price" />
                </b>
              </Col>
              <Col style={{ padding: 0 }}>
                {totalPrice.toFixed(2) + t("currencies.ils")}
              </Col>
            </Row>
            <Row
              className="divider"
              style={{ paddingTop: "10px", width: "90%", margin: "auto" }}
            ></Row>

            <Row
              style={{
                display: "flex",
                alignItems: "center",
                width: "100%",
                margin: 0
              }}
            >
              <Col>
                <p>
                  <Trans i18nKey="components.photographer.price-quotes-form.generic.down-payment-label" />
                </p>
              </Col>
              <Col style={{ minWidth: "105px" }}>
                <CurrencyInput
                  style={{ textAlign: "center" }}
                  max={maxDownPaymentValue}
                  required={isRequired}
                  className="form-control"
                  suffix={t("currencies.ils")}
                  decimalsLimit={2}
                  step={0.1}
                  onValueChange={(downPayment) => {
                    const newValue = Number(
                      Number(downPayment || 0).toFixed(2)
                    );

                    if (newValue <= maxDownPaymentValue && newValue >= 0) {
                      onChange({
                        ...value,
                        "down-payment": Number(Number(newValue).toFixed(2))
                      });
                    }
                  }}
                  value={value["down-payment"] || 0}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </div>
  );

  const actionButtonsHtml = (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <Button text={locale.abort} onClick={abort} color="grey" />
      <Button text={locale.submit} $isSubmit />
    </div>
  );

  const extraDetailsHtml = (
    <div>
      <div style={{ display: "flex" }}>
        <Form.Label className="label-margin-right">
          <b>{t("components.photographer.price-quotes-form.generic.notes")}</b>
        </Form.Label>
      </div>
      <Form.Control
        as="textarea"
        rows={5}
        placeholder={t("generic.generic-message-placeholder")}
        onChange={(e) => onChange({ ...value, notes: e.target.value })}
        value={value.notes || ""}
      />

      <br />

      <div style={{ display: "flex" }}>
        <Form.Label className="label-margin-right">
          <b>
            {t(
              "components.photographer.price-quotes-form.generic.email-content"
            )}
          </b>
        </Form.Label>
      </div>
      <Form.Control
        as="textarea"
        rows={5}
        placeholder={t("generic.generic-message-placeholder")}
        onChange={(e) =>
          onChange({ ...value, "email-content": e.target.value })
        }
        value={value["email-content"] || ""}
      />
      <br />
    </div>
  );

  return (
    <>
      <PhotographerPriceQuotesItemPickerModal
        show={state.modal === Modals.LoadItem}
        onItemsPick={(items: PriceQuoteItem[]) => {
          setState((currState) => {
            return {
              ...currState,
              modal: Modals.None
            };
          });

          if (items.length === 0) return;

          const currLineItems = value["line-items"] || [];

          onChange({
            ...value,
            "line-items": [...currLineItems, ...items]
          });
        }}
      />

      {pageState.isMobile && renderMobileTitle()}
      <div className="page-action-bar" />
      <div className="data-div">
        {!pageState.isMobile && renderDesktopTitle()}
        {render.beforeForm}
        <Form
          className="center-with-margin"
          style={{ height: "100%", paddingBottom: "75px" }}
          onSubmit={handleSubmit}
        >
          {headerHtml}
          <Row className="divider" style={{ paddingTop: "10px" }}></Row>
          {tableActions}

          <WidthResponsive
            minWidth={850}
            bigComponent={
              <PhotographerDesktopLineItemTable
                lineItems={value["line-items"] || []}
                onChange={(lineItems) => {
                  onChange({ ...value, "line-items": lineItems });
                }}
                itemTypesOptions={itemTypesOptions}
              />
            }
            smallComponent={
              <PhotographerMobileLineItemTable
                lineItems={value["line-items"] || []}
                showAddLineItemModal={state.modal === Modals.AddLine}
                closeAddLineItemModal={() => {
                  setState((currState) => {
                    return { ...currState, modal: Modals.None };
                  });
                }}
                onChange={(lineItems) => {
                  onChange({ ...value, "line-items": lineItems });
                }}
              />
            }
          />

          {summaryHtml}
          {extraDetailsHtml}
          {actionButtonsHtml}
        </Form>
        {errorMessage && <p style={{ color: "red" }}>{errorMessage}</p>}
      </div>
    </>
  );
}

export default PhotographerPriceQuotesForm;
