/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface GetPriceQuoteResponse {
  "price-quote"?: PhotographerPriceQuote;
}

export interface UpsertPriceQuoteRequest {
  "price-quote": PhotographerPriceQuote;
}

export interface UploadFileApproveRequest {
  /** @format byte */
  file: string;
  name: string;
}

export interface RemoveFileApproveRequest {
  md5: string;
}

export interface UpsertPriceQuoteResponse {
  "price-quote"?: PhotographerPriceQuote;
}

export interface SendEmailRequest {
  subject: string;
  to: Email[];
  cc?: Email[];
  bcc?: Email[];
}

export interface CreatePriceQuoteItemTemplateRequest {
  "price-quote-item-template": PriceQuoteItem;
}

export interface CreatePriceQuoteItemTemplateResponse {
  "price-quote-item-template"?: PriceQuoteItem;
}

export interface GetPriceQuoteItemsTemplatesResponse {
  "price-quote-items-templates"?: PriceQuoteItem[];
}

export interface GetPriceQuoteItemTemplateResponse {
  "price-quote-item"?: PriceQuoteItem;
}

export interface UpdatePriceQuoteItemTemplateRequest {
  "price-quote-item-template": PriceQuoteItem;
}

export interface UpdatePriceQuoteItemTemplateResponse {
  "price-quote-item"?: PriceQuoteItem;
}

export interface CreatePriceQuoteTemplateRequest {
  "price-quote-template": PhotographerPriceQuoteTemplate;
}

export interface CreatePriceQuoteTemplateResponse {
  "price-quote-template"?: PhotographerPriceQuoteTemplate;
}

export interface GetPriceQuoteTemplatesResponse {
  "price-quote-template-summaries"?: PriceQuoteTemplateSummary[];
}

export interface GetPriceQuoteTemplateResponse {
  "price-quote-template"?: PhotographerPriceQuoteTemplate;
}

export interface UpdatePriceQuoteTemplateRequest {
  "price-quote-template": PhotographerPriceQuoteTemplate;
}

export interface UpdatePriceQuoteTemplateResponse {
  "price-quote-template"?: PhotographerPriceQuoteTemplate;
}

export interface GetPriceQuoteAdditionalDataResponse {
  "price-quote-additional-data"?: PhotographerPriceQuoteAdditionalData;
}

export interface UpsertPriceQuoteAdditionalDataRequest {
  "price-quote-additional-data": PhotographerPriceQuoteAdditionalData;
}

export interface UpsertPriceQuoteAdditionalDataResponse {
  "price-quote-additional-data"?: PhotographerPriceQuoteAdditionalData;
}

export interface GetCustomerPriceQuoteResponse {
  "price-quote"?: PhotographerPriceQuote;
  event?: Event;
}

/** @format byte */
export type CustomerPriceQuotePdfResponse = string;

export interface RejectPriceQuoteRequest {
  reject: RejectPriceQuote;
}

/** @format byte */
export type PhotographerPriceQuotePdfResponse = string;

export interface PhotographerSharePriceQuoteResponse {
  url?: string;
}

export interface PriceQuote {
  /** @maxLength 50 */
  name?: string;
  /** @maxLength 15 */
  "photographer-phone"?: string;
  discount?: Discount;
  /** @format float */
  tax?: number;
  /** @format float */
  "down-payment"?: number;
  "line-items"?: LineItem[];
  /** @maxLength 500 */
  notes?: string;
  /** @maxLength 5000 */
  "email-content"?: string;
  /** @format int64 */
  "update-time"?: number;
}

export type PhotographerPriceQuote = PriceQuote & {
  terms?: string;
  /** only one of approved/rejected will populated */
  "customer-response"?: CustomerResponse;
  /** @format int64 */
  "expiration-date"?: number;
  /** @format float */
  "total-price"?: number;
};

export type PhotographerPriceQuoteTemplate = PriceQuote & {
  "expiration-date"?: ExpirationDate;
};

export interface PriceQuoteTemplateSummary {
  name?: string;
  /** @format int64 */
  "items-count"?: number;
  /** @format float */
  "total-price"?: number;
  /** @format int64 */
  "last-update"?: number;
}

export type LineItem = PriceQuoteItem & {
  /** @format int64 */
  quantity?: number;
};

export interface PriceQuoteItem {
  /** @maxLength 200 */
  name?: string;
  "item-type"?: ItemType;
  /** @format float */
  price?: number;
}

export interface Discount {
  type?: DiscountType;
  /** @format float */
  value?: number;
}

export enum DiscountType {
  Fixed = "fixed",
  Percentage = "percentage"
}

export enum ItemType {
  CrewMember = "crew-member",
  Product = "product",
  LinkedEvent = "linked-event",
  Other = "other"
}

export interface PhotographerPriceQuoteAdditionalData {
  terms?: string;
  business_logo?: string;
}

export interface Event {
  /** @format int64 */
  id?: number;
  name?: string;
  /** @format email */
  customer_email?: string;
  customer_phone?: string;
  location?: string;
  setup_location?: string;
  /** @format int32 */
  number_of_invites?: number;
  /** @format int64 */
  event_time?: number;
  event_type?: EventType;
  event_status?: EventStatus;
}

export enum EventType {
  Wedding = "wedding",
  Engagement = "engagement",
  Stylish = "stylish",
  Sport = "sport",
  Family = "family",
  Pregnancy = "pregnancy",
  Institution = "institution",
  Party = "party",
  BabyBirth = "baby-birth",
  SweetTeen = "sweet-teen",
  Henna = "henna",
  Other = "other"
}

export enum EventStatus {
  Draft = "draft",
  PriceQuote = "price-quote",
  Event = "event",
  Finished = "finished",
  Archived = "archived"
}

/** only one of approved/rejected will populated */
export interface CustomerResponse {
  /** @format int64 */
  "last-seen"?: number;
  approved?: ApprovePriceQuote;
  rejected?: RejectPriceQuote;
}

export interface ExpirationDate {
  type?: ExpirationType;
  /** @format int64 */
  value?: number;
}

export enum ExpirationType {
  OffsetDays = "offset_days",
  Date = "date"
}

export type ApprovePriceQuote = ApprovedPriceQuoteFile & {
  finalized?: boolean;
};

export interface ApprovedPriceQuoteFile {
  files?: Record<string, ApprovedPriceQuoteFileValue>;
}

export interface ApprovedPriceQuoteFileValue {
  name?: string;
  file?: string;
}

export interface RejectPriceQuote {
  reason?: string;
}

/**
 * @format email
 * @maxLength 255
 */
export type Email = string;

export interface RestError {
  error?: string;
  /** @format int32 */
  code?: number;
}

export interface GetPriceQuoteParams {
  /** @format int64 */
  "event-id": number;
}

export type GetPriceQuoteError = RestError;

export interface UpsertPriceQuoteParams {
  /** @format int64 */
  "event-id": number;
}

export type UpsertPriceQuoteError = RestError;

export interface DeletePriceQuoteParams {
  /** @format int64 */
  "event-id": number;
}

export type DeletePriceQuoteError = RestError;

export interface SendEmailPriceQuoteParams {
  /** @format int64 */
  "event-id": number;
}

export type SendEmailPriceQuoteError = RestError;

export interface PhotographerApprovePriceQuoteParams {
  /** @format int64 */
  "event-id": number;
}

export type PhotographerApprovePriceQuoteError = RestError;

export interface PhotographerUploadFileApproveParams {
  /** @format int64 */
  "event-id": number;
}

export type PhotographerUploadFileApproveError = RestError;

export interface PhotographerRemoveFileApproveParams {
  /** @format int64 */
  "event-id": number;
  md5: string;
}

export type PhotographerRemoveFileApproveError = RestError;

export interface ShareLinkPriceQuoteParams {
  /** @format int64 */
  "event-id": number;
}

export type ShareLinkPriceQuoteError = RestError;

export interface PhotographerPriceQuotePdfParams {
  /** @format int64 */
  "event-id": number;
}

export type PhotographerPriceQuotePdfError = RestError;

export type CreatePriceQuoteItemTemplateError = RestError;

export interface GetPriceQuotesItemsTemplatesParams {
  /**
   * current page
   * @min 0
   * @default 0
   */
  "page-number"?: number;
  /**
   * Page size
   * @min 1
   * @max 1000
   * @default 30
   */
  "page-size"?: number;
}

export type GetPriceQuotesItemsTemplatesError = RestError;

export interface GetPriceQuoteItemTemplateParams {
  "item-name": string;
}

export type GetPriceQuoteItemTemplateError = RestError;

export interface UpdatePriceQuoteItemTemplateParams {
  "item-name": string;
}

export type UpdatePriceQuoteItemTemplateError = RestError;

export interface DeletePriceQuoteItemTemplateParams {
  "item-name": string;
}

export type DeletePriceQuoteItemTemplateError = RestError;

export type CreatePriceQuoteTemplateError = RestError;

export interface GetPriceQuoteTemplatesParams {
  /**
   * current page
   * @min 0
   * @default 0
   */
  "page-number"?: number;
  /**
   * Page size
   * @min 1
   * @max 1000
   * @default 30
   */
  "page-size"?: number;
}

export type GetPriceQuoteTemplatesError = RestError;

export interface GetPriceQuoteTemplateParams {
  "template-name": string;
}

export type GetPriceQuoteTemplateError = RestError;

export interface UpdatePriceQuoteTemplateParams {
  "template-name": string;
}

export type UpdatePriceQuoteTemplateError = RestError;

export interface DeletePriceQuoteTemplateParams {
  "template-name": string;
}

export type DeletePriceQuoteTemplateError = RestError;

export interface GetPriceQuoteAdditionalDataParams {
  otp?: string;
  /** @format int64 */
  "event-id"?: number;
}

export type GetPriceQuoteAdditionalDataError = RestError;

export type UpsertPriceQuoteAdditionalDataError = RestError;

export interface GetCustomerPriceQuoteParams {
  /** @format int64 */
  "event-id": number;
  otp?: string;
}

export type GetCustomerPriceQuoteError = RestError;

export interface CustomerApprovePriceQuoteParams {
  /** @format int64 */
  "event-id": number;
  otp?: string;
}

export type CustomerApprovePriceQuoteError = RestError;

export interface CustomerUploadFileApproveParams {
  /** @format int64 */
  "event-id": number;
  otp?: string;
}

export type CustomerUploadFileApproveError = RestError;

export interface CustomerRemoveFileApproveParams {
  /** @format int64 */
  "event-id": number;
  otp?: string;
  md5: string;
}

export type CustomerRemoveFileApproveError = RestError;

export interface RejectPriceQuoteParams {
  /** @format int64 */
  "event-id": number;
  otp?: string;
}

export type RejectPriceQuoteError = RestError;

export interface CustomerPriceQuotePdfParams {
  /** @format int64 */
  "event-id": number;
  otp?: string;
}

export type CustomerPriceQuotePdfError = RestError;

export type QueryParamsType = Record<string | number, any>;
export type ResponseFormat = keyof Omit<Body, "body" | "bodyUsed">;

export interface FullRequestParams extends Omit<RequestInit, "body"> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean;
  /** request path */
  path: string;
  /** content type of request body */
  type?: ContentType;
  /** query params */
  query?: QueryParamsType;
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseFormat;
  /** request body */
  body?: unknown;
  /** base url */
  baseUrl?: string;
  /** request cancellation token */
  cancelToken?: CancelToken;
}

export type RequestParams = Omit<FullRequestParams, "body" | "method" | "query" | "path">;

export interface ApiConfig<SecurityDataType = unknown> {
  baseUrl?: string;
  baseApiParams?: Omit<RequestParams, "baseUrl" | "cancelToken" | "signal">;
  securityWorker?: (securityData: SecurityDataType | null) => Promise<RequestParams | void> | RequestParams | void;
  customFetch?: typeof fetch;
}

export interface HttpResponse<D extends unknown, E extends unknown = unknown> extends Response {
  data: D;
  error: E;
}

type CancelToken = Symbol | string | number;

export enum ContentType {
  Json = "application/json",
  FormData = "multipart/form-data",
  UrlEncoded = "application/x-www-form-urlencoded",
  Text = "text/plain"
}

export class HttpClient<SecurityDataType = unknown> {
  public baseUrl: string = "";
  private securityData: SecurityDataType | null = null;
  private securityWorker?: ApiConfig<SecurityDataType>["securityWorker"];
  private abortControllers = new Map<CancelToken, AbortController>();
  private customFetch = (...fetchParams: Parameters<typeof fetch>) => fetch(...fetchParams);

  private baseApiParams: RequestParams = {
    credentials: "same-origin",
    headers: {},
    redirect: "follow",
    referrerPolicy: "no-referrer"
  };

  constructor(apiConfig: ApiConfig<SecurityDataType> = {}) {
    Object.assign(this, apiConfig);
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data;
  };

  protected encodeQueryParam(key: string, value: any) {
    const encodedKey = encodeURIComponent(key);
    return `${encodedKey}=${encodeURIComponent(typeof value === "number" ? value : `${value}`)}`;
  }

  protected addQueryParam(query: QueryParamsType, key: string) {
    return this.encodeQueryParam(key, query[key]);
  }

  protected addArrayQueryParam(query: QueryParamsType, key: string) {
    const value = query[key];
    return value.map((v: any) => this.encodeQueryParam(key, v)).join("&");
  }

  protected toQueryString(rawQuery?: QueryParamsType): string {
    const query = rawQuery || {};
    const keys = Object.keys(query).filter((key) => "undefined" !== typeof query[key]);
    return keys
      .map((key) => (Array.isArray(query[key]) ? this.addArrayQueryParam(query, key) : this.addQueryParam(query, key)))
      .join("&");
  }

  protected addQueryParams(rawQuery?: QueryParamsType): string {
    const queryString = this.toQueryString(rawQuery);
    return queryString ? `?${queryString}` : "";
  }

  private contentFormatters: Record<ContentType, (input: any) => any> = {
    [ContentType.Json]: (input: any) =>
      input !== null && (typeof input === "object" || typeof input === "string") ? JSON.stringify(input) : input,
    [ContentType.Text]: (input: any) => (input !== null && typeof input !== "string" ? JSON.stringify(input) : input),
    [ContentType.FormData]: (input: any) =>
      Object.keys(input || {}).reduce((formData, key) => {
        const property = input[key];
        formData.append(
          key,
          property instanceof Blob
            ? property
            : typeof property === "object" && property !== null
            ? JSON.stringify(property)
            : `${property}`
        );
        return formData;
      }, new FormData()),
    [ContentType.UrlEncoded]: (input: any) => this.toQueryString(input)
  };

  protected mergeRequestParams(params1: RequestParams, params2?: RequestParams): RequestParams {
    return {
      ...this.baseApiParams,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...(this.baseApiParams.headers || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {})
      }
    };
  }

  protected createAbortSignal = (cancelToken: CancelToken): AbortSignal | undefined => {
    if (this.abortControllers.has(cancelToken)) {
      const abortController = this.abortControllers.get(cancelToken);
      if (abortController) {
        return abortController.signal;
      }
      return void 0;
    }

    const abortController = new AbortController();
    this.abortControllers.set(cancelToken, abortController);
    return abortController.signal;
  };

  public abortRequest = (cancelToken: CancelToken) => {
    const abortController = this.abortControllers.get(cancelToken);

    if (abortController) {
      abortController.abort();
      this.abortControllers.delete(cancelToken);
    }
  };

  public request = async <T = any, E = any>({
    body,
    secure,
    path,
    type,
    query,
    format,
    baseUrl,
    cancelToken,
    ...params
  }: FullRequestParams): Promise<HttpResponse<T, E>> => {
    const secureParams =
      ((typeof secure === "boolean" ? secure : this.baseApiParams.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {};
    const requestParams = this.mergeRequestParams(params, secureParams);
    const queryString = query && this.toQueryString(query);
    const payloadFormatter = this.contentFormatters[type || ContentType.Json];
    const responseFormat = format || requestParams.format;

    return this.customFetch(`${baseUrl || this.baseUrl || ""}${path}${queryString ? `?${queryString}` : ""}`, {
      ...requestParams,
      headers: {
        ...(requestParams.headers || {}),
        ...(type && type !== ContentType.FormData ? { "Content-Type": type } : {})
      },
      signal: (cancelToken ? this.createAbortSignal(cancelToken) : requestParams.signal) || null,
      body: typeof body === "undefined" || body === null ? null : payloadFormatter(body)
    }).then(async (response) => {
      const r = response as HttpResponse<T, E>;
      r.data = null as unknown as T;
      r.error = null as unknown as E;

      const data = !responseFormat
        ? r
        : await response[responseFormat]()
            .then((data) => {
              if (r.ok) {
                r.data = data;
              } else {
                r.error = data;
              }
              return r;
            })
            .catch((e) => {
              r.error = e;
              return r;
            });

      if (cancelToken) {
        this.abortControllers.delete(cancelToken);
      }

      if (!response.ok) throw data;
      return data;
    });
  };
}

/**
 * @title picky price-quotes service
 * @version 1.0.0
 * @license Apache 2.0 (http://www.apache.org/licenses/LICENSE-2.0.html)
 *
 * picky price-quotes service
 */
export class Api<SecurityDataType extends unknown> extends HttpClient<SecurityDataType> {
  photographer = {
    /**
     * No description
     *
     * @tags photographer-price-quotes
     * @name GetPriceQuote
     * @summary get price quote
     * @request GET:/photographer/price-quotes/event/id
     */
    getPriceQuote: (query: GetPriceQuoteParams, params: RequestParams = {}) =>
      this.request<GetPriceQuoteResponse, GetPriceQuoteError>({
        path: `/photographer/price-quotes/event/id`,
        method: "GET",
        query: query,
        type: ContentType.Json,
        format: "json",
        ...params
      }),

    /**
     * No description
     *
     * @tags photographer-price-quotes
     * @name UpsertPriceQuote
     * @summary upsert price quote
     * @request PUT:/photographer/price-quotes/event/id
     */
    upsertPriceQuote: (
      query: UpsertPriceQuoteParams,
      UpsertPriceQuoteRequest: UpsertPriceQuoteRequest,
      params: RequestParams = {}
    ) =>
      this.request<UpsertPriceQuoteResponse, UpsertPriceQuoteError>({
        path: `/photographer/price-quotes/event/id`,
        method: "PUT",
        query: query,
        body: UpsertPriceQuoteRequest,
        type: ContentType.Json,
        format: "json",
        ...params
      }),

    /**
     * No description
     *
     * @tags photographer-price-quotes
     * @name DeletePriceQuote
     * @summary delete price quote
     * @request DELETE:/photographer/price-quotes/event/id
     */
    deletePriceQuote: (query: DeletePriceQuoteParams, params: RequestParams = {}) =>
      this.request<void, DeletePriceQuoteError>({
        path: `/photographer/price-quotes/event/id`,
        method: "DELETE",
        query: query,
        type: ContentType.Json,
        ...params
      }),

    /**
     * No description
     *
     * @tags photographer-price-quotes
     * @name SendEmailPriceQuote
     * @summary send email price quote
     * @request POST:/photographer/price-quotes/event/id/email
     */
    sendEmailPriceQuote: (
      query: SendEmailPriceQuoteParams,
      SendEmailRequest: SendEmailRequest,
      params: RequestParams = {}
    ) =>
      this.request<void, SendEmailPriceQuoteError>({
        path: `/photographer/price-quotes/event/id/email`,
        method: "POST",
        query: query,
        body: SendEmailRequest,
        type: ContentType.Json,
        ...params
      }),

    /**
     * No description
     *
     * @tags photographer-price-quotes
     * @name PhotographerApprovePriceQuote
     * @summary approve price quote
     * @request POST:/photographer/price-quotes/event/id/approve
     */
    photographerApprovePriceQuote: (query: PhotographerApprovePriceQuoteParams, params: RequestParams = {}) =>
      this.request<void, PhotographerApprovePriceQuoteError>({
        path: `/photographer/price-quotes/event/id/approve`,
        method: "POST",
        query: query,
        type: ContentType.Json,
        ...params
      }),

    /**
     * No description
     *
     * @tags photographer-price-quotes
     * @name PhotographerUploadFileApprove
     * @summary upload file approve price quote
     * @request POST:/photographer/price-quotes/event/id/file-approve
     */
    photographerUploadFileApprove: (
      query: PhotographerUploadFileApproveParams,
      UploadFileApproveRequest: UploadFileApproveRequest,
      params: RequestParams = {}
    ) =>
      this.request<void, PhotographerUploadFileApproveError>({
        path: `/photographer/price-quotes/event/id/file-approve`,
        method: "POST",
        query: query,
        body: UploadFileApproveRequest,
        type: ContentType.Json,
        ...params
      }),

    /**
     * No description
     *
     * @tags photographer-price-quotes
     * @name PhotographerRemoveFileApprove
     * @summary remove file approve price quote
     * @request DELETE:/photographer/price-quotes/event/id/file-approve
     */
    photographerRemoveFileApprove: (query: PhotographerRemoveFileApproveParams, params: RequestParams = {}) =>
      this.request<void, PhotographerRemoveFileApproveError>({
        path: `/photographer/price-quotes/event/id/file-approve`,
        method: "DELETE",
        query: query,
        type: ContentType.Json,
        ...params
      }),

    /**
     * No description
     *
     * @tags photographer-price-quotes
     * @name ShareLinkPriceQuote
     * @summary share link price quote
     * @request GET:/photographer/price-quotes/event/id/share
     */
    shareLinkPriceQuote: (query: ShareLinkPriceQuoteParams, params: RequestParams = {}) =>
      this.request<PhotographerSharePriceQuoteResponse, ShareLinkPriceQuoteError>({
        path: `/photographer/price-quotes/event/id/share`,
        method: "GET",
        query: query,
        type: ContentType.Json,
        format: "json",
        ...params
      }),

    /**
     * No description
     *
     * @tags photographer-price-quotes
     * @name PhotographerPriceQuotePdf
     * @summary price quote pdf
     * @request GET:/photographer/price-quotes/event/id/pdf
     */
    photographerPriceQuotePdf: (query: PhotographerPriceQuotePdfParams, params: RequestParams = {}) =>
      this.request<PhotographerPriceQuotePdfResponse, PhotographerPriceQuotePdfError>({
        path: `/photographer/price-quotes/event/id/pdf`,
        method: "GET",
        query: query,
        type: ContentType.Json,
        ...params
      }),

    /**
     * No description
     *
     * @tags photographer-price-quotes-items-templates
     * @name CreatePriceQuoteItemTemplate
     * @summary create a new price quote item template
     * @request POST:/photographer/price-quotes/items-templates
     */
    createPriceQuoteItemTemplate: (
      CreatePriceQuoteItemTemplateRequest: CreatePriceQuoteItemTemplateRequest,
      params: RequestParams = {}
    ) =>
      this.request<CreatePriceQuoteItemTemplateResponse, CreatePriceQuoteItemTemplateError>({
        path: `/photographer/price-quotes/items-templates`,
        method: "POST",
        body: CreatePriceQuoteItemTemplateRequest,
        type: ContentType.Json,
        format: "json",
        ...params
      }),

    /**
     * No description
     *
     * @tags photographer-price-quotes-items-templates
     * @name GetPriceQuotesItemsTemplates
     * @summary list of photographer price quote items templates
     * @request GET:/photographer/price-quotes/items-templates
     */
    getPriceQuotesItemsTemplates: (query: GetPriceQuotesItemsTemplatesParams, params: RequestParams = {}) =>
      this.request<GetPriceQuoteItemsTemplatesResponse, GetPriceQuotesItemsTemplatesError>({
        path: `/photographer/price-quotes/items-templates`,
        method: "GET",
        query: query,
        type: ContentType.Json,
        format: "json",
        ...params
      }),

    /**
     * No description
     *
     * @tags photographer-price-quotes-items-templates
     * @name GetPriceQuoteItemTemplate
     * @summary get price quote item template
     * @request GET:/photographer/price-quotes/items-templates/name
     */
    getPriceQuoteItemTemplate: (query: GetPriceQuoteItemTemplateParams, params: RequestParams = {}) =>
      this.request<GetPriceQuoteItemTemplateResponse, GetPriceQuoteItemTemplateError>({
        path: `/photographer/price-quotes/items-templates/name`,
        method: "GET",
        query: query,
        type: ContentType.Json,
        format: "json",
        ...params
      }),

    /**
     * No description
     *
     * @tags photographer-price-quotes-items-templates
     * @name UpdatePriceQuoteItemTemplate
     * @summary update price quote item template
     * @request PUT:/photographer/price-quotes/items-templates/name
     */
    updatePriceQuoteItemTemplate: (
      query: UpdatePriceQuoteItemTemplateParams,
      UpdatePriceQuoteItemTemplateRequest: UpdatePriceQuoteItemTemplateRequest,
      params: RequestParams = {}
    ) =>
      this.request<UpdatePriceQuoteItemTemplateResponse, UpdatePriceQuoteItemTemplateError>({
        path: `/photographer/price-quotes/items-templates/name`,
        method: "PUT",
        query: query,
        body: UpdatePriceQuoteItemTemplateRequest,
        type: ContentType.Json,
        format: "json",
        ...params
      }),

    /**
     * No description
     *
     * @tags photographer-price-quotes-items-templates
     * @name DeletePriceQuoteItemTemplate
     * @summary delete price quote template
     * @request DELETE:/photographer/price-quotes/items-templates/name
     */
    deletePriceQuoteItemTemplate: (query: DeletePriceQuoteItemTemplateParams, params: RequestParams = {}) =>
      this.request<void, DeletePriceQuoteItemTemplateError>({
        path: `/photographer/price-quotes/items-templates/name`,
        method: "DELETE",
        query: query,
        type: ContentType.Json,
        ...params
      }),

    /**
     * No description
     *
     * @tags photographer-price-quotes-templates
     * @name CreatePriceQuoteTemplate
     * @summary create a new price quote template
     * @request POST:/photographer/price-quotes/templates
     */
    createPriceQuoteTemplate: (
      CreatePriceQuoteTemplateRequest: CreatePriceQuoteTemplateRequest,
      params: RequestParams = {}
    ) =>
      this.request<CreatePriceQuoteTemplateResponse, CreatePriceQuoteTemplateError>({
        path: `/photographer/price-quotes/templates`,
        method: "POST",
        body: CreatePriceQuoteTemplateRequest,
        type: ContentType.Json,
        format: "json",
        ...params
      }),

    /**
     * No description
     *
     * @tags photographer-price-quotes-templates
     * @name GetPriceQuoteTemplates
     * @summary get price quotes templates
     * @request GET:/photographer/price-quotes/templates
     */
    getPriceQuoteTemplates: (query: GetPriceQuoteTemplatesParams, params: RequestParams = {}) =>
      this.request<GetPriceQuoteTemplatesResponse, GetPriceQuoteTemplatesError>({
        path: `/photographer/price-quotes/templates`,
        method: "GET",
        query: query,
        type: ContentType.Json,
        format: "json",
        ...params
      }),

    /**
     * No description
     *
     * @tags photographer-price-quotes-templates
     * @name GetPriceQuoteTemplate
     * @summary get price quotes template
     * @request GET:/photographer/price-quotes/templates/name
     */
    getPriceQuoteTemplate: (query: GetPriceQuoteTemplateParams, params: RequestParams = {}) =>
      this.request<GetPriceQuoteTemplateResponse, GetPriceQuoteTemplateError>({
        path: `/photographer/price-quotes/templates/name`,
        method: "GET",
        query: query,
        type: ContentType.Json,
        format: "json",
        ...params
      }),

    /**
     * No description
     *
     * @tags photographer-price-quotes-templates
     * @name UpdatePriceQuoteTemplate
     * @summary update price quotes template
     * @request PUT:/photographer/price-quotes/templates/name
     */
    updatePriceQuoteTemplate: (
      query: UpdatePriceQuoteTemplateParams,
      UpdatePriceQuoteTemplateRequest: UpdatePriceQuoteTemplateRequest,
      params: RequestParams = {}
    ) =>
      this.request<UpdatePriceQuoteTemplateResponse, UpdatePriceQuoteTemplateError>({
        path: `/photographer/price-quotes/templates/name`,
        method: "PUT",
        query: query,
        body: UpdatePriceQuoteTemplateRequest,
        type: ContentType.Json,
        format: "json",
        ...params
      }),

    /**
     * No description
     *
     * @tags photographer-price-quotes-templates
     * @name DeletePriceQuoteTemplate
     * @summary delete price quote template
     * @request DELETE:/photographer/price-quotes/templates/name
     */
    deletePriceQuoteTemplate: (query: DeletePriceQuoteTemplateParams, params: RequestParams = {}) =>
      this.request<void, DeletePriceQuoteTemplateError>({
        path: `/photographer/price-quotes/templates/name`,
        method: "DELETE",
        query: query,
        type: ContentType.Json,
        ...params
      }),

    /**
     * No description
     *
     * @tags photographer-price-quotes-additional-data
     * @name GetPriceQuoteAdditionalData
     * @summary get price quote additional data
     * @request GET:/photographer/price-quotes-additional-data
     */
    getPriceQuoteAdditionalData: (query: GetPriceQuoteAdditionalDataParams, params: RequestParams = {}) =>
      this.request<GetPriceQuoteAdditionalDataResponse, GetPriceQuoteAdditionalDataError>({
        path: `/photographer/price-quotes-additional-data`,
        method: "GET",
        query: query,
        type: ContentType.Json,
        format: "json",
        ...params
      }),

    /**
     * No description
     *
     * @tags photographer-price-quotes-additional-data
     * @name UpsertPriceQuoteAdditionalData
     * @summary upsert price quote additional data
     * @request PUT:/photographer/price-quotes-additional-data
     */
    upsertPriceQuoteAdditionalData: (
      UpsertPriceQuoteAdditionalDataRequest: UpsertPriceQuoteAdditionalDataRequest,
      params: RequestParams = {}
    ) =>
      this.request<UpsertPriceQuoteAdditionalDataResponse, UpsertPriceQuoteAdditionalDataError>({
        path: `/photographer/price-quotes-additional-data`,
        method: "PUT",
        body: UpsertPriceQuoteAdditionalDataRequest,
        type: ContentType.Json,
        format: "json",
        ...params
      })
  };
  customer = {
    /**
     * No description
     *
     * @tags customer-price-quotes
     * @name GetCustomerPriceQuote
     * @summary get customer price quote
     * @request GET:/customer/price-quotes/event/id
     */
    getCustomerPriceQuote: (query: GetCustomerPriceQuoteParams, params: RequestParams = {}) =>
      this.request<GetCustomerPriceQuoteResponse, GetCustomerPriceQuoteError>({
        path: `/customer/price-quotes/event/id`,
        method: "GET",
        query: query,
        type: ContentType.Json,
        format: "json",
        ...params
      }),

    /**
     * No description
     *
     * @tags customer-price-quotes
     * @name CustomerApprovePriceQuote
     * @summary approve price quote
     * @request POST:/customer/price-quotes/event/id/approve
     */
    customerApprovePriceQuote: (query: CustomerApprovePriceQuoteParams, params: RequestParams = {}) =>
      this.request<void, CustomerApprovePriceQuoteError>({
        path: `/customer/price-quotes/event/id/approve`,
        method: "POST",
        query: query,
        type: ContentType.Json,
        ...params
      }),

    /**
     * No description
     *
     * @tags customer-price-quotes
     * @name CustomerUploadFileApprove
     * @summary upload file approve price quote
     * @request POST:/customer/price-quotes/event/id/file-approve
     */
    customerUploadFileApprove: (
      query: CustomerUploadFileApproveParams,
      UploadFileApproveRequest: UploadFileApproveRequest,
      params: RequestParams = {}
    ) =>
      this.request<void, CustomerUploadFileApproveError>({
        path: `/customer/price-quotes/event/id/file-approve`,
        method: "POST",
        query: query,
        body: UploadFileApproveRequest,
        type: ContentType.Json,
        ...params
      }),

    /**
     * No description
     *
     * @tags customer-price-quotes
     * @name CustomerRemoveFileApprove
     * @summary remove file approve price quote
     * @request DELETE:/customer/price-quotes/event/id/file-approve
     */
    customerRemoveFileApprove: (query: CustomerRemoveFileApproveParams, params: RequestParams = {}) =>
      this.request<void, CustomerRemoveFileApproveError>({
        path: `/customer/price-quotes/event/id/file-approve`,
        method: "DELETE",
        query: query,
        type: ContentType.Json,
        ...params
      }),

    /**
     * No description
     *
     * @tags customer-price-quotes
     * @name RejectPriceQuote
     * @summary reject price quote
     * @request POST:/customer/price-quotes/event/id/reject
     */
    rejectPriceQuote: (
      query: RejectPriceQuoteParams,
      RejectPriceQuoteRequest: RejectPriceQuoteRequest,
      params: RequestParams = {}
    ) =>
      this.request<void, RejectPriceQuoteError>({
        path: `/customer/price-quotes/event/id/reject`,
        method: "POST",
        query: query,
        body: RejectPriceQuoteRequest,
        type: ContentType.Json,
        ...params
      }),

    /**
     * No description
     *
     * @tags customer-price-quotes
     * @name CustomerPriceQuotePdf
     * @summary download customer price quote pdf
     * @request GET:/customer/price-quotes/event/id/pdf
     */
    customerPriceQuotePdf: (query: CustomerPriceQuotePdfParams, params: RequestParams = {}) =>
      this.request<CustomerPriceQuotePdfResponse, CustomerPriceQuotePdfError>({
        path: `/customer/price-quotes/event/id/pdf`,
        method: "GET",
        query: query,
        type: ContentType.Json,
        ...params
      })
  };
}
