import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { isMobile } from "../store/reducers/page-reducer";

interface PageSettingsProps {
  children?: React.ReactNode;
}

function PageSettings({
  children
}: React.PropsWithChildren<PageSettingsProps>) {
  const dispatch = useDispatch();
  const [isReady, setReady] = useState(false);

  useEffect(() => {
    const mediaQuery = window.matchMedia(`(min-width: 850px)`);

    const handleMediaQueryChange = () => {
      dispatch(isMobile(!mediaQuery.matches));
    };

    handleMediaQueryChange();
    mediaQuery.onchange = handleMediaQueryChange;
    setReady(true);

    return () => {
      mediaQuery.onchange = null;
    };
  }, []);

  return <>{isReady && children}</>;
}

export default PageSettings;
