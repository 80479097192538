import { EventStatus } from "../../../../../generated/swagger/events";

export const getEventStatus = (
  t: (key: string) => string,
  event_status?: EventStatus,
  event_time?: number
) => {
  if (event_status === EventStatus.Event) {
    return Date.now() / 1000 < (event_time || 0)
      ? t("pages.events.enums.status.before-event")
      : t("pages.events.enums.status.after-event");
  }

  return t(`pages.events.enums.status.${event_status || "unknown"}`);
};
