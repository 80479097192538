import { useSelector } from "react-redux";
import { accountSelector, pageSelector } from "../store";

interface MobilePageTitleProps {
  pageName?: string;
}

function MobilePageTitle({ pageName }: MobilePageTitleProps) {
  const account = useSelector(accountSelector);
  const pageState = useSelector(pageSelector);

  const showSideBar = account && !pageState.isMobile;

  if (!showSideBar && pageName) {
    return <div className="page-title">{pageName}</div>;
  }

  return <></>;
}

export default MobilePageTitle;
