import { Col, Container, Row, Image } from "react-bootstrap";
import { Trans, useTranslation } from "react-i18next";
import womanPhoto from "../../resources/young-woman-isolated-blue-wall-with-professional-camera.png";
import "react-phone-number-input/style.css";

function Home() {
  const { t } = useTranslation();

  return (
    <Container className="container-max">
      <Row className="origin-row">
        <Col className="text-home">
          <Container>
            <Row>
              <Col className="text-home-title">{t("pages.home.title")}</Col>
            </Row>
            <Row>
              <Col className="text-home-sub-title">
                {t("pages.home.sub-title")}
              </Col>
            </Row>
            <Row>
              <Col className="text-home-label">
                <p>
                  <Trans i18nKey="pages.home.label" />
                </p>
              </Col>
            </Row>
          </Container>
        </Col>
        <Col xs={6} style={{ overflowX: "hidden" }}>
          <Image src={womanPhoto} className="woman-photo" />
        </Col>
      </Row>
    </Container>
  );
}

export default Home;
