import { Form } from "react-bootstrap";

interface SmartTableTextFilterProps {
  value: string | undefined;
  onValueChange: (value: string | undefined) => void;
}

function SmartTableTextFilter({
  value,
  onValueChange
}: SmartTableTextFilterProps) {
  return (
    <Form.Control
      required
      type="text"
      value={value || ""}
      onChange={(e) => {
        const value = e.target.value.length === 0 ? undefined : e.target.value;

        onValueChange(value);
      }}
    />
  );
}

export default SmartTableTextFilter;
