import { useTranslation } from "react-i18next";
import Select, { MultiValue, SingleValue } from "react-select";

interface SmartTableChoiceFilterProps {
  value?: string[];
  onValueChange: (value?: string[]) => void;
  choices: { label: string; value: string }[];
  isMultiChoice: boolean;
}

function SmartTableChoiceFilter({
  value,
  onValueChange,
  choices,
  isMultiChoice
}: SmartTableChoiceFilterProps) {
  const { t } = useTranslation();

  return (
    <Select
      placeholder={t("components.smart-table.generic.pick-sort")}
      closeMenuOnSelect={!isMultiChoice}
      isMulti={isMultiChoice}
      options={choices}
      onChange={(e) => {
        if (isMultiChoice) {
          const multiValue = e as MultiValue<{ value: string; label: string }>;

          multiValue.length === 0
            ? onValueChange(undefined)
            : onValueChange(multiValue.map((curr) => curr.value));
        } else {
          const singleValue = e as SingleValue<{
            value: string;
            label: string;
          }>;

          singleValue
            ? onValueChange([singleValue.value])
            : onValueChange(undefined);
        }
      }}
      value={choices.filter((choice) =>
        value?.find((curr) => curr === choice.value)
      )}
      noOptionsMessage={() => {
        return <>{t("generic.empty-results")}</>;
      }}
    />
  );
}

export default SmartTableChoiceFilter;
