import "./App.css";
import "./i18n";
import "./amplify";
import "bootstrap/dist/css/bootstrap.min.css";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import store from "./store";
import Cognito from "./components/cognito";
import PageSettings from "./components/page-settings";
import AppRouter from "./components/app-router";
import { FontStyles } from "picky-style";

export default () => (
  <Provider store={store}>
    <FontStyles />
    <BrowserRouter>
      <PageSettings>
        <Cognito>
          <AppRouter />
        </Cognito>
      </PageSettings>
    </BrowserRouter>
  </Provider>
);
