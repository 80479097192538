import { EventStatus, EventType } from "../generated/swagger/events";

export const eventTypes = [
  EventType.Wedding,
  EventType.Engagement,
  EventType.Stylish,
  EventType.Sport,
  EventType.Family,
  EventType.Pregnancy,
  EventType.Institution,
  EventType.Party,
  EventType.BabyBirth,
  EventType.SweetTeen,
  EventType.Henna,
  EventType.Other
];

export const eventStatuses = [
  EventStatus.Draft,
  EventStatus.PriceQuote,
  EventStatus.Event,
  EventStatus.Finished,
  EventStatus.Archived
];
