import { CSSProperties, useState } from "react";
import { Table } from "react-bootstrap";
import sortAsc from "./resources/sort-asc.png";
import sortDesc from "./resources/sort-desc.png";
import sortable from "./resources/non-sorted.png";
import filter from "./resources/filter.png";
import { nextSortDirection, Sort, SortDirection } from "./smart-table-sort";
import { SmartTableFilter } from "./smart-table-filter";
import SmartTableFilterModal from "./components/smart-table-filter-modal";

export interface SmartTableColumn<T> {
  name: string;
  headerClassName?: string;
  headerStyle?: CSSProperties;
  dataClassName?: string;
  dataStyle?: CSSProperties;
  headerValue?: JSX.Element;
  dataMapper: (data: T) => any;
  showCondition?: () => boolean;
  filterBy?: SmartTableFilter<T>;
  sortBy?: (a: T, b: T) => number;
  blockClickPropagate?: boolean;
  defaultFilterData?: unknown;
}

interface CustomFilter {
  showSmartTableFilter: boolean;
  showFilterModal: boolean;
  onFilterClose: () => void;
  customState?: [
    Map<number, unknown>,
    (newFilters: Map<number, unknown>) => void
  ];
}

interface SmartTableProps<T> {
  keyId: string;
  className?: string;
  style?: CSSProperties;
  data: T[];
  columns: SmartTableColumn<T>[];
  onDataClick?: (data: T) => void;
  customFilter?: CustomFilter;
}

const defaultFilters = <T,>(columns: SmartTableColumn<T>[]) => {
  const filters = new Map<number, unknown>();

  for (let i = 0; i < columns.length; i++) {
    const column = columns[i];

    if (column.defaultFilterData) {
      filters.set(i, column.defaultFilterData);
    }
  }

  return filters;
};

function SmartTable<T>(props: SmartTableProps<T>) {
  const [modal, setModal] = useState<JSX.Element | undefined>();
  const [filters, setFilters] = props.customFilter?.customState
    ? props.customFilter.customState
    : useState(defaultFilters(props.columns));
  const [sorters, setSorters] = useState([] as Sort[]);

  const getIndexedColumns = () => {
    const result = [] as { index: number; column: SmartTableColumn<T> }[];

    for (let i = 0; i < props.columns.length; i++) {
      result.push({ index: i, column: props.columns[i] });
    }

    return result;
  };

  const visibleColumns = getIndexedColumns().filter((indexColumn) => {
    if (indexColumn.column.showCondition) {
      return indexColumn.column.showCondition();
    }

    return true;
  });

  const filterableColumns = visibleColumns.filter(
    (indexColumn) => indexColumn.column.filterBy
  );

  const sortableColumns = visibleColumns.filter(
    (indexColumn) => indexColumn.column.sortBy
  );

  const filterData = (data: T[]): T[] => {
    if (filterableColumns.length === 0 || filters.size === 0) return data;

    return data.filter((curr) =>
      filterableColumns.every((indexColumn) =>
        indexColumn.column.filterBy?.filter(
          curr,
          filters.get(indexColumn.index)
        )
      )
    );
  };

  const sortData = (data: T[]) => {
    if (sorters.length === 0 || sortableColumns.length === 0) return data;

    return data.sort((a: T, b: T) => {
      for (let i = 0; i < sorters.length; i++) {
        const sort = sorters[i];
        const column = props.columns[sort.columnIndex];
        const showCondition = column.showCondition;

        if (column.sortBy && (!showCondition || showCondition())) {
          const sortResult = column.sortBy(a, b);

          if (sortResult != 0) {
            if (sort.direction === SortDirection.DESC) {
              return sortResult * -1;
            }

            return sortResult;
          }
        }
      }

      return 0;
    });
  };

  const data = sortData(filterData([...props.data]));

  const renderColumns = () => {
    return visibleColumns.map((indexColumn) => {
      const index = indexColumn.index;
      const column = indexColumn.column;
      const sortClassName = column.sortBy ? "clickable" : undefined;
      const classNames = [column.headerClassName, sortClassName].filter(
        (className) => className !== undefined
      );
      const className = classNames.length > 0 ? classNames.join() : undefined;

      const getColumnSort = (index: number) =>
        sorters.find((sort) => sort.columnIndex === index) || {
          columnIndex: index,
          direction: SortDirection.None
        };

      const renderSort = () => {
        const existing = getColumnSort(index);

        switch (existing.direction) {
          case SortDirection.None:
            return <img src={sortable} className={"table-sort-icon"} />;
          case SortDirection.ASC:
            return <img src={sortAsc} className={"table-sort-icon"} />;
          case SortDirection.DESC:
            return <img src={sortDesc} className={"table-sort-icon"} />;
        }

        return <></>;
      };

      const updateSort = () => {
        const existing = getColumnSort(index);

        const newSort: Sort = {
          ...existing,
          direction: nextSortDirection(existing.direction)
        };

        const newSorters = [
          ...sorters.filter((sort) => sort.columnIndex !== index)
        ];

        if (newSort.direction !== SortDirection.None) {
          newSorters.push(newSort);
        }

        setSorters(newSorters);
      };

      return (
        <th
          key={`${props.keyId}-${column.name}-column`}
          className={className}
          style={column.headerStyle}
          onClick={() => column.sortBy && updateSort()}
        >
          {column.sortBy && renderSort()}
          {column.headerValue || column.name}
        </th>
      );
    });
  };

  const renderData = () => {
    let rowKey = 0;

    return data.map((currValue) => {
      const renderColumns = () => {
        return visibleColumns.map((indexColumn) => {
          const column = indexColumn.column;

          return (
            <td
              key={`${props.keyId}-row-${rowKey}-col-${indexColumn.index}`}
              className={column.dataClassName}
              style={column.dataStyle}
              onClick={(e) => {
                if (column.blockClickPropagate) {
                  e.stopPropagation();
                }
              }}
            >
              {column.dataMapper(currValue)}
            </td>
          );
        });
      };

      const className = props.onDataClick ? "clickable" : undefined;

      return (
        <tr
          key={`${props.keyId}-row-${rowKey++}`}
          className={className}
          onClick={() => props.onDataClick && props.onDataClick(currValue)}
        >
          {renderColumns()}
        </tr>
      );
    });
  };

  const showModal = () => {
    const closeModal = () => {
      setModal(undefined);
      props.customFilter?.onFilterClose();
    };

    setModal(
      <SmartTableFilterModal
        onModalClose={closeModal}
        filterableColumns={filterableColumns}
        filters={filters}
        onFilterChange={(newFilters) => {
          setFilters(newFilters);
        }}
      />
    );
  };

  if (props.customFilter?.showFilterModal && modal === undefined) {
    showModal();
  }

  return (
    <>
      {modal}
      <Table className={props.className} style={props.style}>
        <thead>
          {filterableColumns.length > 0 &&
            (props.customFilter === undefined ||
              props.customFilter.showSmartTableFilter) && (
              <tr>
                <th
                  colSpan={visibleColumns.length}
                  className="clickable"
                  onClick={() => showModal()}
                >
                  <img className="table-filter-icon" src={filter} />
                </th>
              </tr>
            )}
          <tr>{renderColumns()}</tr>
        </thead>
        <tbody>{renderData()}</tbody>
      </Table>
    </>
  );
}

export default SmartTable;
