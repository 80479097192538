import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { PhotographerEventSummary } from "../../../../../../generated/swagger/events";
import { photographerEventManageUrl } from "../../../../../app-body";
import share from "../../../../../../resources/share.png";
import download from "../../../../../../resources/pdf.png";
import downloadGif from "../../../../../../resources/pdf-download.gif";
import email from "../../../../../../resources/mail-produce.png";
import PhotographerPriceQuoteMailModal from "./photographer-price-quote-email-modal";
import { useState } from "react";
import { saveRestResponseFile } from "../../../../../../utils/base64ToFile";
import { priceQuotesApi } from "../../../../../../gateway/price-quotes/price-quotes";
import { getAuthorizationHeader } from "../../../../../../amplify";
import { Button } from "picky-style";
import PhotographerPriceQuoteShareLinkModal from "./photographer-price-quote-share-link-modal";
import { useSelector } from "react-redux";
import { pageSelector } from "../../../../../../store";

enum Modals {
  None,
  EmailModal,
  ShareModal
}

export interface PhotographerPriceQuoteProduceProps {
  priceQuoteTitle: string;
  event: PhotographerEventSummary;
}

function PhotographerPriceQuoteProduce({
  priceQuoteTitle,
  event
}: PhotographerPriceQuoteProduceProps) {
  const pageState = useSelector(pageSelector);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [state, setState] = useState({
    showModal: Modals.None,
    isDownloading: false
  });

  const renderDesktopTitle = () => {
    return !pageState.isMobile ? (
      <>
        <div className="table-headline" style={{ textAlign: "center" }}>
          <label>
            {t("components.photographer.price-quotes-produce.title")}
          </label>
        </div>
        <br />
      </>
    ) : undefined;
  };
  const renderMobileTitle = () => {
    return (
      <div className="page-title">
        {t("components.photographer.price-quotes-produce.title")}
      </div>
    );
  };

  const onShareClick = () => {
    setState((curr) => {
      return { ...curr, showModal: Modals.ShareModal };
    });
  };

  const onDownloadClick = async () => {
    if (state.isDownloading) return;

    setState((curr) => {
      return { ...curr, isDownloading: true };
    });

    try {
      const authorization = await getAuthorizationHeader();
      const base64RestResponse =
        await priceQuotesApi.photographer.getPhotographerPdf(
          event.id || -1,
          authorization
        );

      saveRestResponseFile(
        base64RestResponse,
        `${t("pages.events.manage.price-quote.title")}-${event.name || ""}.pdf`
      );

      priceQuotesApi.photographer.getPhotographerPdf(
        event.id || -1,
        authorization
      );
    } finally {
      setState((curr) => {
        return { ...curr, isDownloading: false };
      });
    }
  };

  const onEmailClick = () => {
    setState((curr) => {
      return { ...curr, showModal: Modals.EmailModal };
    });
  };
  const modalHtml = (() => {
    if (!event.id) return undefined;

    switch (state.showModal) {
      case Modals.None:
        return undefined;
      case Modals.ShareModal:
        return (
          <PhotographerPriceQuoteShareLinkModal
            eventId={event.id}
            show={true}
            closeModal={() => {
              setState((curr) => {
                return { ...curr, showModal: Modals.None };
              });
            }}
          />
        );
      case Modals.EmailModal: {
        return (
          <PhotographerPriceQuoteMailModal
            eventId={event.id}
            mail={event.customer_email}
            show={true}
            closeModal={() => {
              setState((curr) => {
                return { ...curr, showModal: Modals.None };
              });
            }}
          />
        );
      }
    }
  })();

  return (
    <>
      {pageState.isMobile && renderMobileTitle()}
      {modalHtml}
      <div className="photographer-price-quote-produce">
        {renderDesktopTitle()}
        <div style={{ padding: "8.334svh" }}>
          <label className="center" style={{ flexWrap: "wrap" }}>
            <b>{t("components.photographer.price-quotes-produce.event")}</b>
            {event.name}
          </label>
          <label className="center">
            <b>
              {t(
                "components.photographer.price-quotes-produce.price-quote-title"
              )}
            </b>
            {priceQuoteTitle}
          </label>
          <br />
          <Row
            className="divider"
            style={{
              paddingTop: "10px",
              maxWidth: "85%",
              margin: "auto",
              marginBottom: "10px"
            }}
          />
          <Row
            className="align-items-center"
            style={{
              justifyContent: "space-around",
              padding: "25px 0px 25px 0px",
              width: "80%",
              margin: "auto"
            }}
          >
            <Col xs={2} className="clickable" onClick={onShareClick}>
              <div className="center">
                <img src={share} width={"40px"} height={"40px"} />
              </div>
              <label className="center">
                {t(
                  "components.photographer.price-quotes-produce.actions.share"
                )}
              </label>
            </Col>
            <Col xs={2} className="clickable" onClick={onDownloadClick}>
              <div className="center">
                {state.isDownloading ? (
                  <img src={downloadGif} width={"60px"} height={"60px"} />
                ) : (
                  <img src={download} width={"40px"} height={"40px"} />
                )}
              </div>
              <label className="center">
                {t("components.photographer.price-quotes-produce.actions.pdf")}
              </label>
            </Col>
            <Col xs={2} className="clickable" onClick={onEmailClick}>
              <div className="center">
                <img src={email} width={"40px"} height={"40px"} />
              </div>
              <label className="center">
                {t(
                  "components.photographer.price-quotes-produce.actions.email"
                )}
              </label>
            </Col>
          </Row>
          <Row
            className="divider"
            style={{
              paddingTop: "10px",
              maxWidth: "85%",
              margin: "auto",
              marginBottom: "10px"
            }}
          />
          <br />
          <div className="center">
            <Button
              text={t(
                "components.photographer.price-quotes-produce.actions.close"
              )}
              onClick={() =>
                navigate(
                  photographerEventManageUrl.replace(
                    ":eventId",
                    (event.id || 0).toString()
                  )
                )
              }
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default PhotographerPriceQuoteProduce;
