import { useEffect, useState } from "react";

interface WidthResponsiveProps {
  minWidth: number;
  smallComponent: JSX.Element;
  bigComponent: JSX.Element;
  cache?: boolean;
}

function WidthResponsive({
  minWidth,
  smallComponent,
  bigComponent,
  cache
}: WidthResponsiveProps) {
  const [state, setState] = useState({
    element: null as JSX.Element | null,
    isSmaller: false,
    isReady: false
  });

  const getElement = (isSmaller: boolean) => {
    return isSmaller ? smallComponent : bigComponent;
  };

  useEffect(() => {
    const mediaQuery = window.matchMedia(`(min-width: ${minWidth}px)`);

    const handleMediaQueryChange = () => {
      const isSmaller = !mediaQuery.matches;
      const element = cache ? getElement(isSmaller) : null;

      setState({
        isSmaller: isSmaller,
        element: element,
        isReady: true
      });
    };

    handleMediaQueryChange();
    mediaQuery.onchange = handleMediaQueryChange;

    return () => {
      mediaQuery.onchange = null;
    };
  }, []);

  return state.isReady && state.element
    ? state.element
    : getElement(state.isSmaller);
}

export default WidthResponsive;
