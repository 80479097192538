import { CSSProperties, useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useParams } from "react-router";
import Loading from "../../loading";
import { Col, Form, Row } from "react-bootstrap";
import { priceQuotesApi } from "../../../gateway/price-quotes/price-quotes";
import {
  PhotographerPriceQuote,
  Event,
  LineItem,
  PhotographerPriceQuoteAdditionalData,
  DiscountType,
  ApprovedPriceQuoteFileValue
} from "../../../generated/swagger/price-quotes";
import { useSelector } from "react-redux";
import { pageSelector } from "../../../store";
import { getAuthorizationHeader } from "../../../amplify";
import { useSearchParams } from "react-router-dom";
import Moment from "react-moment";
import PriceTable from "../../price-table";
import {
  base64ToByteArray,
  saveRestResponseFile
} from "../../../utils/base64ToFile";
import footerLogo from "../../../resources/footer-logo.png";
import headerLogoPlaceHolder from "../../../resources/logo-placeholder.png";
import logo from "../../../resources/logo.png";
import { formatPhoneNumber } from "react-phone-number-input";
import TextBoxModal from "../../text-box-modal";
import { textBoxModalLocale, uploadFileModalLocale } from "../../../constants";
import { Button } from "picky-style";
import md5 from "blueimp-md5";
import UploadFileModal from "../../upload-file-modal";

enum Modals {
  None,
  Approve,
  Reject
}

interface CustomerPriceQuoteData {
  event: Event;
  priceQuote: PhotographerPriceQuote; //FIXME: CustomerPriceQuote
}

function CustomerPriceQuotesView() {
  const pageState = useSelector(pageSelector);
  const { t } = useTranslation();
  const params = useParams();
  const [searchParams] = useSearchParams();

  const eventId = Number(params["eventId"]);
  const otp = searchParams.get("otp") || undefined;
  const pdf = searchParams.get("pdf") || undefined;
  const pageSize = 20;
  const maxItemsInLastPageWithoutPageBreak = 17;

  const isPdf = pdf === "true";

  const [state, setState] = useState({
    loading: true,
    data: undefined as CustomerPriceQuoteData | undefined,
    files: undefined as Map<string, ApprovedPriceQuoteFileValue> | undefined,
    businessData: undefined as PhotographerPriceQuoteAdditionalData | undefined,
    errorMessage: undefined as string | undefined,
    showModal: Modals.None,
    savingPdf: false
  });

  const fetchData = async (): Promise<CustomerPriceQuoteData | undefined> => {
    const token = otp ? undefined : await getAuthorizationHeader();

    const response = await priceQuotesApi.customer.getCustomerPriceQuote(
      eventId,
      otp,
      token
    );

    const data = response
      ? {
          event: response.event || {},
          priceQuote: response["price-quote"] || {}
        }
      : undefined;
    return data;
  };

  const fetchBusinessData = async (): Promise<
    PhotographerPriceQuoteAdditionalData | undefined
  > => {
    return priceQuotesApi.photographer.getPriceQuoteAdditionalData(
      eventId,
      otp,
      undefined
    );
  };

  useEffect(() => {
    (async () => {
      const dataPromise = fetchData();
      const businessDataPromise = fetchBusinessData();
      try {
        const [data, businessData] = await Promise.all([
          dataPromise,
          businessDataPromise
        ]);

        const approveFiles =
          data?.priceQuote["customer-response"]?.approved &&
          data.priceQuote["customer-response"].approved.files
            ? new Map(
                Object.entries(
                  data.priceQuote["customer-response"].approved.files
                )
              )
            : undefined;

        setState((curr) => {
          return {
            ...curr,
            loading: false,
            errorMessage: undefined,
            data,
            files: approveFiles,
            businessData
          };
        });
      } catch {
        setState((curr) => {
          return {
            ...curr,
            loading: false,
            errorMessage: t("error.general"),
            data: undefined,
            businessData: undefined
          };
        });
      }
    })();
  }, [eventId]);

  if (state.loading) {
    return <Loading text={t("generic.fetching-data")} />;
  }

  if (!state.data) {
    return (
      <div className="center-page">
        <h1>{t("errors.page-not-found")}</h1>
      </div>
    );
  }

  const { event, priceQuote } = state.data;
  const lineItems = priceQuote["line-items"] || [];

  const lineItemsTotalPrice = lineItems.reduce(
    (a, b) => a + (b.price || 0) * (b.quantity || 0),
    0
  );

  const discount = (() => {
    const discountValue = priceQuote.discount?.value || 0;

    if (discountValue <= 0) {
      return 0;
    }

    if (priceQuote.discount?.type === DiscountType.Fixed) {
      return discountValue;
    } else {
      return (discountValue / 100) * (lineItemsTotalPrice || 0);
    }
  })();

  const calculatedTax =
    ((priceQuote.tax || 0) / 100) * (lineItemsTotalPrice - discount);

  const totalPrice = lineItemsTotalPrice - discount + calculatedTax;

  const renderView = () => {
    if (isPdf) return undefined;

    const renderModal = () => {
      if (isPdf) return undefined;

      switch (state.showModal) {
        case Modals.None:
          return undefined;
        case Modals.Approve:
          return (
            <UploadFileModal
              title={t("components.approve-price-quote-modal.title")}
              fileList={
                state.files &&
                Array.from(state.files.entries()).map(([key, value]) => ({
                  uid: key,
                  name: value.name || "",
                  thumbUrl: value.file
                }))
              }
              onUpload={async (files) => {
                const fileList = Array.isArray(files) ? files : [files];
                const token = otp ? undefined : await getAuthorizationHeader();
                for (const file of fileList) {
                  const reader = new FileReader();
                  reader.onloadend = async () => {
                    const base64 = reader.result as string;
                    const byteArray = await base64ToByteArray(base64);

                    const imageFile: ApprovedPriceQuoteFileValue = {
                      name: file.name,
                      file: byteArray as unknown as string
                    };
                    if (!imageFile.file) return false;

                    const key = md5(imageFile.file);
                    if (state.files && state.files.has(key)) return true;

                    try {
                      await priceQuotesApi.customer.customerUploadFileApprove(
                        eventId,
                        otp,
                        token,
                        byteArray,
                        file.name
                      );
                      setState((curr) => {
                        const updatedFiles =
                          state.files ??
                          new Map<string, ApprovedPriceQuoteFileValue>();
                        updatedFiles.set(key, imageFile);
                        return {
                          ...curr,
                          files: updatedFiles,
                          errorMessage: undefined
                        };
                      });
                    } catch {
                      return false;
                    }
                  };
                  reader.readAsDataURL(file);
                }
                return true;
              }}
              onApprove={async () => {
                const token = otp ? undefined : await getAuthorizationHeader();

                return priceQuotesApi.customer
                  .approvePriceQuote(eventId, otp, token)
                  .then(() => {
                    setState((curr) => {
                      if (!curr.data) return curr;

                      return {
                        ...curr,
                        data: {
                          ...curr.data,
                          priceQuote: {
                            ...curr.data?.priceQuote,
                            "customer-response": {
                              ...curr.data?.priceQuote?.["customer-response"],
                              approved: {
                                ...curr.data?.priceQuote?.["customer-response"]
                                  ?.approved,
                                finalized: true
                              }
                            }
                          }
                        },
                        showModal: Modals.None
                      };
                    });
                  });
              }}
              onClose={() =>
                setState((curr) => {
                  return { ...curr, showModal: Modals.None };
                })
              }
              locale={uploadFileModalLocale(t)}
              onRemove={async (id) => {
                const token = otp ? undefined : await getAuthorizationHeader();

                return priceQuotesApi.customer
                  .customerRemoveFileApprove(eventId, otp, token, id)
                  .then(() => {
                    setState((curr) => {
                      const updatedFilesMap = new Map(state.files);
                      updatedFilesMap.delete(id);
                      return {
                        ...curr,
                        files: updatedFilesMap
                      };
                    });
                  });
              }}
            />
          );
        case Modals.Reject:
          return (
            <TextBoxModal
              title={t("components.customer.reject-price-quote-modal.title")}
              subTitle={
                <Trans
                  i18nKey={t(
                    "components.customer.reject-price-quote-modal.sub-title"
                  )}
                />
              }
              placeholder={t(
                "components.customer.reject-price-quote-modal.reason"
              )}
              numberOfRows={3}
              locale={textBoxModalLocale(t)}
              onClose={() =>
                setState((curr) => {
                  return {
                    ...curr,
                    showModal: Modals.None
                  };
                })
              }
              onApprove={async (rejected) => {
                const token = otp ? undefined : await getAuthorizationHeader();

                priceQuotesApi.customer
                  .rejectPriceQuote(eventId, otp, token, rejected)
                  .then(() => {
                    setState((curr) => {
                      if (!curr.data) return curr;

                      return {
                        ...curr,
                        showModal: Modals.None,
                        data: {
                          ...curr.data,
                          priceQuote: {
                            ...curr.data?.priceQuote,
                            "customer-response": {
                              ...curr.data?.priceQuote?.["customer-response"],
                              rejectReason: { reason: rejected }
                            }
                          }
                        }
                      };
                    });
                  });
              }}
            />
          );
      }
    };

    const renderNavbar = () => {
      return (
        <>
          <div className="nav-bar">
            <div className="right-menu" style={{ backgroundColor: "#2cbfc9" }}>
              <img src={logo} className="nav-bar-logo" alt="Picky Logo" />
            </div>

            <div className="big-nav-login-container" />
          </div>
          <div className="page-action-bar" />
        </>
      );
    };

    return (
      <>
        {renderModal()}
        {renderNavbar()}
      </>
    );
  };

  const renderDesktopTitle = () => {
    if (pageState.isMobile || isPdf) return undefined;

    return (
      <div className="table-headline">
        <label>{t("pages.price-quote-customer.generic.title")}</label>
      </div>
    );
  };

  const renderPages = () => {
    const headerLogo = state.businessData?.business_logo
      ? state.businessData.business_logo
      : headerLogoPlaceHolder;

    const header = (
      <div
        style={{
          width: "95%",
          margin: "auto",
          textAlign: "right"
        }}
      >
        <div className="img-header" style={{ textAlign: "end" }}>
          <img
            src={headerLogo}
            style={{
              maxHeight: "60px",
              maxWidth: "180px",
              paddingTop: "5px"
            }}
          />
        </div>

        <Row className="origin-row" style={{ marginTop: "12px" }}>
          <Col>
            <h5 className="small-title">
              {t("pages.price-quote-customer.generic.price-quote-title")}
            </h5>
          </Col>
          <Col style={{ textAlign: "left" }}>
            <b>
              {t("pages.price-quote-customer.generic.date")}
              <Moment format="DD/MM/YYYY">
                {new Date(priceQuote?.["update-time"] || 0)}
              </Moment>
            </b>
          </Col>
        </Row>
        <Row className="origin-row">
          {event.name && event.location && (
            <Col>
              {t("pages.price-quote-customer.generic.price-quote-description")}
              {t("pages.events.enums.type." + event.event_type)}
              {t("pages.price-quote-customer.generic.for")
                .replace("{event name}", event.name)
                .replace("{event location}", event.location)}
            </Col>
          )}
          <Col style={{ textAlign: "left" }}>
            <div>
              {t("pages.price-quote-customer.generic.expiration")}
              <Moment format="DD/MM/YYYY">
                {new Date((priceQuote?.["expiration-date"] || 0) * 1000)}
              </Moment>
            </div>
          </Col>
        </Row>
        <Row
          className="divider origin-row"
          style={{ paddingTop: "12px", maxWidth: "100%", margin: "auto" }}
        ></Row>
        <h5 className="small-title" style={{ marginBottom: "0px" }}>
          {t("pages.price-quote-customer.generic.event-title")}
        </h5>
        <Row className="origin-row" style={{ marginTop: "5px" }}>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <table className="event-details-table">
              <tbody>
                <tr>
                  <td>
                    <label>
                      <b>{t("pages.events.generic.name")}: &nbsp;</b>
                    </label>
                    <label>{event.name || ""}</label>
                  </td>
                  <td>
                    <label>
                      <b>{t("pages.events.generic.event-type")}: &nbsp;</b>
                    </label>
                    <label>
                      {t(`pages.events.enums.type.${event.event_type}`)}
                    </label>
                  </td>
                </tr>
                <tr>
                  <td>
                    <label>
                      <b>{t("pages.events.generic.location")}: &nbsp;</b>
                    </label>
                    <label>{event.location || ""}</label>
                  </td>
                  <td>
                    <label>
                      <b>{t("pages.events.generic.setup-location")}: &nbsp;</b>
                    </label>
                    <label>{event.setup_location || "---"}</label>
                  </td>
                </tr>
                <tr>
                  <td>
                    <label>
                      <b>
                        {t("pages.events.generic.number-of-invites")}: &nbsp;
                      </b>
                    </label>
                    <label>{event.number_of_invites || "---"}</label>
                  </td>
                  <td>
                    <label>
                      <b>{t("pages.events.generic.event-time")}: &nbsp;</b>
                    </label>
                    <label>
                      <Moment format="DD/MM/YYYY">
                        {new Date((event.event_time || 0) * 1000)}
                      </Moment>
                    </label>
                  </td>
                </tr>
                <tr>
                  <td>
                    <label>
                      <b>{t("pages.events.generic.customer-phone")}: &nbsp;</b>
                    </label>
                    <label>
                      {formatPhoneNumber(event.customer_phone || "---")}
                    </label>
                  </td>
                  <td>
                    <label>
                      <b>{t("pages.events.generic.customer-email")}: &nbsp;</b>
                    </label>
                    <label>{event.customer_email}</label>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </Row>
        <Row
          className="divider origin-row"
          style={{
            paddingTop: "12px",
            marginBottom: "12px",
            maxWidth: "100%",
            margin: "auto"
          }}
        ></Row>
      </div>
    );

    const renderPage = (
      index: number,
      pages: number,
      lineItems: LineItem[]
    ) => {
      const isLastPage = index + 1 >= pages;

      const table = (() => {
        const additionalData = (() => {
          if (!isLastPage) return <></>;

          return (
            <div
              style={{
                display: "flex",
                justifyContent: "end"
              }}
            >
              <div
                style={{
                  width: "200px",
                  minWidth: "200px"
                }}
              >
                <Row
                  className="flex-space-between"
                  style={{ margin: "auto", width: "100%" }}
                >
                  <Col style={{ textAlign: "center", width: "100px" }}>
                    {t("pages.price-quote-customer.generic.total")}
                  </Col>
                  <Col
                    style={{
                      textAlign: "center",
                      width: "100px"
                    }}
                  >
                    <b>
                      {(lineItemsTotalPrice || 0).toFixed(2) +
                        t("currencies.ils")}
                    </b>
                  </Col>
                </Row>
                <Row
                  className="origin-row"
                  style={{
                    maxWidth: "100%",
                    margin: "auto",
                    borderBottom: "1.5px solid rgb(218, 208, 208)"
                  }}
                />
                {discount > 0 && (
                  <>
                    <Row
                      className="flex-space-between"
                      style={{ margin: "auto", width: "100%" }}
                    >
                      <Col style={{ textAlign: "center", width: "80px" }}>
                        {t("pages.price-quote-customer.generic.discount")}
                      </Col>
                      <Col
                        style={{
                          textAlign: "center",
                          width: "80px"
                        }}
                      >
                        <b>{discount.toFixed(2) + t("currencies.ils")}</b>
                      </Col>
                    </Row>
                    <Row
                      className="origin-row"
                      style={{
                        maxWidth: "100%",
                        margin: "auto",
                        borderBottom: "1.5px solid rgb(218, 208, 208)"
                      }}
                    />
                  </>
                )}

                <Row
                  className="flex-space-between"
                  style={{ margin: "auto", width: "100%" }}
                >
                  <Col style={{ textAlign: "center", width: "80px" }}>
                    {t("pages.price-quote-customer.generic.tax")}
                  </Col>
                  <Col
                    style={{
                      textAlign: "center",
                      width: "80px"
                    }}
                  >
                    <b>{calculatedTax.toFixed(2) + t("currencies.ils")}</b>
                  </Col>
                </Row>

                <Row
                  className="origin-row"
                  style={{
                    maxWidth: "100%",
                    margin: "auto",
                    borderBottom: "1.5px solid rgb(218, 208, 208)"
                  }}
                />
                <Row
                  className="flex-space-between"
                  style={{
                    margin: "auto",
                    marginTop: "5px",
                    width: "100%",
                    backgroundColor: "#2CBFC9",
                    color: "white",
                    borderRadius: "8px",
                    height: "45px"
                  }}
                >
                  <Col
                    style={{
                      alignSelf: "center",
                      textAlign: "start",
                      width: "80px",
                      fontSize: "16px"
                    }}
                  >
                    {t("pages.price-quote-customer.generic.total-price")}
                  </Col>
                  <Col
                    style={{
                      alignSelf: "center",
                      textAlign: "center",
                      minWidth: "80px",
                      fontSize: "16px"
                    }}
                  >
                    <b>{totalPrice.toFixed(2) + t("currencies.ils")}</b>
                  </Col>
                </Row>
              </div>
            </div>
          );
        })();

        return (
          <PriceTable
            offset={index * pageSize}
            lineItems={lineItems}
            header={
              <Row>
                <h5 className="small-title" style={{ marginBottom: "2px" }}>
                  {t("pages.price-quote-customer.generic.table-title")}
                </h5>
              </Row>
            }
            additionalData={additionalData}
          />
        );
      })();

      const customStyle: CSSProperties | undefined = isLastPage
        ? undefined
        : { pageBreakAfter: "always" };

      return (
        <div key={`page-${index}`} style={customStyle}>
          {header}
          {table}
        </div>
      );
    };

    if (isPdf) {
      const pages = Math.ceil(lineItems.length / Number(pageSize));

      const result = [];

      for (let i = 0; i < pages; i++) {
        result.push(
          renderPage(
            i,
            pages,
            lineItems.slice(i * pageSize, (i + 1) * pageSize)
          )
        );
      }

      const lastPageItems = lineItems.length - (pages - 1) * pageSize;

      if (lastPageItems > maxItemsInLastPageWithoutPageBreak) {
        result.push(<div style={{ pageBreakBefore: "always" }}>{header}</div>);
      }

      return result;
    }

    return renderPage(0, 0, lineItems);
  };

  const noteSectionHtml = (() => {
    const downPayment = priceQuote["down-payment"] || 0;

    return (
      <div
        style={{
          width: "95%",
          margin: "auto",
          textAlign: "right"
        }}
      >
        <Row>
          <h5 className="small-title">
            {t("components.photographer.price-quotes-form.generic.notes")}
          </h5>
        </Row>
        <Form.Control
          disabled
          as="textarea"
          value={priceQuote.notes || ""}
          style={{
            backgroundColor: "white",
            fontSize: "12px"
          }}
        />
        <Row style={{ marginTop: "12px" }}>
          <h5 className="small-title">
            {t(
              "components.photographer.price-quotes-form.generic.payment-method"
            )}
          </h5>
        </Row>
        <Form.Control
          disabled
          as="textarea"
          value={(() => {
            if (downPayment > 0) {
              return (
                t(
                  "components.photographer.price-quotes-form.generic.down-payment",
                  { downPayment }
                ) + t("currencies.ils")
              );
            } else {
              return "";
            }
          })()}
          style={{
            backgroundColor: "white",
            fontSize: "12px"
          }}
        />
      </div>
    );
  })();

  const renderActions = () => {
    if (isPdf) {
      return (
        <div className="footer">
          <br />
          <Row style={{ maxWidth: "95%", margin: "auto", height: "20px" }}>
            <Col>
              <hr
                style={{
                  maxWidth: "95%"
                }}
              />
            </Col>
            <Col>
              <hr
                style={{
                  color: "#000000",
                  maxWidth: "95%"
                }}
              />
            </Col>
            <Col>
              <hr
                style={{
                  color: "#000000",
                  maxWidth: "95%"
                }}
              />
            </Col>
          </Row>
          <Row style={{ maxWidth: "95%", margin: "auto" }}>
            <Col>
              <b>{t("pages.price-quote-customer.pdf.full-name")}</b>
            </Col>
            <Col>
              <b>{t("pages.price-quote-customer.pdf.id")}</b>
            </Col>
            <Col>
              <b>{t("pages.price-quote-customer.pdf.signature")}</b>
            </Col>
          </Row>
          <Row
            style={{
              width: "100%",
              backgroundColor: "#2CBFC9",
              justifyContent: "center",
              color: "white",
              margin: "auto",
              fontSize: "15px",
              alignContent: "center",
              alignItems: "center",
              height: "30px"
            }}
          >
            {t("pages.price-quote-customer.pdf.footer")}
            <img
              style={{ width: "55px", height: "20.53px" }}
              src={footerLogo}
            />
          </Row>
        </div>
      );
    } else {
      const downloadPdf = async () => {
        setState((curr) => {
          return {
            ...curr,
            savingPdf: true
          };
        });

        try {
          const token = otp ? undefined : await getAuthorizationHeader();

          const base64RestResponse =
            await priceQuotesApi.customer.getCustomerPdf(eventId, otp, token);

          saveRestResponseFile(
            base64RestResponse,
            `${t("pages.events.manage.price-quote.title")}-${
              event.name || ""
            }.pdf`
          );
        } finally {
          setState((curr) => {
            return {
              ...curr,
              savingPdf: false
            };
          });
        }
      };

      const renderButtons = () => {
        const buttons = [];
        buttons.push(<br />);

        const showActionButtons = !(
          priceQuote["customer-response"]?.approved?.finalized ||
          priceQuote["customer-response"]?.rejected
        );

        if (showActionButtons) {
          buttons.push(
            <Button
              key={"action-button-reject"}
              color="grey"
              size="medium"
              $wide
              text={t("pages.price-quote-customer.generic.reject")}
              onClick={() =>
                setState((curr) => {
                  return {
                    ...curr,
                    showModal: Modals.Reject
                  };
                })
              }
            />
          );
        }

        buttons.push(
          <Button
            key={"action-button-pdf"}
            color="turquoise"
            size="medium"
            $wide
            text={t("pages.price-quote-customer.generic.download-pdf")}
            onClick={downloadPdf}
          />
        );

        if (showActionButtons) {
          buttons.push(
            <Button
              key={"action-button-approve"}
              size="medium"
              $wide
              text={t("pages.price-quote-customer.generic.approve")}
              onClick={() =>
                setState((curr) => {
                  return {
                    ...curr,
                    showModal: Modals.Approve
                  };
                })
              }
            />
          );
        }

        return buttons;
      };

      return (
        <div className="center" style={{ flexWrap: "wrap", padding: "5px" }}>
          {renderButtons()}
        </div>
      );
    }
  };

  const customStyle: CSSProperties | undefined = isPdf
    ? { width: "100%", right: "0px", height: "100%" }
    : { position: "relative", top: "var(--nav-bar-height)" };

  return (
    <>
      {renderView()}
      <div className="data-div" style={customStyle}>
        {renderDesktopTitle()}
        {renderPages()}
        {noteSectionHtml}
        {renderTerms(t, state.data.priceQuote.terms)}
        {renderActions()}
      </div>
    </>
  );
}

export const renderTerms = (
  t: (key: string) => string,
  terms?: string,
  width?: string
) => {
  if (!terms) return undefined;

  const renderPage = (pageNumber: number, paragraphs: JSX.Element[]) => {
    return (
      <div
        key={`terms-page-${pageNumber}`}
        style={{
          marginTop: "40px",
          marginInlineStart: "50px",
          marginInlineEnd: "50px",
          textAlign: "start",
          pageBreakBefore: "always"
        }}
      >
        {paragraphs}
      </div>
    );
  };

  const maxRowsInPage = 49;
  const maxCharInLine = 75;

  const rows = terms.split("\n");
  const pages: JSX.Element[] = [];

  let paragraphs: JSX.Element[] = [];

  paragraphs.push(
    <h5 className="small-title">
      {t("pages.price-quote-customer.generic.terms-title")}
    </h5>
  );

  let currLines = 1;

  for (let i = 0; i < rows.length; i++) {
    const row = rows[i];
    const actualLines = Math.ceil(row.length / maxCharInLine);

    if (currLines + actualLines > maxRowsInPage) {
      pages.push(renderPage(pages.length, paragraphs));
      paragraphs = [];
      currLines = 0;
    } else {
      currLines += actualLines;
    }

    const baseStyle: CSSProperties = {
      overflowWrap: "break-word",
      marginBlockEnd: "auto",
      fontSize: "14px"
    };

    if (width) {
      baseStyle["width"] = width;
      baseStyle["minWidth"] = width;
    }

    paragraphs.push(
      <p
        key={`terms-paragraph-${pages.length}-${paragraphs.length}`}
        style={baseStyle}
      >
        {row}
      </p>
    );
  }

  if (paragraphs.length > 0) {
    pages.push(renderPage(pages.length, paragraphs));
  }

  return pages;
};

export default CustomerPriceQuotesView;
