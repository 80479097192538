import { useEffect, useState } from "react";
import { Dropdown, Form } from "react-bootstrap";
import usePlacesAutocomplete from "use-places-autocomplete";

interface PlacesAutocompleteProps {
  placeholder: string;
  onChange?: (value: string) => void;
  initialValue: string;
  required: boolean;
}
const PlacesAutocomplete = ({
  placeholder,
  onChange,
  initialValue,
  required
}: PlacesAutocompleteProps) => {
  const {
    value,
    suggestions: { status, data },
    setValue
  } = usePlacesAutocomplete();

  const [state, setState] = useState({ focused: false });

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  const onNewValue = (newValue: string) => {
    onChange && onChange(newValue);
    setValue(newValue);
  };

  const handleSelect = (value: string) => () => {
    onNewValue(value);
    setState({
      ...state,
      focused: false
    });
  };

  const renderSuggestions = () =>
    data.map(({ place_id, structured_formatting: { main_text } }) => {
      const text = `${main_text}`;

      return (
        <Dropdown.Item
          className="auto-complete-dropdown-item"
          key={place_id}
          id={place_id}
          onClick={handleSelect(text)}
        >
          {text}
        </Dropdown.Item>
      );
    });

  return (
    <>
      <Form.Group>
        <Form.Control
          required={required}
          type="text"
          placeholder={placeholder}
          onChange={(e) => onNewValue(e.target.value)}
          value={value}
          onFocus={() =>
            setState({
              ...state,
              focused: true
            })
          }
          onBlur={(e) => {
            const target = e.relatedTarget?.className;

            if (target && !target.includes("auto-complete-dropdown-item")) {
              setState({
                ...state,
                focused: false
              });
            }
          }}
        />

        {state.focused && status === "OK" && (
          <Dropdown.Menu show={state.focused}>
            {renderSuggestions()}
          </Dropdown.Menu>
        )}
      </Form.Group>
    </>
  );
};

export default PlacesAutocomplete;
