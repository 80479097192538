import { gateway } from "../constants";
import { Api } from "../generated/swagger/media";

const api = new Api({ baseUrl: gateway.media });

const getPhotographerBusinessLogo = (
  authorization: string
): Promise<string | undefined> => {
  return api.media
    .getPhotographerBusinessLogo({ headers: { authorization } })
    .then((response) => response.data.url);
};

const uploadPhotographerBusinessLogo = (
  authorization: string,
  business_logo: number[]
): Promise<string | undefined> => {
  return api.media
    .uploadPhotographerBusinessLogo(
      { business_logo: business_logo as any },
      { headers: { authorization } }
    )
    .then((response) => response.data.url);
};

export const mediaApi = {
  photographer: {
    getPhotographerBusinessLogo,
    uploadPhotographerBusinessLogo
  }
};
