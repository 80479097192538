import { TFunction } from "react-i18next";
import { UploadFileModalLocale } from "./components/upload-file-modal";
import { TextBoxModalLocale } from "./components/text-box-modal";

export const cognito = {
  identityPoolId: "eu-central-1:a83dca80-adf8-494f-8cf4-498a3c1cc05f",
  region: "eu-central-1",
  identityPoolRegion: "eu-central-1",
  userPoolId: "eu-central-1_mOu6c7t6n",
  userPoolWebClientId: "3f64rf493aoc4f1i4h85ltv9oh",
  domain: "pickyapp-public.auth.eu-central-1.amazoncognito.com",
  redirectSignIn: "https://pickyapp.co.il/login",
  redirectSignOut: "https://pickyapp.co.il/"
};

export const gateway = {
  events: "https://events.pickyapp.co.il",
  priceQuotes: "https://price-quotes.pickyapp.co.il",
  media: "https://media.pickyapp.co.il"
};

export const validateEmail = (email: string) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

export const uploadFileModalLocale = (
  t: TFunction<"translation", undefined>
): UploadFileModalLocale => {
  return {
    upload: t("components.upload-photo-modal.hints.upload"),
    types: t("components.upload-photo-modal.hints.types"),
    genericError: t("errors.generic"),
    typeError: t("components.upload-photo-modal.errors.type"),
    sizeError: t("components.upload-photo-modal.errors.size"),
    approve: t("components.upload-photo-modal.actions.approve"),
    cancel: t("components.upload-photo-modal.actions.cancel")
  };
};

export const textBoxModalLocale = (
  t: TFunction<"translation", undefined>
): TextBoxModalLocale => {
  return {
    approve: t("components.text-box-modal.actions.approve"),
    cancel: t("components.text-box-modal.actions.cancel"),
    reset: t("components.text-box-modal.actions.reset"),
    genericError: t("errors.generic")
  };
};
