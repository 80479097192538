import { Table } from "react-bootstrap";
import PhotographerLineItemTemplate from "./photographer-line-item-template";
import { useTranslation } from "react-i18next";
import {
  ItemType,
  LineItem
} from "../../../../../../generated/swagger/price-quotes";
import { useState } from "react";

interface PhotographerDesktopLineItemTableProps {
  lineItems: LineItem[];
  itemTypesOptions: {
    label: string;
    value: ItemType;
  }[];
  onChange: (value: LineItem[]) => void;
}
function PhotographerDesktopLineItemTable({
  lineItems,
  itemTypesOptions,
  onChange
}: PhotographerDesktopLineItemTableProps) {
  const { t } = useTranslation();
  const [focusedFieldIndex, setFocusedFieldIndex] = useState(0);

  const renderDesktopLineItems = () =>
    lineItems?.map((data, index) => {
      return (
        <PhotographerLineItemTemplate
          key={`line-item-row-${index}`}
          isRequired={true}
          value={data}
          itemTypesOptions={itemTypesOptions}
          focusedIndex={
            (lineItems.length || 1) - 1 === index
              ? focusedFieldIndex
              : undefined
          }
          onChange={(lineItem: LineItem) => {
            const newLineItems = [...(lineItems || [])];
            newLineItems[index] = lineItem;

            onChange(newLineItems);
          }}
          onDelete={() => {
            const newLineItems = [...(lineItems || [])];
            newLineItems.splice(index, 1);

            onChange(newLineItems);
          }}
        />
      );
    });

  return (
    <Table className="table-no-padding">
      <thead>
        <tr>
          <th scope="col" style={{ width: "2%" }}></th>
          <th
            className="label-margin-right"
            scope="col"
            style={{ width: "48%" }}
          >
            {t(
              "components.photographer.price-quotes-form.generic.line-items.name"
            )}
          </th>
          <th scope="col" style={{ width: "17%" }}>
            {t(
              "components.photographer.price-quotes-form.generic.line-items.type"
            )}
          </th>
          <th scope="col" style={{ width: "11%" }}>
            {t(
              "components.photographer.price-quotes-form.generic.line-items.quantity"
            )}
          </th>
          <th scope="col" style={{ width: "11%" }}>
            {t(
              "components.photographer.price-quotes-form.generic.line-items.price"
            )}
          </th>
          <th scope="col" style={{ width: "11%" }}>
            {t(
              "components.photographer.price-quotes-form.generic.line-items.total"
            )}
          </th>
        </tr>
      </thead>
      <tbody style={{ verticalAlign: "middle" }}>
        {renderDesktopLineItems()}
        <PhotographerLineItemTemplate
          isRequired={false}
          value={{}}
          itemTypesOptions={itemTypesOptions}
          onChange={(lineItem, fieldIndex) => {
            const currLineItems = lineItems || [];

            onChange([...currLineItems, lineItem]);
            setFocusedFieldIndex(fieldIndex);
          }}
        />
      </tbody>
    </Table>
  );
}
export default PhotographerDesktopLineItemTable;
