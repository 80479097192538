import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import { getAuthorizationHeader } from "../../../../../amplify";
import { eventsApi } from "../../../../../gateway/events";
import {
  EventStatus,
  PhotographerEventSummary as PhotographerEventSummarySwagger
} from "../../../../../generated/swagger/events";
import { pageSelector } from "../../../../../store";
import {
  photographerEventEditorUrl,
  photographerEventsUrl,
  photographerPriceQuoteEditorUrl
} from "../../../../app-body";
import Loading from "../../../../loading";
import ValidateModal from "../../../../validate-modal/validate-modal";
import deleteIcon from "../../../../../resources/delete.png";
import threeDotsIcon from "../../../../../resources/three-dots.png";
import checkIcon from "../../../../../resources/check-icon.png";
import iIcon from "../../../../../resources/I-icon.png";
import waitingStatusIcon from "../../../../../resources/waiting-status-icon.png";
import confirmedStatusIcon from "../../../../../resources/confirmed-status-icon.png";
import editIcon from "../../../../../resources/edit-line-items.png";
import download from "../../../../../resources/download-price-quote.png";
import produceIcon from "../../../../../resources/produce.png";
import termsIcon from "../../../../../resources/terms.png";
import eyePriceQuote from "../../../../../resources/eye-price-quote.png";
import PhotographerEventSummary from "../components/photographer-event-summary";
import {
  Row,
  Accordion,
  Table,
  Tooltip,
  OverlayTrigger,
  Dropdown
} from "react-bootstrap";
import {
  PhotographerPriceQuote,
  ApprovedPriceQuoteFileValue
} from "../../../../../generated/swagger/price-quotes";
import { priceQuotesApi } from "../../../../../gateway/price-quotes/price-quotes";
import Moment from "react-moment";
import PriceQuoteModal from "./photographer-price-quote-modal";
import {
  FilesToZipped,
  base64ToByteArray,
  saveFilesAsZip
} from "../../../../../utils/base64ToFile";
import {
  textBoxModalLocale,
  uploadFileModalLocale
} from "../../../../../constants";
import { Button } from "picky-style";
import UploadFileModal from "../../../../upload-file-modal";
import md5 from "blueimp-md5";
import PhotographerPriceQuoteProduce from "../../price-quotes/components/produce/photographer-price-quote-produce";
import TextBoxModal from "../../../../text-box-modal";

enum Modals {
  None,
  Approve,
  PriceQuote,
  Terms
}

function PhotographerEventManage() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const pageState = useSelector(pageSelector);
  const params = useParams();
  const eventId = Number(params["eventId"]);

  const [state, setState] = useState({
    loading: true,
    event: undefined as PhotographerEventSummarySwagger | undefined,
    priceQuote: undefined as PhotographerPriceQuote | undefined,
    files: undefined as Map<string, ApprovedPriceQuoteFileValue> | undefined,
    errorMessage: undefined as string | undefined,
    showModal: Modals.None,
    savingPdf: false,
    showProduce: false,
    openDropDown: false
  });

  useEffect(() => {
    (async () => {
      const authorization = await getAuthorizationHeader();
      const eventAsync = eventsApi.photographer.getPhotographerEvent(
        authorization,
        eventId
      );

      const priceQuoteAsync =
        priceQuotesApi.photographer.getPhotographerPriceQuote(
          authorization,
          eventId
        );

      const [event, priceQuote] = await Promise.all([
        eventAsync,
        priceQuoteAsync
      ]);

      const approveFiles =
        priceQuote?.["customer-response"]?.approved &&
        priceQuote["customer-response"].approved.files
          ? new Map(
              Object.entries(priceQuote["customer-response"].approved.files)
            )
          : undefined;
      setState((curr) => {
        return {
          ...curr,
          event,
          priceQuote,
          files: approveFiles,
          loading: false
        };
      });
    })();
  }, [eventId]);

  if (state.loading) {
    return <Loading text={t("generic.fetching-data")} />;
  }

  if (!state.event) {
    return (
      <div className="center-page">
        <h1>{t("errors.page-not-found")}</h1>
      </div>
    );
  }

  const renderMobileTitle = () => {
    return <div className="page-title">{t("pages.events.manage.title")}</div>;
  };

  const renderDesktopTitle = () => {
    return (
      <>
        <div className="table-headline">
          <label>{t("pages.events.manage.title")}</label>
        </div>
        <br />
      </>
    );
  };

  const renderActionButtons = () => {
    const elements = [
      <Button
        key={"edit-event-button"}
        text={t("pages.events.actions.edit")}
        onClick={() =>
          navigate(photographerEventEditorUrl.replace("*", eventId.toString()))
        }
        $loadable={false}
        size="tiny"
      />
    ];

    if (state.event?.event_status !== EventStatus.Archived) {
      elements.push(
        <ValidateModal
          key={"archive-event-button"}
          title={t("pages.events.confirmation.archive.title")}
          confirmationMessage={t("pages.events.confirmation.archive.message")}
          locale={{
            cancel: t("generic.actions.cancel"),
            confirm: t("generic.actions.confirm")
          }}
          text={t("pages.events.actions.archive")}
          onApprove={async () => {
            const authorization = await getAuthorizationHeader();

            const archivedEvent =
              await eventsApi.photographer.archivePhotographerEvent(
                authorization,
                state.event?.id || 0
              );

            setState((curr) => {
              return {
                ...curr,
                event: archivedEvent
              };
            });
          }}
        />
      );
    }

    if (
      state.event?.event_status === EventStatus.Draft ||
      state.event?.event_status === EventStatus.Archived
    ) {
      elements.push(
        <ValidateModal
          key={"delete-event-button"}
          title={t("pages.events.confirmation.delete.title")}
          confirmationMessage={t("pages.events.confirmation.delete.message")}
          locale={{
            cancel: t("generic.actions.cancel"),
            confirm: t("generic.actions.confirm")
          }}
          text={<img src={deleteIcon} className={"delete-icon"} />}
          color="red"
          onApprove={async () => {
            const authorization = await getAuthorizationHeader();

            await eventsApi.photographer.deletePhotographerEvent(
              authorization,
              state.event?.id || 0
            );

            navigate(photographerEventsUrl);
          }}
        />
      );
    }

    return elements;
  };

  const modalHtml = (() => {
    switch (state.showModal) {
      case Modals.None:
        return undefined;
      case Modals.Approve:
        return (
          <UploadFileModal
            title={t("components.approve-price-quote-modal.title")}
            fileList={
              state.files &&
              Array.from(state.files.entries()).map(([key, value]) => ({
                uid: key,
                name: value.name || "",
                thumbUrl: value.file,
                status: "done"
              }))
            }
            onUpload={async (file) => {
              const authorization = await getAuthorizationHeader();
              const reader = new FileReader();
              reader.onloadend = async () => {
                const base64 = reader.result as string;
                const byteArray = await base64ToByteArray(base64);
                const imageFile: ApprovedPriceQuoteFileValue = {
                  name: file.name,
                  file: byteArray as unknown as string
                };
                if (!imageFile.file) return false;

                const key = md5(imageFile.file);
                if (state.files && state.files.has(key)) return true;

                try {
                  await priceQuotesApi.photographer.photographerUploadFileApprove(
                    eventId,
                    authorization,
                    byteArray,
                    file.name
                  );
                  setState((curr) => {
                    const updatedFiles =
                      state.files ??
                      new Map<string, ApprovedPriceQuoteFileValue>();
                    updatedFiles.set(key, imageFile);
                    return {
                      ...curr,
                      files: updatedFiles,
                      errorMessage: undefined
                    };
                  });
                } catch {
                  return false;
                }
              };
              reader.readAsDataURL(file);

              return true;
            }}
            onApprove={async () => {
              const authorization = await getAuthorizationHeader();

              return priceQuotesApi.photographer
                .manuallyApprovePriceQuote(eventId, authorization)
                .then(() => {
                  setState((curr) => {
                    return {
                      ...curr,
                      showModal: Modals.None,
                      priceQuote: {
                        ...curr.priceQuote,
                        "customer-response": {
                          ...curr.priceQuote?.["customer-response"],
                          approved: {
                            ...curr.priceQuote?.["customer-response"]?.approved,
                            finalized: true
                          }
                        }
                      }
                    };
                  });
                });
            }}
            onRemove={async (id) => {
              const authorization = await getAuthorizationHeader();

              priceQuotesApi.photographer
                .photographerRemoveFileApprove(eventId, authorization, id)
                .then(() => {
                  setState((curr) => {
                    const updatedFilesMap = new Map(state.files);
                    updatedFilesMap.delete(id);
                    return {
                      ...curr,
                      files: updatedFilesMap
                    };
                  });
                })
                .catch((error) => console.log(error));
            }}
            onClose={() =>
              setState((curr) => {
                return { ...curr, showModal: Modals.None };
              })
            }
            locale={uploadFileModalLocale(t)}
          />
        );
      case Modals.PriceQuote:
        return (
          state.priceQuote &&
          state.event && (
            <PriceQuoteModal
              priceQuote={state.priceQuote}
              event={state.event}
              close={() => {
                setState((curr) => {
                  return {
                    ...curr,
                    showModal: Modals.None
                  };
                });
              }}
            />
          )
        );
      case Modals.Terms:
        return (
          state.priceQuote && (
            <TextBoxModal
              modalSize="lg"
              value={state.priceQuote.terms}
              disabled={true}
              onClose={() => {
                setState((curr) => {
                  return {
                    ...curr,
                    showModal: Modals.None
                  };
                });
              }}
              title={t("pages.events.price-quote.modal.terms.title")}
              locale={textBoxModalLocale(t)}
            />
          )
        );
    }
  })();

  const priceQuoteHtml = (() => {
    const status = () => {
      if (state.priceQuote?.["customer-response"]?.approved?.finalized) {
        return (
          <>
            <img
              src={confirmedStatusIcon}
              style={{
                height: "18px",
                width: "18px",
                marginBottom: "2px"
              }}
            />
            <span
              style={{
                marginRight: "3px"
              }}
            >
              {t("pages.events.price-quote.status.confirmed")}
            </span>
          </>
        );
      } else if (state.priceQuote?.["customer-response"]?.rejected?.reason) {
        const tooltip = (
          <Tooltip id="tooltip">
            {t("pages.events.price-quote.status.rejected.reason", {
              reason: state.priceQuote?.["customer-response"]?.rejected?.reason
            })}
          </Tooltip>
        );
        return (
          <>
            <OverlayTrigger placement="bottom" overlay={tooltip}>
              <img
                src={iIcon}
                style={{
                  height: "18px",
                  width: "18px",
                  marginBottom: "2px"
                }}
              />
            </OverlayTrigger>
            <span
              style={{
                marginRight: "3px"
              }}
            >
              {t("pages.events.price-quote.status.rejected.title")}
            </span>
          </>
        );
      }
      return (
        <>
          <img
            src={waitingStatusIcon}
            style={{
              height: "18px",
              width: "18px",
              marginBottom: "2px"
            }}
          />
          <span
            style={{
              marginRight: "3px"
            }}
          >
            {t("pages.events.price-quote.status.waiting")}
          </span>
        </>
      );
    };
    const allowedStatus = [
      EventStatus.Draft,
      EventStatus.Event,
      EventStatus.PriceQuote
    ];

    const customerResponseApprovedFiles = (() => {
      const files: FilesToZipped[] = [];
      state.priceQuote?.["customer-response"]?.approved?.files &&
        Object.values(
          state.priceQuote?.["customer-response"]?.approved?.files
        ).map((value) =>
          files.push({ name: value.name || "", url: value.file || "" })
        );
      return files;
    })();

    const body = (() => {
      if (state.priceQuote) {
        const actionDropdown = (
          <Dropdown
            show={state.openDropDown}
            onToggle={() => {
              setState((curr) => {
                return { ...curr, openDropDown: !state.openDropDown };
              });
            }}
          >
            <Dropdown.Toggle
              id="dropdown-custom-components"
              style={{
                border: "none",
                background: "none",
                width: "20px"
              }}
            >
              <img
                src={threeDotsIcon}
                alt="Toggle Button"
                onClick={() => {
                  setState((curr) => {
                    return {
                      ...curr,
                      openDropDown: !state.openDropDown
                    };
                  });
                }}
              />
            </Dropdown.Toggle>

            <Dropdown.Menu style={{ borderRadius: "20px", textAlign: "start" }}>
              <Dropdown.Item
                eventKey="1"
                disabled={
                  state.event.event_status &&
                  !allowedStatus.includes(state.event.event_status)
                }
                onClick={() =>
                  navigate(
                    photographerPriceQuoteEditorUrl.replace(
                      ":eventId",
                      eventId.toString()
                    )
                  )
                }
              >
                <>
                  <img
                    style={{
                      height: "24px",
                      width: "24px",
                      padding: "1px"
                    }}
                    src={editIcon}
                    className={
                      state.event.event_status &&
                      !allowedStatus.includes(state.event.event_status)
                        ? "clickable img-disabled "
                        : "clickable"
                    }
                  />
                  {t("pages.events.price-quote.dropdown.edit")}
                </>
              </Dropdown.Item>
              <Row
                className="divider"
                style={{
                  maxWidth: "97%",
                  margin: "auto"
                }}
              ></Row>
              <Dropdown.Item
                eventKey="2"
                onClick={() =>
                  setState((curr) => {
                    return { ...curr, showModal: Modals.Approve };
                  })
                }
              >
                <>
                  <img
                    style={{
                      height: "16.6px",
                      width: "26.6px",
                      padding: "1px"
                    }}
                    src={checkIcon}
                    className={"clickable"}
                  />
                  {state.priceQuote["customer-response"]?.approved?.finalized
                    ? t("pages.events.price-quote.dropdown.reconfirm")
                    : t("pages.events.price-quote.dropdown.confirm")}
                </>
              </Dropdown.Item>
              <Row
                className="divider"
                style={{
                  maxWidth: "97%",
                  margin: "auto"
                }}
              ></Row>
              <Dropdown.Item
                eventKey="3"
                disabled={!customerResponseApprovedFiles}
                onClick={async () => {
                  const name = `${t("pages.events.manage.price-quote.title")}-${
                    state.event?.name || ""
                  }-${t(
                    "pages.events.manage.price-quote.customer-response.approved"
                  )}`;
                  customerResponseApprovedFiles &&
                    (await saveFilesAsZip(customerResponseApprovedFiles, name));
                }}
              >
                <>
                  <img
                    style={{
                      padding: "1px",
                      paddingLeft: "3px"
                    }}
                    src={download}
                    className={
                      !state.files ? "clickable img-disabled " : "clickable"
                    }
                  />
                  {t("pages.events.price-quote.dropdown.download")}
                </>
              </Dropdown.Item>

              <Row
                className="divider"
                style={{
                  maxWidth: "97%",
                  margin: "auto"
                }}
              ></Row>
              <Dropdown.Item
                eventKey="4"
                onClick={() =>
                  setState((curr) => {
                    return { ...curr, showProduce: true };
                  })
                }
              >
                <>
                  <img
                    style={{
                      padding: "1px",
                      paddingLeft: "3px"
                    }}
                    src={produceIcon}
                  />
                  {t("pages.events.price-quote.dropdown.produce")}
                </>
              </Dropdown.Item>
              <Row
                className="divider"
                style={{
                  maxWidth: "97%",
                  margin: "auto"
                }}
              ></Row>
              <Dropdown.Item
                eventKey="5"
                onClick={() =>
                  setState((curr) => {
                    return { ...curr, showModal: Modals.Terms };
                  })
                }
              >
                <>
                  <img
                    style={{
                      padding: "1px",
                      paddingLeft: "3px"
                    }}
                    src={termsIcon}
                  />
                  {t("pages.events.price-quote.dropdown.terms")}
                </>
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        );

        return (
          <Table
            style={{
              width: "95%",
              margin: "auto",
              textAlign: "center"
            }}
          >
            <thead>
              <tr>
                <th style={{ textAlign: "right" }}>
                  {t("pages.events.price-quote.description")}
                </th>
                <th>{t("pages.events.price-quote.price-quote-status")}</th>
                {!pageState.isMobile && (
                  <th>{t("pages.events.price-quote.expiration-date")}</th>
                )}
                <th>{t("pages.events.price-quote.price")}</th>
                {!pageState.isMobile && (
                  <th>{t("pages.events.price-quote.last-seen")}</th>
                )}
                {!pageState.isMobile && <th></th>}
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr {...{ valign: "middle" }}>
                <td style={{ textAlign: "right" }}>{state.priceQuote?.name}</td>
                <td>{state.priceQuote && status()}</td>
                {!pageState.isMobile && (
                  <td>
                    {state.priceQuote?.["expiration-date"] && (
                      <Moment format="DD/MM/YYYY">
                        {
                          new Date(
                            (state.priceQuote?.["expiration-date"] || 0) * 1000
                          )
                        }
                      </Moment>
                    )}
                  </td>
                )}
                <td>
                  {state.priceQuote?.["total-price"]?.toFixed(2) +
                    t("currencies.ils")}
                </td>
                {!pageState.isMobile && (
                  <td>
                    {state.priceQuote?.["customer-response"]?.["last-seen"] && (
                      <Moment format="DD/MM/YYYY hh:mm:ss">
                        {
                          new Date(
                            state.priceQuote?.["customer-response"]?.[
                              "last-seen"
                            ] || 0
                          )
                        }
                      </Moment>
                    )}
                  </td>
                )}
                <td>
                  <img
                    src={eyePriceQuote}
                    className="clickable"
                    onClick={() => {
                      setState((curr) => {
                        return {
                          ...curr,
                          showModal: Modals.PriceQuote
                        };
                      });
                    }}
                  />
                </td>
                <td
                  style={{
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "10px"
                  }}
                >
                  {actionDropdown}
                </td>
              </tr>
            </tbody>
          </Table>
        );
      } else {
        return t("pages.events.manage.price-quote.empty");
      }
    })();

    return (
      <Accordion.Item eventKey="0">
        <Accordion.Header>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between"
            }}
          >
            <h4>
              <b>{t("pages.events.manage.price-quote.title")}</b>
            </h4>
            {!state.priceQuote &&
              state.event.event_status &&
              allowedStatus.includes(state.event.event_status) && (
                <Button
                  text={t("pages.events.generic.price-quote-editor")}
                  onClick={() =>
                    navigate(
                      photographerPriceQuoteEditorUrl.replace(
                        ":eventId",
                        eventId.toString()
                      )
                    )
                  }
                />
              )}
          </div>
          {"\u00A0"}
          {"\u00A0"}
        </Accordion.Header>
        <Accordion.Body>
          <div className="rounded-accordion-body">{body}</div>
        </Accordion.Body>
      </Accordion.Item>
    );
  })();
  const renderManage = () => {
    return (
      <>
        {modalHtml}
        {pageState.isMobile && renderMobileTitle()}
        <div className="page-action-bar">
          <div className="buttons">{renderActionButtons()}</div>
        </div>
        <div className="data-div">
          {!pageState.isMobile && renderDesktopTitle()}
          {state.event && (
            <PhotographerEventSummary eventSummary={state.event} />
          )}
          <Row
            className="divider"
            style={{ paddingTop: "10px", maxWidth: "97%", margin: "auto" }}
          ></Row>
          <Accordion>{priceQuoteHtml}</Accordion>
        </div>
      </>
    );
  };
  const renderProduce = () => {
    return (
      <PhotographerPriceQuoteProduce
        priceQuoteTitle={state.priceQuote?.name || ""}
        event={state.event || {}}
      />
    );
  };
  return state.showProduce ? renderProduce() : renderManage();
}

export default PhotographerEventManage;
