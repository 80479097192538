import { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { getAuthorizationHeader } from "../../../../../amplify";
import { priceQuotesTemplateApi } from "../../../../../gateway/price-quotes/price-quotes-templates";
import {
  PhotographerPriceQuoteTemplate,
  ExpirationType
} from "../../../../../generated/swagger/price-quotes";
import Loading from "../../../../loading";
import CurrencyInput from "react-currency-input-field";

import { photographerPriceQuotesTemplatesUrl } from "../../../../app-body";
import PhotographerPriceQuotesForm from "../components/form/photographer-price-quotes-form";

function PhotographerPriceQuotesTemplatesEditor() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const params = useParams();
  const name = params["*"];

  const [priceQuoteTemplate, setPriceQuoteTemplate] = useState({
    loading: name ? true : false,
    data: name ? undefined : ({ tax: 17 } as PhotographerPriceQuoteTemplate),
    errorMessage: undefined as string | undefined,
    conflictError: undefined as string | undefined
  });

  const getPriceQuoteTemplate = async (
    name: string
  ): Promise<PhotographerPriceQuoteTemplate | undefined> => {
    const authorization = await getAuthorizationHeader();
    const photographerPriceQuoteTemplate =
      await priceQuotesTemplateApi.photographer.getPhotographerPriceQuotesTemplate(
        authorization,
        name
      );

    return photographerPriceQuoteTemplate;
  };

  useEffect(() => {
    if (name) {
      getPriceQuoteTemplate(String(name))
        .then((res) => {
          setPriceQuoteTemplate((curr) => {
            return {
              ...curr,
              loading: false,
              data: res,
              errorMessage: ""
            };
          });
        })
        .catch(() => {
          setPriceQuoteTemplate((curr) => {
            return {
              ...curr,
              loading: false,
              data: undefined,
              errorMessage: ""
            };
          });
        });
    }
  }, [name]);

  if (priceQuoteTemplate.loading) {
    return <Loading text={t("generic.fetching-data")} />;
  }

  if (!priceQuoteTemplate.data) {
    return (
      <div className="center-page">
        <h1>{t("errors.page-not-found")}</h1>
      </div>
    );
  }

  const action = name ? "edit" : "create";

  const getTitleName = (): string => {
    return action === "create"
      ? "pages.price-quotes-templates.editor.new"
      : "pages.price-quotes-templates.editor.edit";
  };

  const onSubmit = async () => {
    if (!priceQuoteTemplate.data) {
      setPriceQuoteTemplate((curr) => {
        return {
          ...curr,
          loading: false,
          errorMessage: t("errors.generic"),
          conflictError: undefined
        };
      });

      return;
    }

    setPriceQuoteTemplate((curr) => {
      return {
        ...curr,
        loading: false,
        errorMessage: undefined,
        conflictError: undefined
      };
    });

    try {
      const authorization = await getAuthorizationHeader();

      if (action === "create") {
        await priceQuotesTemplateApi.photographer.createPhotographerPriceQuotesTemplate(
          authorization,
          priceQuoteTemplate.data
        );
      } else {
        {
          name &&
            (await priceQuotesTemplateApi.photographer.updatePhotographerPriceQuotesTemplate(
              authorization,
              name,
              priceQuoteTemplate.data
            ));
        }
      }

      navigate(photographerPriceQuotesTemplatesUrl);
    } catch (e) {
      if (e instanceof Response) {
        if (e.status === 409) {
          setPriceQuoteTemplate((curr) => {
            return {
              ...curr,
              errorMessage: undefined,
              conflictError: t(
                "pages.price-quotes-templates.errors.already-exists"
              )
            };
          });

          return;
        }
      }

      setPriceQuoteTemplate((curr) => {
        return {
          ...curr,
          errorMessage: t("errors.generic"),
          conflictError: undefined
        };
      });
    }
  };

  const renderExpiration = (
    <Form.Group className="align-right">
      <Form.Label className="label-margin-right">
        <b>{t("pages.price-quotes-templates.generic.expiration")}</b>
      </Form.Label>
      <CurrencyInput
        required
        className="form-control"
        suffix={` ${
          priceQuoteTemplate.data["expiration-date"]?.value === 1
            ? t("generic.day")
            : t("generic.days")
        }`}
        allowDecimals={false}
        allowNegativeValue={false}
        step={1}
        placeholder={t(
          "pages.price-quotes-templates.generic.expiration-placeholder"
        )}
        min={1}
        onValueChange={(value) =>
          setPriceQuoteTemplate((curr) => {
            return {
              ...curr,
              data: {
                ...curr.data,
                "expiration-date": {
                  type: ExpirationType.OffsetDays,
                  value: Number(value)
                }
              }
            };
          })
        }
        value={priceQuoteTemplate.data["expiration-date"]?.value || ""}
      />
    </Form.Group>
  );

  return (
    <PhotographerPriceQuotesForm
      locale={{
        title: t(getTitleName()),
        name: t("pages.price-quotes-templates.generic.name"),
        namePlaceholder: t(
          "pages.price-quotes-templates.generic.name-placeholder"
        ),
        submit: t("pages.price-quotes-templates.editor.actions.save"),
        abort: t("pages.price-quotes-templates.editor.actions.close")
      }}
      isRequired={false}
      value={priceQuoteTemplate.data}
      render={{
        expiration: renderExpiration
      }}
      backUrl={photographerPriceQuotesTemplatesUrl}
      conflictError={priceQuoteTemplate.conflictError}
      errorMessage={priceQuoteTemplate.errorMessage}
      onChange={(data) =>
        setPriceQuoteTemplate((curr) => {
          return { ...curr, data };
        })
      }
      onError={(errorMessage) =>
        setPriceQuoteTemplate((curr) => {
          return { ...curr, errorMessage };
        })
      }
      onSubmit={onSubmit}
    />
  );
}

export default PhotographerPriceQuotesTemplatesEditor;
