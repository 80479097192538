import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { getAuthorizationHeader } from "../../../../../../amplify";
import { priceQuotesApi } from "../../../../../../gateway/price-quotes/price-quotes";
import { Button } from "picky-style";
import { useTranslation } from "react-i18next";
import copyIcon from "../../../../../../resources/copy-icon.png";
import copiedIcon from "../../../../../../resources/check-icon.png";
import Loading from "../../../../../loading";

interface PhotographerPriceQuoteShareLinkModalProps {
  eventId: number;
  show: boolean;
  closeModal: () => void;
}

function PhotographerPriceQuoteShareLinkModal({
  eventId,
  show,
  closeModal
}: PhotographerPriceQuoteShareLinkModalProps) {
  const { t } = useTranslation();
  const [state, setState] = useState({
    otp: "",
    loading: true,
    icon: copyIcon
  });
  const getLink = async () => {
    const authorization = await getAuthorizationHeader();
    const link = priceQuotesApi.photographer.shareLinkPhotographerPriceQuote(
      authorization,
      eventId
    );
    return link;
  };

  useEffect(() => {
    getLink().then((link) => {
      setState((curr) => {
        return {
          ...curr,
          otp: link.url || "",
          loading: false
        };
      });
    });
  }, []);

  const changeIcon = () => {
    setState((curr) => {
      return {
        ...curr,
        icon: copiedIcon
      };
    });

    // Revert image source after 3 seconds
    setTimeout(() => {
      setState((curr) => {
        return {
          ...curr,
          icon: copyIcon
        };
      });
    }, 3000);
  };

  if (state.loading) {
    return <Loading text={t("generic.fetching-data")} />;
  }

  return (
    <Modal show={show} size="lg" animation={true}>
      <Modal.Header>
        <Modal.Title>
          {t("components.photographer.price-quote-share-modal.title")}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div
          style={{
            backgroundColor: "#9D9D9D0A",
            display: "inline-flex",
            borderRadius: "20px"
          }}
        >
          <CopyToClipboard text={state.otp}>
            <a
              href="javascript:void(0);"
              style={{ alignSelf: "center" }}
              onClick={changeIcon}
            >
              <img src={state.icon} />
            </a>
          </CopyToClipboard>
          <p style={{ marginTop: "1svh", textAlign: "center" }}>{state.otp}</p>
        </div>
        <div className="center">
          <Button
            text={t("components.photographer.price-quote-share-modal.close")}
            onClick={() => closeModal()}
            color="grey"
          />
        </div>
      </Modal.Body>
    </Modal>
  );
}
export default PhotographerPriceQuoteShareLinkModal;
