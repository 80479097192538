import { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { SmartTableColumn } from "../smart-table";
import deleteIcon from "../resources/delete.png";

interface SmartTableFilterModalProps<T> {
  onModalClose: () => void;
  filterableColumns: { index: number; column: SmartTableColumn<T> }[];
  filters: Map<number, unknown>;
  onFilterChange: (filters: Map<number, unknown>) => void;
}

function SmartTableFilterModal<T>({
  onModalClose,
  filterableColumns,
  filters: defaultFilters,
  onFilterChange
}: SmartTableFilterModalProps<T>) {
  const { t } = useTranslation();
  const [filters, setFilters] = useState(defaultFilters);
  const [state, setState] = useState<
    { index: number; column: SmartTableColumn<T> } | undefined
  >(undefined);
  const deleteFilter = (index?: number) => {
    const newFilters = index ? new Map(filters) : new Map<number, unknown>();

    if (index) {
      newFilters.delete(index);
    }

    onFilterChange(newFilters);
    setFilters(newFilters);
  };

  const getFilterPickData = () => {
    return {
      title: t("components.smart-table.generic.pick-sort"),
      body: (
        <ul>
          {filterableColumns.map((filterableColumn) => {
            const renderText = () => {
              if (filters.get(filterableColumn.index)) {
                return <b>{filterableColumn.column.name}</b>;
              } else {
                return <>{filterableColumn.column.name}</>;
              }
            };

            return (
              <li
                key={`smart-table-filter-${filterableColumn.index}`}
                style={{ display: "flex", alignItems: "center" }}
              >
                <>
                  <div style={{ width: "100%" }}>
                    <label
                      className="table-filter-pick clickable"
                      onClick={() => setState(filterableColumn)}
                    >
                      {renderText()}
                    </label>
                  </div>
                  {filters.get(filterableColumn.index) && (
                    <img
                      src={deleteIcon}
                      className="table-filter-icon clickable"
                      onClick={() => deleteFilter(filterableColumn.index)}
                    />
                  )}
                </>
              </li>
            );
          })}
        </ul>
      )
    };
  };

  const getFilterData = (indexedColumn: {
    index: number;
    column: SmartTableColumn<T>;
  }) => {
    return {
      title: indexedColumn.column.name,
      body: indexedColumn.column.filterBy?.render(
        filters.get(indexedColumn.index),
        (filterData: unknown) => {
          const newFilters = new Map(filters);
          newFilters.set(indexedColumn.index, filterData);

          onFilterChange(newFilters);
          setFilters(newFilters);
        }
      )
    };
  };

  const shouldShowGenericDelete = () => {
    return (
      state || [...filters.values()].find((filter) => filter !== undefined)
    );
  };

  const render = () => {
    const data = state ? getFilterData(state) : getFilterPickData();

    return (
      <Modal show onHide={onModalClose}>
        <Modal.Header>
          <Modal.Title>{data.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{data.body}</Modal.Body>
        <Modal.Footer>
          <>
            {state && (
              <Button variant="secondary" onClick={() => setState(undefined)}>
                {t("components.smart-table.actions.back")}
              </Button>
            )}

            {shouldShowGenericDelete() && (
              <Button
                variant="secondary"
                onClick={() => deleteFilter(state?.index)}
              >
                {t("components.smart-table.actions.clear")}
              </Button>
            )}

            {defaultFilters.size > 0 && (
              <Button
                variant="secondary"
                onClick={() => {
                  const newFilters = state ? new Map(filters) : defaultFilters;

                  if (state) {
                    const initialFilter = defaultFilters.get(state.index);

                    initialFilter
                      ? newFilters.set(state.index, initialFilter)
                      : newFilters.delete(state.index);
                  }

                  onFilterChange(newFilters);
                  setFilters(newFilters);
                }}
              >
                {t("components.smart-table.generic.default")}
              </Button>
            )}

            <Button variant="primary" onClick={() => onModalClose()}>
              {t("components.smart-table.actions.close")}
            </Button>
          </>
        </Modal.Footer>
      </Modal>
    );
  };

  return render();
}

export default SmartTableFilterModal;
