import { useTranslation } from "react-i18next";
import TextBoxModal from "../../text-box-modal";
import { textBoxModalLocale } from "../../../constants";
import { renderTerms } from "../customer/customer-price-quotes-view";

interface PhotographerTermsModalProps {
  value?: string;
  initialData?: string;
  onApprove: (terms: string) => Promise<void> | void;
  onClose: () => void;
}

function PhotographerTermsModal({
  value,
  initialData,
  onApprove,
  onClose
}: PhotographerTermsModalProps) {
  const { t } = useTranslation();
  const renderAdditionalContent = (text: string) => (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        padding: "5px"
      }}
    >
      <div style={{ overflow: "auto" }}>{renderTerms(t, text, "695px")}</div>
    </div>
  );

  return (
    <TextBoxModal
      modalSize="lg"
      textAreaStyle={{ fontSize: "14.9px" }}
      title={t(
        "pages.profile-settings.photographer.modals.price-quote-terms.title"
      )}
      validateTitle={t("components.terms-modal.validate.title")}
      validateMassage={t("components.terms-modal.validate.massage")}
      numberOfRows={10}
      locale={textBoxModalLocale(t)}
      value={value}
      initialData={initialData}
      onClose={onClose}
      onApprove={onApprove}
      renderAdditionalContent={renderAdditionalContent}
    />
  );
}
export default PhotographerTermsModal;
