import { useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import addButton from "../resources/add-button.png";
import { pageSelector } from "../store";
import Loading from "./loading";
import SmartTable, { SmartTableColumn } from "./smart-table/smart-table";
import filter from "./smart-table/resources/filter.png";
import { Button } from "picky-style";

export interface EmptyTableData {
  emptyTableTitle: string;
  emptyTableSubTitle: string;
  emptyTableAddButtonClick: () => void;
}

export interface FullTableData<Key, Value> {
  actionElements?: ActionElement<Key, Value>[];
  checkBoxColumn: boolean;
  columns: SmartTableColumn<Value>[];
  onDataClick?: (data: Value) => void;
  onSearch: (data: Value, search: string) => boolean;
}

export interface ActionElement<Key, Value> {
  filter?: (selectedValues: Value[]) => boolean;
  buildElement: (
    selectedValues: Value[],
    currData: Map<Key, Value>,
    updateState: (data?: Map<Key, Value>, selected?: Set<Key>) => void
  ) => JSX.Element;
}

interface TablePageProps<Key, Value> {
  getElements: () => Promise<Value[]>;
  valueToKey: (value: Value) => Key;
  getEmptyTableData: () => EmptyTableData;
  getFullTableData: () => FullTableData<Key, Value>;
  additionalElements?: ActionElement<Key, Value>[];
}

function TablePage<Key, Value>({
  getElements,
  valueToKey,
  getEmptyTableData,
  getFullTableData,
  additionalElements
}: TablePageProps<Key, Value>) {
  const pageState = useSelector(pageSelector);
  const { t } = useTranslation();

  const [tableState, setTableState] = useState({
    data: new Map<Key, Value>(),
    loading: true,
    selected: new Set<Key>(),
    showFilterModal: false,
    search: ""
  });

  useEffect(() => {
    getElements().then((elements) => {
      setTableState((curr) => {
        return {
          ...curr,
          data: new Map<Key, Value>(
            elements.map((element) => [valueToKey(element), element])
          ),
          loading: false
        };
      });
    });
  }, []);

  const selectedElements = [...tableState.data.entries()]
    .filter((entry) => tableState.selected.has(entry[0]))
    .map((entry) => entry[1]);

  const updateState = (data?: Map<Key, Value>, selected?: Set<Key>) => {
    setTableState((curr) => {
      return {
        ...curr,
        data: data || curr.data,
        selected: selected || curr.selected
      };
    });
  };

  const renderEmptyTable = () => {
    const { emptyTableTitle, emptyTableSubTitle, emptyTableAddButtonClick } =
      getEmptyTableData();

    return (
      <>
        <div className="page-action-bar" />
        <div className="data-div">
          <label className="data-div-title">{emptyTableTitle}</label>
          <br />
          <label className="data-div-sub-title">{emptyTableSubTitle}</label>
          <br />
          <br />
          <img
            src={addButton}
            className="data-div-add-button clickable"
            onClick={emptyTableAddButtonClick}
          />
        </div>
      </>
    );
  };

  const renderTable = () => {
    const fullTableData = getFullTableData();

    const onSelectedValue = (
      e: React.ChangeEvent<HTMLInputElement>,
      value: Value
    ) => {
      const key = valueToKey(value);
      const clonedSelected = new Set(tableState.selected);

      e.target.checked ? clonedSelected.add(key) : clonedSelected.delete(key);

      setTableState((curr) => {
        return {
          ...curr,
          selected: clonedSelected
        };
      });
    };

    const renderActionButtons = () => {
      if (
        !fullTableData.actionElements ||
        fullTableData.actionElements.length === 0
      ) {
        return <></>;
      }

      const actionElements = fullTableData.actionElements
        .filter((actionElement) =>
          actionElement.filter ? actionElement.filter(selectedElements) : true
        )
        .map((actionElement) =>
          actionElement.buildElement(
            selectedElements,
            tableState.data,
            updateState
          )
        );

      return actionElements;
    };

    const headerCheckBox = () => {
      const checked = tableState.selected.size === tableState.data.size;
      const handleClick = () => {
        const clonedSelected = checked
          ? new Set<Key>()
          : new Set<Key>([...tableState.data.keys()]);

        setTableState((curr) => {
          return {
            ...curr,
            selected: clonedSelected
          };
        });
      };

      return (
        <input
          className="checkbox"
          type="checkbox"
          onChange={handleClick}
          checked={checked}
        />
      );
    };

    const columns: SmartTableColumn<Value>[] = fullTableData.checkBoxColumn
      ? [
          {
            name: "",
            headerValue: headerCheckBox(),
            dataMapper: (value) => {
              return (
                <input
                  className="checkbox"
                  type="checkbox"
                  onChange={(e) => onSelectedValue(e, value)}
                  checked={tableState.selected.has(valueToKey(value))}
                />
              );
            },
            blockClickPropagate: true
          },
          ...fullTableData.columns
        ]
      : fullTableData.columns;

    const data = [...tableState.data.values()];
    const filteredData =
      tableState.search.length > 0
        ? data.filter((curr) => {
            const searchToLower = tableState.search.toLocaleLowerCase();

            return fullTableData.onSearch(curr, searchToLower);
          })
        : data;

    return (
      <>
        <div className="page-action-bar">
          <div style={{ display: "flex", alignItems: "center" }}>
            <div className="buttons">{renderActionButtons()}</div>
            <div className="left-buttons">
              <Row className="align-items-center">
                {!pageState.isMobile && (
                  <Col>
                    <Form.Control
                      type="search"
                      className="search"
                      placeholder={t("generic.search")}
                      onChange={(e) =>
                        setTableState((curr) => {
                          return { ...curr, search: e.target.value };
                        })
                      }
                      value={tableState.search}
                    />
                  </Col>
                )}

                <Col xs="auto">
                  <Button
                    $loadable={false}
                    color="turquoise"
                    text={
                      <>
                        <img src={filter} className="table-page-filter-icon" />
                        {t("components.smart-table.actions.filter")}
                      </>
                    }
                    size="tiny"
                    onClick={() =>
                      setTableState((curr) => {
                        return {
                          ...curr,
                          showFilterModal: true
                        };
                      })
                    }
                  />
                </Col>
              </Row>
            </div>
          </div>

          {pageState.isMobile && (
            <Form.Control
              type="search"
              className="search"
              placeholder={t("generic.search")}
              onChange={(e) =>
                setTableState((curr) => {
                  return { ...curr, search: e.target.value };
                })
              }
              value={tableState.search}
              style={{
                width: "97%",
                margin: "auto",
                marginTop: "8px",
                marginBottom: "8px"
              }}
            />
          )}
        </div>

        <div className="data-div">
          <SmartTable
            keyId="data-table"
            className="table-page-table"
            data={filteredData}
            columns={columns}
            onDataClick={fullTableData.onDataClick}
            customFilter={{
              showSmartTableFilter: false,
              onFilterClose: () => {
                setTableState((curr) => {
                  return {
                    ...curr,
                    showFilterModal: false
                  };
                });
              },
              showFilterModal: tableState.showFilterModal
            }}
          />
        </div>
      </>
    );
  };

  const renderLoading = () => {
    return <Loading text={t("generic.fetching-data")} />;
  };

  if (tableState.loading) {
    return renderLoading();
  }

  const table = tableState.data.size === 0 ? renderEmptyTable() : renderTable();
  const renderAdditionalElements = () => {
    return additionalElements
      ?.filter((additionalElement) =>
        additionalElement.filter
          ? additionalElement.filter(selectedElements)
          : true
      )
      .map((additionalElement) =>
        additionalElement.buildElement(
          selectedElements,
          tableState.data,
          updateState
        )
      );
  };

  return (
    <>
      {table}
      {renderAdditionalElements()}
    </>
  );
}

export default TablePage;
