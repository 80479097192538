import { Modal, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { getAuthorizationHeader } from "../../../../../amplify";
import { priceQuotesApi } from "../../../../../gateway/price-quotes/price-quotes";
import { PhotographerPriceQuote } from "../../../../../generated/swagger/price-quotes";
import { useState } from "react";
import { Button } from "picky-style";

interface ConfirmUpsertModalProps {
  eventId: number;
  priceQuote: PhotographerPriceQuote;
  show: boolean;
  close: (confirm: boolean, priceQuote: PhotographerPriceQuote) => void;
}

function ConfirmUpsertModal({
  eventId,
  priceQuote,
  show,
  close
}: ConfirmUpsertModalProps) {
  const { t } = useTranslation();
  const [error, setError] = useState(undefined as string | undefined);

  return (
    <Modal show={show}>
      <Modal.Header>
        <Modal.Title className="modal-title">
          {t("components.photographer.price-quote-confirm-upsert-modal.title")}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          {t(
            "components.photographer.price-quote-confirm-upsert-modal.description"
          )}
          <div className="center">
            <Button
              text={t(
                "components.photographer.price-quote-mail-modal.actions.cancel"
              )}
              onClick={() => close(false, priceQuote)}
              color="grey"
              size="small"
            />
            <Button
              text={t(
                "components.photographer.price-quote-mail-modal.actions.send"
              )}
              onClick={async () => {
                try {
                  const authorization = await getAuthorizationHeader();
                  const persistedPriceQuote =
                    await priceQuotesApi.photographer.upsertPriceQuote(
                      authorization,
                      eventId,
                      priceQuote
                    );
                  close(true, persistedPriceQuote);
                } catch (e) {
                  close;
                  setError(t("errors.generic"));
                }
              }}
              size="small"
            />
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
export default ConfirmUpsertModal;
