import { useTranslation } from "react-i18next";

function NotFound() {
  const { t } = useTranslation();

  return (
    <div style={{ textAlign: "center" }}>
      <h1>{t("errors.page-not-found")}</h1>
    </div>
  );
}

export default NotFound;
