import { useEffect, useState } from "react";
import { Form, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { getAuthorizationHeader } from "../../../../../amplify";
import { eventsApi } from "../../../../../gateway/events";
import { priceQuotesApi } from "../../../../../gateway/price-quotes/price-quotes";
import { PhotographerEventSummary as PhotographerEventSummarySwagger } from "../../../../../generated/swagger/events";
import {
  DiscountType,
  ExpirationType,
  PhotographerPriceQuote
} from "../../../../../generated/swagger/price-quotes";
import { photographerEventManageUrl } from "../../../../app-body";
import FormDateInput from "../../../../form-date-input";
import Loading from "../../../../loading";
import PhotographerEventSummary from "../../events/components/photographer-event-summary";
import PhotographerPriceQuoteProduce from "../components/produce/photographer-price-quote-produce";
import PhotographerPriceQuotesForm from "../components/form/photographer-price-quotes-form";
import PhotographerPriceQuotesTemplatePickerModal from "./photographer-price-quotes-template-picker-modal";
import ConfirmUpsertModal from "./photographer-price-quote-confirm-upsert-modal";
import { Button } from "picky-style";
import PhotographerTermsModal from "../../photographer-terms-modal";

enum Modals {
  None,
  LoadTemplateModal,
  ConfirmModal,
  PriceQuoteTermsModal
}

function PhotographerPriceQuoteEditor() {
  const { t } = useTranslation();

  const params = useParams();
  const eventId = Number(params["eventId"]);

  const [state, setState] = useState({
    loading: true,
    event: undefined as PhotographerEventSummarySwagger | undefined,
    priceQuote: undefined as PhotographerPriceQuote | undefined,
    photographerTerms: undefined as string | undefined,
    errorMessage: undefined as string | undefined,
    showModal: Modals.None,
    showProduce: false,
    isDirty: false
  });

  useEffect(() => {
    (async () => {
      const authorization = await getAuthorizationHeader();

      const eventAsync = eventsApi.photographer.getPhotographerEvent(
        authorization,
        eventId
      );

      const priceQuoteAsync =
        priceQuotesApi.photographer.getPhotographerPriceQuote(
          authorization,
          eventId
        );

      const photographerPriceQuoteAdditionalDataAsync =
        priceQuotesApi.photographer.getPriceQuoteAdditionalData(
          eventId,
          undefined,
          authorization
        );

      const [event, priceQuote, photographerPriceQuoteAdditionalData] =
        await Promise.all([
          eventAsync,
          priceQuoteAsync,
          photographerPriceQuoteAdditionalDataAsync
        ]);

      setState((curr) => {
        return {
          ...curr,
          event,
          priceQuote: priceQuote || {
            tax: 17,
            discount: { type: DiscountType.Fixed, value: 0 },
            terms: photographerPriceQuoteAdditionalData?.terms
          },
          photographerTerms: photographerPriceQuoteAdditionalData?.terms,
          loading: false
        };
      });
    })();
  }, [eventId]);

  if (state.loading) {
    return <Loading text={t("generic.fetching-data")} />;
  }

  if (!state.event || !state.priceQuote) {
    return (
      <div className="center-page">
        <h1>{t("errors.page-not-found")}</h1>
      </div>
    );
  }
  const modalHtml = (() => {
    switch (state.showModal) {
      case Modals.None:
        return undefined;
      case Modals.ConfirmModal:
        return state.priceQuote && eventId ? (
          <ConfirmUpsertModal
            priceQuote={state.priceQuote}
            eventId={eventId}
            show={true}
            close={(confirm, priceQuote) => {
              setState((curr) => {
                return {
                  ...curr,
                  showProduce: confirm,
                  showModal: Modals.None,
                  priceQuote: priceQuote
                };
              });
            }}
          />
        ) : undefined;
      case Modals.PriceQuoteTermsModal: {
        return (
          <PhotographerTermsModal
            value={state.priceQuote.terms}
            initialData={state.photographerTerms}
            onClose={() => {
              setState((curr) => {
                return {
                  ...curr,
                  showModal: Modals.None
                };
              });
            }}
            onApprove={(terms) => {
              setState((curr) => {
                return {
                  ...curr,
                  priceQuote: {
                    ...curr.priceQuote,
                    terms
                  },
                  showModal: Modals.None,
                  isDirty: true
                };
              });
            }}
          />
        );
      }
      case Modals.LoadTemplateModal: {
        return (
          <PhotographerPriceQuotesTemplatePickerModal
            show={true}
            onItemPick={(item) => {
              const expirationDate = (() => {
                if (item["expiration-date"]) {
                  if (item["expiration-date"].type === ExpirationType.Date) {
                    return item["expiration-date"].value;
                  } else {
                    return (
                      (Date.now() +
                        (item["expiration-date"].value || 0) * 86400000) /
                      1000
                    );
                  }
                }

                return undefined;
              })();

              setState((curr) => {
                return {
                  ...curr,
                  priceQuote: {
                    ...curr.priceQuote,
                    name: item.name,
                    "photographer-phone": item["photographer-phone"],
                    discount: item.discount,
                    tax: item.tax,
                    "down-payment": item["down-payment"],
                    "line-items": item["line-items"],
                    notes: item.notes,
                    "email-content": item["email-content"],
                    "expiration-date": expirationDate
                  },
                  showModal: Modals.None,
                  isDirty: true
                };
              });
            }}
            onAbort={() =>
              setState((curr) => {
                return {
                  ...curr,
                  showModal: Modals.None
                };
              })
            }
          />
        );
      }
    }
  })();

  const beforeFormHtml = (
    <>
      <div style={{ float: "left", marginLeft: "1.2svw" }}>
        <Button
          text={t("pages.price-quote-editor.actions.terms")}
          size="small"
          onClick={() => {
            setState((curr) => {
              return { ...curr, showModal: Modals.PriceQuoteTermsModal };
            });
          }}
        />
      </div>

      <PhotographerEventSummary eventSummary={state.event} />
      <Row
        className="divider"
        style={{ paddingTop: "10px", maxWidth: "97%", margin: "auto" }}
      ></Row>
      <br />
    </>
  );

  const expirationHtml = (
    <Form.Group className="align-right">
      <Form.Label className="label-margin-right">
        <b>{t("pages.price-quotes-templates.generic.expiration")}</b>
      </Form.Label>
      <FormDateInput
        required
        onChange={(newValue) =>
          setState((curr) => {
            return {
              ...curr,
              priceQuote: {
                ...curr.priceQuote,
                "expiration-date": newValue
              },
              isDirty: true
            };
          })
        }
        value={state.priceQuote["expiration-date"]}
      />
    </Form.Group>
  );

  const additionalButtonsHtml = [
    <Button
      key="load-template-button"
      text={t("pages.price-quote-editor.actions.load-template")}
      onClick={() => {
        setState((curr) => {
          return {
            ...curr,
            showModal: Modals.LoadTemplateModal
          };
        });
      }}
      $primary={false}
      size="tiny"
      $loadable={false}
    />
  ];

  const onSubmit = async () => {
    if (!state.priceQuote) return;
    if (
      state.isDirty &&
      (state.priceQuote["customer-response"]?.approved ||
        state.priceQuote["customer-response"]?.rejected)
    ) {
      setState((curr) => {
        return {
          ...curr,
          showModal: Modals.ConfirmModal
        };
      });
    } else if (state.isDirty) {
      try {
        const authorization = await getAuthorizationHeader();
        await priceQuotesApi.photographer.upsertPriceQuote(
          authorization,
          eventId,
          state.priceQuote
        );
        setState((curr) => {
          return {
            ...curr,
            showProduce: true
          };
        });
      } catch (e) {
        setState((curr) => {
          return { ...curr, errorMessage: t("errors.generic") };
        });
      }
    } else {
      setState((curr) => {
        return {
          ...curr,
          showProduce: true
        };
      });
    }
  };
  const renderProduce = (
    <PhotographerPriceQuoteProduce
      event={state.event}
      priceQuoteTitle={state.priceQuote.name || ""}
    />
  );
  const renderEditor = (
    <>
      {modalHtml}
      <PhotographerPriceQuotesForm
        locale={{
          title: t("pages.price-quote-editor.editor.title"),
          name: t("pages.price-quote-editor.generic.name"),
          namePlaceholder: t(
            "pages.price-quote-editor.generic.name-placeholder"
          ),
          submit: t("pages.price-quote-editor.actions.submit"),
          abort: t("pages.price-quote-editor.actions.abort")
        }}
        isRequired={true}
        value={state.priceQuote}
        render={{
          beforeForm: beforeFormHtml,
          expiration: expirationHtml,
          additionalButtons: additionalButtonsHtml
        }}
        backUrl={photographerEventManageUrl.replace(
          ":eventId",
          eventId.toString()
        )}
        errorMessage={state.errorMessage}
        onChange={(priceQuote) => {
          setState((curr) => {
            return {
              ...curr,
              priceQuote,
              isDirty: true
            };
          });
        }}
        onSubmit={onSubmit}
        onError={(errorMessage) => {
          setState((curr) => {
            return {
              ...curr,
              errorMessage
            };
          });
        }}
      />
    </>
  );
  return state.showProduce ? renderProduce : renderEditor;
}

export default PhotographerPriceQuoteEditor;
