import { Button } from "picky-style";
import { useState } from "react";
import { Modal } from "react-bootstrap";

interface ValidateModalProps {
  title: string;
  confirmationMessage: string;
  locale: ValidateModalLocale;
  text: JSX.Element | string;
  color?: "orange" | "grey" | "turquoise" | "white" | "red";
  primary?: boolean;
  size?: "tiny" | "small" | "medium" | "large";
  disabled?: boolean;
  onApprove: () => Promise<void> | void;
}

interface ValidateModalLocale {
  confirm: string;
  cancel: string;
}

function ValidateModal({
  title,
  confirmationMessage,
  locale,
  text,
  color,
  primary,
  size,
  disabled,
  onApprove
}: ValidateModalProps) {
  const [show, setShow] = useState(false);

  return (
    <>
      <Button
        disabled={disabled}
        onClick={() => setShow(true)}
        text={text}
        color={color}
        $primary={primary}
        size={size || "tiny"}
        $loadable={false}
      />

      <Modal show={show} style={{ whiteSpace: "pre-line" }}>
        <Modal.Header>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{confirmationMessage}</Modal.Body>
        <Modal.Footer style={{ justifyContent: "center" }}>
          <Button
            onClick={() => setShow(false)}
            text={locale.cancel}
            size="small"
            $primary={false}
          />
          <Button
            onClick={async () => {
              await onApprove();
              setShow(false);
            }}
            text={locale.confirm}
            size="small"
          />
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default ValidateModal;
