import { Api, PriceQuoteItem } from "../../generated/swagger/price-quotes";
import { gateway } from "../../constants";

const api = new Api({ baseUrl: gateway.priceQuotes });

const getPhotographerPriceQuotesItemsTemplates = (
  authorization: string,
  pageNumber: number,
  pageSize: number
): Promise<PriceQuoteItem[]> => {
  return api.photographer
    .getPriceQuotesItemsTemplates(
      {
        "page-number": pageNumber,
        "page-size": pageSize
      },
      { headers: { authorization } }
    )
    .then((response) => response.data["price-quote-items-templates"] || []);
};

const getPhotographerPriceQuotesItemTemplate = (
  authorization: string,
  itemName: string
): Promise<PriceQuoteItem | undefined> => {
  return api.photographer
    .getPriceQuoteItemTemplate(
      { "item-name": itemName },
      {
        headers: { authorization }
      }
    )
    .then((response) => response.data["price-quote-item"]);
};

const createPhotographerPriceQuotesItemTemplate = (
  authorization: string,
  request: PriceQuoteItem
): Promise<PriceQuoteItem> => {
  return api.photographer
    .createPriceQuoteItemTemplate(
      { "price-quote-item-template": request },
      { headers: { authorization } }
    )
    .then((response) => response.data["price-quote-item-template"] || {});
};

const updatePhotographerPriceQuotesItemTemplate = (
  authorization: string,
  itemName: string,
  request: PriceQuoteItem
): Promise<PriceQuoteItem> => {
  return api.photographer
    .updatePriceQuoteItemTemplate(
      { "item-name": itemName },
      { "price-quote-item-template": request },
      { headers: { authorization } }
    )
    .then((response) => response.data["price-quote-item"] || {});
};

const deletePhotographerPriceQuotesItemTemplate = (
  authorization: string,
  itemName: string
): Promise<void> => {
  return api.photographer
    .deletePriceQuoteItemTemplate(
      { "item-name": itemName },
      {
        headers: { authorization }
      }
    )
    .then((response) => response.data);
};

export const priceQuotesItemsApi = {
  photographer: {
    getPhotographerPriceQuotesItemsTemplates,
    getPhotographerPriceQuotesItemTemplate,
    createPhotographerPriceQuotesItemTemplate,
    updatePhotographerPriceQuotesItemTemplate,
    deletePhotographerPriceQuotesItemTemplate
  }
};
