/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface Account {
  email: string;
  phone?: string;
  name?: string;
  avatar?: string;
  given_name?: string;
  family_name?: string;
  photographer_data?: PhotographerData;
  customer_data?: CustomerData;
  type: AccountType;
}

export interface PhotographerData {
  business_name?: string;
  business_id?: string;
  business_logo?: string;
}

export type CustomerData = any;

export enum AccountType {
  Customer = "customer",
  Photographer = "photographer"
}
