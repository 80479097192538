import "../spinner.css";
import { useSelector } from "react-redux";
import { accountSelector, pageSelector } from "../store";

interface LoadingProps {
  text: string;
}

function Loading({ text }: LoadingProps) {
  const account = useSelector(accountSelector);
  const pageState = useSelector(pageSelector);

  const showSideBar = account && !pageState.isMobile;
  const className = showSideBar ? "page-content-with-right-menu" : "";

  return (
    <div className="center-page">
      <div className={className}>
        <div className="lds-ellipsis">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
        <h1>{text}</h1>
      </div>
    </div>
  );
}

export default Loading;
