import { useState } from "react";
import { useTranslation } from "react-i18next";
import { getAuthorizationHeader } from "../../../../../amplify";
import { priceQuotesItemsApi } from "../../../../../gateway/price-quotes/price-quotes-items-templates";
import { PriceQuoteItem } from "../../../../../generated/swagger/price-quotes";
import { SmartTableColumn } from "../../../../smart-table/smart-table";
import TablePage, {
  ActionElement,
  EmptyTableData,
  FullTableData
} from "../../../../table-page";
import ValidateModal from "../../../../validate-modal/validate-modal";
import PhotographerItemsTemplatesModal from "./photographer-items-templates-modal";
import deleteIcon from "../../../../../resources/delete.png";
import {
  smartTableChoiceFilter,
  smartTableNumberRangeFilter,
  smartTableStringContainsFilter
} from "../../../../smart-table/smart-table-filter";
import {
  smartTableNumberSorter,
  smartTableStringSorter
} from "../../../../smart-table/smart-table-sort";
import { itemTypes } from "../../../../../data/price-quotes-constants";
import { Button } from "picky-style";

function PhotographerItemsTemplates() {
  const { t } = useTranslation();
  const [editorData, setEditorData] = useState<PriceQuoteItem | undefined>(
    undefined
  );

  const getElements = async (): Promise<PriceQuoteItem[]> => {
    const authorization = await getAuthorizationHeader();
    const photographerPriceQuotesItemsTemplates =
      //we dont expect pagination on early point, we will handle that later in the future
      await priceQuotesItemsApi.photographer.getPhotographerPriceQuotesItemsTemplates(
        authorization,
        0,
        1000
      );

    return photographerPriceQuotesItemsTemplates;
  };

  const valueToKey = (priceQuoteItem: PriceQuoteItem): string => {
    return priceQuoteItem.name || "";
  };

  const getEmptyTableData = (): EmptyTableData => {
    return {
      emptyTableTitle: t("pages.price-quotes-items-templates.empty.title"),
      emptyTableSubTitle: t(
        "pages.price-quotes-items-templates.empty.sub-title"
      ),
      emptyTableAddButtonClick: () => setEditorData({})
    };
  };

  const getFullTableData = (): FullTableData<string, PriceQuoteItem> => {
    const actionElements: ActionElement<string, PriceQuoteItem>[] = [
      {
        buildElement: () => {
          return (
            <Button
              key="create-price-quote-item-template-button"
              text={t("pages.price-quotes-items-templates.actions.create")}
              onClick={() => setEditorData({})}
              size="tiny"
              $loadable={false}
            />
          );
        }
      },
      {
        filter: (selectedValues) => {
          return selectedValues.length === 1;
        },
        buildElement: (selectedValues) => {
          return (
            <Button
              key="edit-price-quote-item-template-button"
              text={t("pages.price-quotes-items-templates.actions.edit")}
              onClick={() => setEditorData(selectedValues[0])}
              size="tiny"
              $loadable={false}
            />
          );
        }
      },
      {
        filter: (selectedValues) => {
          return selectedValues.length > 0;
        },
        buildElement: (selectedValues, currData, updateState) => {
          const [title, confirmMassage] =
            selectedValues.length > 1
              ? [
                  t(
                    "pages.price-quotes-items-templates.confirmation.delete-multiple.title"
                  ),
                  t(
                    "pages.price-quotes-items-templates.confirmation.delete-multiple.message"
                  )
                ]
              : [
                  t(
                    "pages.price-quotes-items-templates.confirmation.delete.title"
                  ),
                  t(
                    "pages.price-quotes-items-templates.confirmation.delete.message"
                  )
                ];

          return (
            <ValidateModal
              key={"delete-price-quotes-item-template-button"}
              title={title}
              confirmationMessage={confirmMassage}
              locale={{
                cancel: t("generic.actions.cancel"),
                confirm: t("generic.actions.confirm")
              }}
              text={<img src={deleteIcon} className={"delete-icon"} />}
              color="red"
              onApprove={async () => {
                const authorization = await getAuthorizationHeader();

                for (let i = 0; i < selectedValues.length; i++) {
                  await priceQuotesItemsApi.photographer.deletePhotographerPriceQuotesItemTemplate(
                    authorization,
                    valueToKey(selectedValues[i])
                  );
                }

                const clonedMap = new Map(currData);
                selectedValues.forEach((item) =>
                  clonedMap.delete(valueToKey(item))
                );
                updateState(clonedMap, new Set<string>());
              }}
            />
          );
        }
      }
    ];

    const columns: SmartTableColumn<PriceQuoteItem>[] = [
      {
        name: t("pages.price-quotes-items-templates.generic.name"),
        dataMapper: (item) => {
          return item.name;
        },
        filterBy: smartTableStringContainsFilter(
          (data: PriceQuoteItem) => data.name
        ),
        sortBy: smartTableStringSorter((data: PriceQuoteItem) => data.name)
      },
      {
        name: t("pages.price-quotes-items-templates.generic.item-type"),
        dataMapper: (item) => {
          return t(
            `pages.price-quotes-items-templates.enums.type.${
              item["item-type"] || "unknown"
            }`
          );
        },
        filterBy: smartTableChoiceFilter(
          (data: PriceQuoteItem) => data["item-type"],
          itemTypes.map((itemType) => {
            return {
              label: t(
                `pages.price-quotes-items-templates.enums.type.${
                  itemType || "unknown"
                }`
              ),
              value: itemType
            };
          }),
          true
        ),
        sortBy: smartTableStringSorter((data: PriceQuoteItem) =>
          t(
            `pages.price-quotes-items-templates.enums.type.${
              data["item-type"] || "unknown"
            }`
          )
        )
      },
      {
        name: t("pages.price-quotes-items-templates.generic.price"),
        dataMapper: (item) => {
          return String(item.price) + t("currencies.ils");
        },
        filterBy: smartTableNumberRangeFilter(
          (data: PriceQuoteItem) => data.price
        ),
        sortBy: smartTableNumberSorter((data: PriceQuoteItem) => data.price)
      }
    ];

    const onSearch = (data: PriceQuoteItem, search: string) => {
      return (
        data.name?.toLocaleLowerCase()?.includes(search) ||
        data["item-type"]?.toLocaleLowerCase()?.includes(search) ||
        false
      );
    };

    return {
      actionElements,
      checkBoxColumn: true,
      columns,
      onSearch
    };
  };

  const additionalElements: ActionElement<string, PriceQuoteItem>[] = [
    {
      buildElement: (selectedValues, currData, updateState) => {
        if (!editorData) return <div key={"items-templates-modal"} />;

        return (
          editorData && (
            <PhotographerItemsTemplatesModal
              key={"items-templates-modal"}
              initialData={editorData}
              close={(newData) => {
                if (newData && Object.keys(newData).length === 0) {
                  setEditorData(undefined);
                } else {
                  setEditorData(undefined);
                  const newSelected =
                    editorData.name === newData.name
                      ? undefined
                      : (() => {
                          const newSelected = new Set<string>(
                            selectedValues.map(valueToKey)
                          );

                          if (newSelected.delete(valueToKey(editorData)))
                            newSelected.add(valueToKey(newData));

                          return newSelected;
                        })();

                  const newMap = new Map<string, PriceQuoteItem>(currData);
                  newMap.delete(valueToKey(editorData));
                  newMap.set(valueToKey(newData), newData);

                  updateState(newMap, newSelected);
                }
              }}
            />
          )
        );
      }
    }
  ];

  return (
    <TablePage
      getElements={getElements}
      valueToKey={valueToKey}
      getEmptyTableData={getEmptyTableData}
      getFullTableData={getFullTableData}
      additionalElements={additionalElements}
    />
  );
}

export default PhotographerItemsTemplates;
