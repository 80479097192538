import { Auth } from "aws-amplify";
import { AccountType } from "../generated/swagger/auth";
import { CognitoHostedUIIdentityProvider } from "@aws-amplify/auth";

export const useAuthHook = () => {
  return {
    customerLogin: () => {
      localStorage.setItem("accountType", AccountType.Customer);

      Auth.federatedSignIn({
        provider: CognitoHostedUIIdentityProvider.Google
      });
    },
    photographerLogin: () => {
      localStorage.setItem("accountType", AccountType.Photographer);

      Auth.federatedSignIn({
        provider: CognitoHostedUIIdentityProvider.Google
      });
    },
    logout: () => {
      Auth.signOut();
    }
  };
};
