import { Row, Col, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { PhotographerData } from "../../../generated/swagger/auth";
import logoPlaceHolder from "../../../resources/logo-placeholder.png";
import { useState } from "react";
import { uploadFileModalLocale } from "../../../constants";
import { priceQuotesApi } from "../../../gateway/price-quotes/price-quotes";
import { getAuthorizationHeader } from "../../../amplify";
import { Button } from "picky-style";
import PhotographerTermsModal from "./photographer-terms-modal";
import UploadFileModal from "../../upload-file-modal";

interface PhotographerProfileSettingsProps {
  value?: PhotographerData;
  onChange: (photographer_data: PhotographerData) => void;
}

enum Modals {
  None,
  UploadBusinessLogo,
  PriceQuoteTerms
}

function PhotographerProfileSettings({
  value,
  onChange
}: PhotographerProfileSettingsProps) {
  const { t } = useTranslation();

  const [showModal, setShowModal] = useState(Modals.None);
  const [priceQuoteTerms, setPriceQuoteTerms] = useState(
    undefined as string | undefined
  );

  const modalHtml = (() => {
    switch (showModal) {
      case Modals.None:
        return undefined;
      case Modals.UploadBusinessLogo:
        return (
          <UploadFileModal
            title={t(
              "pages.profile-settings.photographer.modals.upload-business-logo.title"
            )}
            locale={uploadFileModalLocale(t)}
            onClose={() => setShowModal(Modals.None)}
            onApprove={() => {
              setShowModal(Modals.None);
            }}
            onUpload={(files) => {
              return new Promise((resolve) => {
                const fileList = Array.isArray(files) ? files : [files];
                const file = fileList[0];
                const reader = new FileReader();

                reader.onloadend = () => {
                  onChange({
                    ...value,
                    business_logo: reader.result as string
                  });
                };
                reader.readAsDataURL(file);
                return resolve(true);
              });
            }}
            maxCount={1}
          />
        );
      case Modals.PriceQuoteTerms:
        return (
          <PhotographerTermsModal
            value={priceQuoteTerms}
            initialData={priceQuoteTerms}
            onClose={() => setShowModal(Modals.None)}
            onApprove={async (text) => {
              const authorization = await getAuthorizationHeader();

              return priceQuotesApi.photographer
                .upsertPriceQuoteAdditionalData(authorization, {
                  terms: text
                })
                .then(() => {
                  setShowModal(Modals.None);
                  setPriceQuoteTerms(text);
                });
            }}
          />
        );
    }
  })();

  const businessHtml = (
    <>
      <h4
        style={{
          paddingInlineStart: "1svw",
          textAlign: "start",
          paddingTop: "2svh"
        }}
      >
        {t("pages.profile-settings.photographer.form.business-group.title")}
      </h4>
      <div style={{ padding: "1svw" }} className="rounded-accordion-body">
        <Row style={{ width: "auto", justifyContent: "center" }}>
          <Col style={{ minWidth: "80%" }}>
            <Row style={{ width: "auto", justifyContent: "center" }}>
              <Col xs={4} className="my-1 col-padding">
                <Form.Group className="align-right">
                  <Form.Label className="label-margin-right">
                    <b>
                      *
                      {t(
                        "pages.profile-settings.photographer.form.business-group.business-name"
                      )}
                    </b>
                  </Form.Label>
                  <Form.Control
                    required
                    type="text"
                    onChange={(e) =>
                      onChange({
                        ...value,
                        business_name: e.target.value
                      })
                    }
                    value={value?.business_name || ""}
                  />
                </Form.Group>
              </Col>
              <Col xs={4} className="my-1 col-padding">
                <Form.Group className="align-right">
                  <Form.Label className="label-margin-right">
                    <b>
                      *
                      {t(
                        "pages.profile-settings.photographer.form.business-group.business-id"
                      )}
                    </b>
                  </Form.Label>
                  <Form.Control
                    required
                    type="text"
                    onChange={(e) =>
                      onChange({
                        ...value,
                        business_id: e.target.value
                      })
                    }
                    value={value?.business_id || ""}
                  />
                </Form.Group>
              </Col>
              <Col xs={4} />
            </Row>
          </Col>
          <Col xs={"auto"}>
            <div>
              <img
                src={value?.business_logo || logoPlaceHolder}
                style={{
                  maxHeight: "60px",
                  maxWidth: "180px",
                  border: "solid",
                  borderRadius: "20px"
                }}
              />
              <br />
              <Button
                onClick={() => setShowModal(Modals.UploadBusinessLogo)}
                color="turquoise"
                $primary={false}
                text={t(
                  "pages.profile-settings.photographer.actions.upload-business-logo"
                )}
                size="small"
              />
            </div>
          </Col>
        </Row>
      </div>
    </>
  );

  const priceQuoteHtml = (
    <>
      <h4
        style={{
          paddingInlineStart: "1svw",
          textAlign: "start",
          paddingTop: "2svh"
        }}
      >
        {t("pages.profile-settings.photographer.form.price-quote-group.title")}
      </h4>

      <div style={{ textAlign: "start" }}>
        <Button
          text={t(
            "pages.profile-settings.photographer.actions.price-quotes-terms"
          )}
          onClick={async () => {
            if (!priceQuoteTerms) {
              const authorization = await getAuthorizationHeader();

              priceQuotesApi.photographer
                .getPriceQuoteAdditionalData(
                  undefined,
                  undefined,
                  authorization
                )
                .then((data) => {
                  setPriceQuoteTerms(data?.terms || "");
                  setShowModal(Modals.PriceQuoteTerms);
                });
            } else {
              setShowModal(Modals.PriceQuoteTerms);
            }
          }}
        />
      </div>
    </>
  );

  return (
    <>
      {modalHtml}
      {businessHtml}
      <br />
      {priceQuoteHtml}
    </>
  );
}

export default PhotographerProfileSettings;
