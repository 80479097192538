import { gateway } from "../../constants";
import {
  Api,
  GetCustomerPriceQuoteResponse,
  PhotographerPriceQuote,
  PhotographerPriceQuoteAdditionalData,
  PhotographerSharePriceQuoteResponse
} from "../../generated/swagger/price-quotes";
import { Base64RestResponse } from "../../utils/base64ToFile";

const api = new Api({ baseUrl: gateway.priceQuotes });

const upsertPriceQuote = (
  authorization: string,
  eventId: number,
  priceQuote: PhotographerPriceQuote
): Promise<PhotographerPriceQuote> => {
  return api.photographer
    .upsertPriceQuote(
      { "event-id": eventId },
      { "price-quote": priceQuote },
      {
        headers: { authorization }
      }
    )
    .then((response) => response.data["price-quote"] || priceQuote);
};

const getPhotographerPriceQuote = (
  authorization: string,
  eventId: number
): Promise<PhotographerPriceQuote | undefined> => {
  return api.photographer
    .getPriceQuote(
      { "event-id": eventId },
      {
        headers: { authorization }
      }
    )
    .then((response) => response.data["price-quote"])
    .catch((e) => {
      if (e instanceof Response) {
        if (e.status === 404) {
          return undefined;
        }
      }

      throw e;
    });
};

const deletePhotographerPriceQuote = (
  authorization: string,
  eventId: number
): Promise<void> => {
  return api.photographer
    .deletePriceQuote(
      { "event-id": eventId },
      {
        headers: { authorization }
      }
    )
    .then((response) => response.data);
};

const sendMailPhotographerPriceQuote = (
  authorization: string,
  eventId: number,
  emails: string[],
  subject: string
): Promise<void> => {
  return api.photographer
    .sendEmailPriceQuote(
      { "event-id": eventId },
      { subject, to: emails },
      {
        headers: { authorization }
      }
    )
    .then((response) => response.data);
};

const shareLinkPhotographerPriceQuote = (
  authorization: string,
  eventId: number
): Promise<PhotographerSharePriceQuoteResponse> => {
  return api.photographer
    .shareLinkPriceQuote(
      { "event-id": eventId },
      {
        headers: { authorization }
      }
    )
    .then((response) => response.data);
};

const getPhotographerPdf = (
  eventId: number,
  authorization: string
): Promise<Base64RestResponse> => {
  return api.photographer
    .photographerPriceQuotePdf(
      { "event-id": eventId },
      { headers: { authorization } }
    )
    .then((response) => response.blob())
    .then((blob) => blob.text())
    .then((data) => {
      return {
        data
      };
    });
};
const photographerUploadFileApprove = (
  eventId: number,
  authorization: string,
  file: number[],
  name: string
): Promise<void> => {
  const request = () => {
    return api.photographer.photographerUploadFileApprove(
      { "event-id": eventId },
      { file: file as any, name: name },
      { headers: { authorization } }
    );
  };
  return request().then((response) => response.data);
};

const customerUploadFileApprove = (
  eventId: number,
  otp: string | undefined,
  authorization: string | undefined,
  file: number[],
  name: string
): Promise<void> => {
  const request = () => {
    if (otp) {
      return api.customer.customerUploadFileApprove(
        { "event-id": eventId, otp },
        { file: file as any, name: name }
      );
    } else if (authorization) {
      return api.customer.customerUploadFileApprove(
        { "event-id": eventId },
        { file: file as any, name: name },
        { headers: { authorization } }
      );
    } else throw "otp or token is mandatory";
  };

  return request().then((response) => response.data);
};
const customerRemoveFileApprove = (
  eventId: number,
  otp: string | undefined,
  authorization: string | undefined,
  key: string
): Promise<void> => {
  const request = () => {
    if (otp) {
      return api.customer.customerRemoveFileApprove({
        "event-id": eventId,
        otp,
        md5: key
      });
    } else if (authorization) {
      return api.customer.customerRemoveFileApprove(
        { "event-id": eventId, md5: key },
        { headers: { authorization } }
      );
    } else throw "otp or token is mandatory";
  };

  return request().then((response) => response.data);
};

const photographerRemoveFileApprove = (
  eventId: number,
  authorization: string,
  key: string
): Promise<void> => {
  const request = () => {
    return api.photographer.photographerRemoveFileApprove(
      { "event-id": eventId, md5: key },
      { headers: { authorization } }
    );
  };
  return request().then((response) => response.data);
};

const manuallyApprovePriceQuote = (
  eventId: number,
  authorization: string
): Promise<void> => {
  const request = () => {
    return api.photographer.photographerApprovePriceQuote(
      { "event-id": eventId },
      { headers: { authorization } }
    );
  };

  return request()
    .then((response) => response.data)
    .catch((e) => {
      if (e instanceof Response) {
        if (e.status === 404) {
          return undefined;
        }
      }

      throw e;
    });
};

const getCustomerPriceQuote = (
  eventId: number,
  otp: string | undefined,
  authorization: string | undefined
): Promise<GetCustomerPriceQuoteResponse | undefined> => {
  const request = () => {
    if (otp) {
      return api.customer.getCustomerPriceQuote({ "event-id": eventId, otp });
    } else if (authorization) {
      return api.customer.getCustomerPriceQuote(
        { "event-id": eventId },
        { headers: { authorization } }
      );
    } else throw "otp or token is mandatory";
  };

  return request()
    .then((response) => response.data)
    .catch((e) => {
      if (e instanceof Response) {
        if (e.status === 404) {
          return undefined;
        }
      }

      throw e;
    });
};

const rejectPriceQuote = (
  eventId: number,
  otp: string | undefined,
  authorization: string | undefined,
  reason?: string
): Promise<void> => {
  const request = () => {
    if (otp) {
      return api.customer.rejectPriceQuote(
        { "event-id": eventId, otp },
        { reject: { reason } }
      );
    } else if (authorization) {
      return api.customer.rejectPriceQuote(
        { "event-id": eventId },
        { reject: { reason } },
        { headers: { authorization } }
      );
    } else throw "otp or token is mandatory";
  };

  return request()
    .then((response) => response.data)
    .catch((e) => {
      if (e instanceof Response) {
        if (e.status === 404) {
          return undefined;
        }
      }

      throw e;
    });
};

const approvePriceQuote = (
  eventId: number,
  otp: string | undefined,
  authorization: string | undefined
): Promise<void> => {
  const request = () => {
    if (otp) {
      return api.customer.customerApprovePriceQuote({
        "event-id": eventId,
        otp
      });
    } else if (authorization) {
      return api.customer.customerApprovePriceQuote(
        { "event-id": eventId },
        { headers: { authorization } }
      );
    } else throw "otp or token is mandatory";
  };

  return request()
    .then((response) => response.data)
    .catch((e) => {
      if (e instanceof Response) {
        if (e.status === 404) {
          return undefined;
        }
      }

      throw e;
    });
};

const getCustomerPdf = (
  eventId: number,
  otp: string | undefined,
  authorization: string | undefined
): Promise<Base64RestResponse> => {
  const request = () => {
    if (otp) {
      return api.customer.customerPriceQuotePdf({ "event-id": eventId, otp });
    } else if (authorization) {
      return api.customer.customerPriceQuotePdf(
        { "event-id": eventId },
        { headers: { authorization } }
      );
    } else throw "otp or token is mandatory";
  };

  return request()
    .then((response) => response.blob())
    .then((blob) => blob.text())
    .then((data) => {
      return {
        data
      };
    });
};

const upsertPriceQuoteAdditionalData = (
  authorization: string,
  priceQuoteAdditionalData: PhotographerPriceQuoteAdditionalData
): Promise<PhotographerPriceQuoteAdditionalData> => {
  return api.photographer
    .upsertPriceQuoteAdditionalData(
      { "price-quote-additional-data": priceQuoteAdditionalData },
      {
        headers: { authorization }
      }
    )
    .then(
      (response) =>
        response.data["price-quote-additional-data"] || priceQuoteAdditionalData
    )
    .catch((error) => {
      console.log(error);
      return priceQuoteAdditionalData;
    });
};

const getPriceQuoteAdditionalData = (
  eventId: number | undefined,
  otp: string | undefined,
  authorization: string | undefined
): Promise<PhotographerPriceQuoteAdditionalData | undefined> => {
  if (otp && eventId) {
    return api.photographer
      .getPriceQuoteAdditionalData({
        "event-id": eventId,
        otp
      })
      .then((response) => response.data["price-quote-additional-data"] || {})
      .catch((e) => {
        if (e instanceof Response) {
          if (e.status === 404) {
            return undefined;
          }
        }

        throw e;
      });
  } else if (authorization) {
    return api.photographer
      .getPriceQuoteAdditionalData({}, { headers: { authorization } })
      .then((response) => response.data["price-quote-additional-data"] || {})
      .catch((e) => {
        if (e instanceof Response) {
          if (e.status === 404) {
            return undefined;
          }
        }

        throw e;
      });
  } else throw "otp or token is mandatory";
};

export const priceQuotesApi = {
  photographer: {
    upsertPriceQuote,
    getPhotographerPriceQuote,
    deletePhotographerPriceQuote,
    sendMailPhotographerPriceQuote,
    getPhotographerPdf,
    manuallyApprovePriceQuote,
    upsertPriceQuoteAdditionalData,
    getPriceQuoteAdditionalData,
    shareLinkPhotographerPriceQuote,
    photographerUploadFileApprove,
    photographerRemoveFileApprove
  },
  customer: {
    getCustomerPriceQuote,
    rejectPriceQuote,
    approvePriceQuote,
    getCustomerPdf,
    customerUploadFileApprove,
    customerRemoveFileApprove
  }
};
