import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import FormDateInput from "../../form-date-input";

interface SmartTableRangeFilterProps {
  value: { from?: number; to?: number } | undefined;
  onValueChange: (value: { from?: number; to?: number } | undefined) => void;
  isDate: boolean;
}

function SmartTableRangeFilter({
  value,
  onValueChange,
  isDate
}: SmartTableRangeFilterProps) {
  const { t } = useTranslation();

  const from = value?.from;
  const to = value?.to;

  const getNewValue = (value: string) => {
    return value.length > 0 ? Number(value) / 1000 : undefined;
  };

  const getType = (value?: number) => {
    if (isDate) {
      if (value) return "date";
      else return "text";
    } else {
      return "number";
    }
  };

  const fromType = getType(from);
  const toType = getType(to);

  const renderDate = () => {
    return (
      <>
        <FormDateInput
          placeholder={t("components.smart-table.generic.from")}
          onChange={(newFrom) => {
            const newTo = newFrom && to && to < newFrom ? newFrom : to;

            const newState = {
              from: newFrom,
              to: newTo
            };

            onValueChange(newState);
          }}
          value={from}
        />
        <FormDateInput
          placeholder={t("components.smart-table.generic.to")}
          onChange={(newTo) => {
            const newFrom = newTo && from && newTo < from ? newTo : from;

            const newState = {
              from: newFrom,
              to: newTo
            };

            onValueChange(newState);
          }}
          value={to}
        />
      </>
    );
  };

  const renderNumber = () => {
    return (
      <>
        <Form.Control
          type={fromType}
          placeholder={t("components.smart-table.generic.from")}
          onChange={(e) => {
            const newState = {
              from: getNewValue(e.target.value),
              to
            };

            onValueChange(newState);
          }}
          onBlur={() => {
            const newTo = from && to && from > to ? from : to;

            if (newTo !== to) {
              const newState = {
                from,
                to: newTo
              };

              onValueChange(newState);
            }
          }}
          value={from || ""}
        />
        <Form.Control
          type={toType}
          placeholder={t("components.smart-table.generic.to")}
          onChange={(e) => {
            const newState = {
              from,
              to: getNewValue(e.target.value)
            };

            onValueChange(newState);
          }}
          onBlur={() => {
            const newFrom = from && to && to < from ? to : from;

            if (newFrom !== from) {
              const newState = {
                from: newFrom,
                to
              };

              onValueChange(newState);
            }
          }}
          value={to || ""}
        />
      </>
    );
  };

  return isDate ? renderDate() : renderNumber();
}

export default SmartTableRangeFilter;
