import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { AccountType } from "../../generated/swagger/auth";
import { useAuthHook } from "../../hooks/auth-hook";
import { accountSelector } from "../../store";
import {
  photographerEventsUrl,
  photographerPriceQuotesItemsTemplatesUrl,
  photographerPriceQuotesTemplatesUrl,
  profileSettingsUrl
} from "../app-body";
import eventsIcon from "../../resources/calendar-icon.png";
import EventsClickedIcon from "../../resources/calendar-clicked-icon.png";
import priceQuotesItemsIcon from "../../resources/price-quote-item-template-icon.png";
import priceQuotesItemsClickedIcon from "../../resources/price-quote-item-template-clicked-icon.png";
import priceQuotesTemplatesIcon from "../../resources/price-quote-template-icon.png";
import priceQuotesTemplatesClickedIcon from "../../resources/price-quote-template-clicked-icon.png";
import profileSettingsIcon from "../../resources/profile-settings-logo.png";
import profileSettingsClickedIcon from "../../resources/profile-settings-clicked-logo.png";

import NavIconLink from "./nav-icon-link";
import { Button } from "picky-style";

function BigSideNav() {
  const { t } = useTranslation();
  const account = useSelector(accountSelector);
  const { logout } = useAuthHook();

  if (!account) {
    return <></>;
  }

  const renderPhotographerMenu = () => {
    return (
      <div className="right-side-nav-menu">
        <div className="item">
          <div className="link">
            <NavIconLink
              isMobile={false}
              name={t("components.menu.photographer.events")}
              url={photographerEventsUrl}
              icon={eventsIcon}
              iconClicked={EventsClickedIcon}
            />
          </div>
        </div>
        <div className="item">
          <div className="link">
            <NavIconLink
              isMobile={false}
              name={t(
                "components.menu.photographer.price-quotes-items-templates"
              )}
              url={photographerPriceQuotesItemsTemplatesUrl}
              icon={priceQuotesItemsIcon}
              iconClicked={priceQuotesItemsClickedIcon}
            />
          </div>
        </div>
        <div className="item">
          <div className="link">
            <NavIconLink
              isMobile={false}
              name={t("components.menu.photographer.price-quotes-templates")}
              url={photographerPriceQuotesTemplatesUrl}
              icon={priceQuotesTemplatesIcon}
              iconClicked={priceQuotesTemplatesClickedIcon}
            />
          </div>
        </div>
        <div className="item">
          <div className="link">
            <NavIconLink
              isMobile={false}
              name={t("components.menu.photographer.profile-settings")}
              url={profileSettingsUrl}
              icon={profileSettingsIcon}
              iconClicked={profileSettingsClickedIcon}
            />
          </div>
        </div>
      </div>
    );
  };

  const renderCustomerMenu = () => {
    return <></>;
  };

  const render = () => {
    if (account.type === AccountType.Photographer) {
      return renderPhotographerMenu();
    } else {
      return renderCustomerMenu();
    }
  };

  return (
    <div className="right-menu right-side-nav">
      {render()}

      <div className="logout-button">
        <Button
          onClick={logout}
          text={t("components.menu.auth.logout")}
          $wide
        />
      </div>
    </div>
  );
}

export default BigSideNav;
