/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface GetPhotographerLogoBusinessResponse {
  url?: string;
}

export interface GetFileUrlResponse {
  path?: string;
  url?: string;
}

export interface UploadFileResponse {
  url?: string;
}

export interface UploadFileRequest {
  /** @format byte */
  byteArray: string;
  path: string;
}

export interface GetFilesRequest {
  paths: string[];
}

export interface UploadPhotographerBusinessLogoRequest {
  /** @format byte */
  business_logo: string;
}

export interface UploadPhotographerLogoBusinessResponse {
  url?: string;
}

export interface GetFilesResponse {
  urlsMap?: Record<string, string>;
  errors?: string[];
}

export interface RestError {
  error?: string;
  /** @format int32 */
  code?: number;
}

export type GetPhotographerBusinessLogoError = RestError;

export type UploadPhotographerBusinessLogoError = RestError;

export type UploadFileError = RestError;

export interface GetFileUrlParams {
  path: string;
}

export type GetFileUrlError = RestError;

export interface DeleteFileParams {
  path: string;
}

export type DeleteFileError = RestError;

export type GetFilesListError = RestError;

export type QueryParamsType = Record<string | number, any>;
export type ResponseFormat = keyof Omit<Body, "body" | "bodyUsed">;

export interface FullRequestParams extends Omit<RequestInit, "body"> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean;
  /** request path */
  path: string;
  /** content type of request body */
  type?: ContentType;
  /** query params */
  query?: QueryParamsType;
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseFormat;
  /** request body */
  body?: unknown;
  /** base url */
  baseUrl?: string;
  /** request cancellation token */
  cancelToken?: CancelToken;
}

export type RequestParams = Omit<FullRequestParams, "body" | "method" | "query" | "path">;

export interface ApiConfig<SecurityDataType = unknown> {
  baseUrl?: string;
  baseApiParams?: Omit<RequestParams, "baseUrl" | "cancelToken" | "signal">;
  securityWorker?: (securityData: SecurityDataType | null) => Promise<RequestParams | void> | RequestParams | void;
  customFetch?: typeof fetch;
}

export interface HttpResponse<D extends unknown, E extends unknown = unknown> extends Response {
  data: D;
  error: E;
}

type CancelToken = Symbol | string | number;

export enum ContentType {
  Json = "application/json",
  FormData = "multipart/form-data",
  UrlEncoded = "application/x-www-form-urlencoded",
  Text = "text/plain"
}

export class HttpClient<SecurityDataType = unknown> {
  public baseUrl: string = "";
  private securityData: SecurityDataType | null = null;
  private securityWorker?: ApiConfig<SecurityDataType>["securityWorker"];
  private abortControllers = new Map<CancelToken, AbortController>();
  private customFetch = (...fetchParams: Parameters<typeof fetch>) => fetch(...fetchParams);

  private baseApiParams: RequestParams = {
    credentials: "same-origin",
    headers: {},
    redirect: "follow",
    referrerPolicy: "no-referrer"
  };

  constructor(apiConfig: ApiConfig<SecurityDataType> = {}) {
    Object.assign(this, apiConfig);
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data;
  };

  protected encodeQueryParam(key: string, value: any) {
    const encodedKey = encodeURIComponent(key);
    return `${encodedKey}=${encodeURIComponent(typeof value === "number" ? value : `${value}`)}`;
  }

  protected addQueryParam(query: QueryParamsType, key: string) {
    return this.encodeQueryParam(key, query[key]);
  }

  protected addArrayQueryParam(query: QueryParamsType, key: string) {
    const value = query[key];
    return value.map((v: any) => this.encodeQueryParam(key, v)).join("&");
  }

  protected toQueryString(rawQuery?: QueryParamsType): string {
    const query = rawQuery || {};
    const keys = Object.keys(query).filter((key) => "undefined" !== typeof query[key]);
    return keys
      .map((key) => (Array.isArray(query[key]) ? this.addArrayQueryParam(query, key) : this.addQueryParam(query, key)))
      .join("&");
  }

  protected addQueryParams(rawQuery?: QueryParamsType): string {
    const queryString = this.toQueryString(rawQuery);
    return queryString ? `?${queryString}` : "";
  }

  private contentFormatters: Record<ContentType, (input: any) => any> = {
    [ContentType.Json]: (input: any) =>
      input !== null && (typeof input === "object" || typeof input === "string") ? JSON.stringify(input) : input,
    [ContentType.Text]: (input: any) => (input !== null && typeof input !== "string" ? JSON.stringify(input) : input),
    [ContentType.FormData]: (input: any) =>
      Object.keys(input || {}).reduce((formData, key) => {
        const property = input[key];
        formData.append(
          key,
          property instanceof Blob
            ? property
            : typeof property === "object" && property !== null
            ? JSON.stringify(property)
            : `${property}`
        );
        return formData;
      }, new FormData()),
    [ContentType.UrlEncoded]: (input: any) => this.toQueryString(input)
  };

  protected mergeRequestParams(params1: RequestParams, params2?: RequestParams): RequestParams {
    return {
      ...this.baseApiParams,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...(this.baseApiParams.headers || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {})
      }
    };
  }

  protected createAbortSignal = (cancelToken: CancelToken): AbortSignal | undefined => {
    if (this.abortControllers.has(cancelToken)) {
      const abortController = this.abortControllers.get(cancelToken);
      if (abortController) {
        return abortController.signal;
      }
      return void 0;
    }

    const abortController = new AbortController();
    this.abortControllers.set(cancelToken, abortController);
    return abortController.signal;
  };

  public abortRequest = (cancelToken: CancelToken) => {
    const abortController = this.abortControllers.get(cancelToken);

    if (abortController) {
      abortController.abort();
      this.abortControllers.delete(cancelToken);
    }
  };

  public request = async <T = any, E = any>({
    body,
    secure,
    path,
    type,
    query,
    format,
    baseUrl,
    cancelToken,
    ...params
  }: FullRequestParams): Promise<HttpResponse<T, E>> => {
    const secureParams =
      ((typeof secure === "boolean" ? secure : this.baseApiParams.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {};
    const requestParams = this.mergeRequestParams(params, secureParams);
    const queryString = query && this.toQueryString(query);
    const payloadFormatter = this.contentFormatters[type || ContentType.Json];
    const responseFormat = format || requestParams.format;

    return this.customFetch(`${baseUrl || this.baseUrl || ""}${path}${queryString ? `?${queryString}` : ""}`, {
      ...requestParams,
      headers: {
        ...(requestParams.headers || {}),
        ...(type && type !== ContentType.FormData ? { "Content-Type": type } : {})
      },
      signal: (cancelToken ? this.createAbortSignal(cancelToken) : requestParams.signal) || null,
      body: typeof body === "undefined" || body === null ? null : payloadFormatter(body)
    }).then(async (response) => {
      const r = response as HttpResponse<T, E>;
      r.data = null as unknown as T;
      r.error = null as unknown as E;

      const data = !responseFormat
        ? r
        : await response[responseFormat]()
            .then((data) => {
              if (r.ok) {
                r.data = data;
              } else {
                r.error = data;
              }
              return r;
            })
            .catch((e) => {
              r.error = e;
              return r;
            });

      if (cancelToken) {
        this.abortControllers.delete(cancelToken);
      }

      if (!response.ok) throw data;
      return data;
    });
  };
}

/**
 * @title picky media service
 * @version 1.0.0
 * @license Apache 2.0 (http://www.apache.org/licenses/LICENSE-2.0.html)
 *
 * picky media service
 */
export class Api<SecurityDataType extends unknown> extends HttpClient<SecurityDataType> {
  media = {
    /**
     * No description
     *
     * @tags photographer-media
     * @name GetPhotographerBusinessLogo
     * @summary get photographer business logo
     * @request GET:/media/photographer/business-logo
     */
    getPhotographerBusinessLogo: (params: RequestParams = {}) =>
      this.request<GetPhotographerLogoBusinessResponse, GetPhotographerBusinessLogoError>({
        path: `/media/photographer/business-logo`,
        method: "GET",
        type: ContentType.Json,
        format: "json",
        ...params
      }),

    /**
     * No description
     *
     * @tags photographer-media
     * @name UploadPhotographerBusinessLogo
     * @summary upload photographer business logo
     * @request POST:/media/photographer/business-logo
     */
    uploadPhotographerBusinessLogo: (
      UploadPhotographerBusinessLogoRequest: UploadPhotographerBusinessLogoRequest,
      params: RequestParams = {}
    ) =>
      this.request<UploadPhotographerLogoBusinessResponse, UploadPhotographerBusinessLogoError>({
        path: `/media/photographer/business-logo`,
        method: "POST",
        body: UploadPhotographerBusinessLogoRequest,
        type: ContentType.Json,
        format: "json",
        ...params
      })
  };
  backoffice = {
    /**
     * No description
     *
     * @tags backoffice-media
     * @name UploadFile
     * @summary upload file
     * @request POST:/backoffice/media
     */
    uploadFile: (UploadFileRequest: UploadFileRequest, params: RequestParams = {}) =>
      this.request<UploadFileResponse, UploadFileError>({
        path: `/backoffice/media`,
        method: "POST",
        body: UploadFileRequest,
        type: ContentType.Json,
        format: "json",
        ...params
      }),

    /**
     * No description
     *
     * @tags backoffice-media
     * @name GetFileUrl
     * @summary get file url
     * @request GET:/backoffice/media
     */
    getFileUrl: (query: GetFileUrlParams, params: RequestParams = {}) =>
      this.request<GetFileUrlResponse, GetFileUrlError>({
        path: `/backoffice/media`,
        method: "GET",
        query: query,
        type: ContentType.Json,
        format: "json",
        ...params
      }),

    /**
     * No description
     *
     * @tags backoffice-media
     * @name DeleteFile
     * @summary delete file
     * @request DELETE:/backoffice/media
     */
    deleteFile: (query: DeleteFileParams, params: RequestParams = {}) =>
      this.request<void, DeleteFileError>({
        path: `/backoffice/media`,
        method: "DELETE",
        query: query,
        type: ContentType.Json,
        ...params
      }),

    /**
     * No description
     *
     * @tags backoffice-media
     * @name GetFilesList
     * @summary get files list
     * @request POST:/backoffice/media/list
     */
    getFilesList: (GetFilesRequest: GetFilesRequest, params: RequestParams = {}) =>
      this.request<GetFilesResponse, GetFilesListError>({
        path: `/backoffice/media/list`,
        method: "POST",
        body: GetFilesRequest,
        type: ContentType.Json,
        format: "json",
        ...params
      })
  };
}
