import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import {
  ItemType,
  LineItem
} from "../../../../../../generated/swagger/price-quotes";
import Select from "react-select";
import CurrencyInput from "react-currency-input-field";
import deleteRowIcon from "../../../../../../resources/delete-row-icon.png";

interface PhotographerLineItemTemplateProps {
  isRequired: boolean;
  value: LineItem;
  itemTypesOptions: { label: string; value: ItemType }[];
  focusedIndex?: number;
  onChange: (lineItem: LineItem, fieldIndex: number) => void;
  onDelete?: () => void;
}

function PhotographerLineItemTemplate({
  isRequired,
  value,
  itemTypesOptions,
  focusedIndex,
  onChange,
  onDelete
}: PhotographerLineItemTemplateProps) {
  const { t } = useTranslation();

  return (
    <tr>
      <td>
        {onDelete && (
          <img
            src={deleteRowIcon}
            width="21px"
            height="21px"
            className="clickable"
            onClick={onDelete}
          />
        )}
      </td>
      <td>
        <Form.Control
          required={isRequired}
          type="text"
          placeholder={t(
            "components.photographer.price-quotes-form.generic.line-items.name-placeholder"
          )}
          onChange={(e) => {
            const newLineItem = {
              ...value,
              name: e.target.value,
              price: value.price || 0,
              quantity: value.quantity || 1
            };

            onChange(newLineItem, 0);
          }}
          value={value.name || ""}
          autoFocus={focusedIndex === 0}
        />
      </td>
      <td>
        <Select
          required={isRequired}
          name="item-type-select"
          options={itemTypesOptions}
          placeholder={t(
            "components.photographer.price-quotes-form.generic.line-items.type-placeholder"
          )}
          onChange={(e) => {
            const newLineItem = {
              ...value,
              "item-type": e?.value as ItemType | undefined
            };

            onChange(newLineItem, 1);
          }}
          value={itemTypesOptions.filter(
            (option) => option.value === value["item-type"]
          )}
          noOptionsMessage={() => {
            return <>{t("generic.empty-results")}</>;
          }}
          autoFocus={focusedIndex === 1}
        />
      </td>
      <td>
        <Form.Control
          required={isRequired}
          type="number"
          placeholder={t(
            "components.photographer.price-quotes-form.generic.line-items.quantity-placeholder"
          )}
          min={1}
          onChange={(e) => {
            const newLineItem = {
              ...value,
              quantity: Number(Number(e.target.value).toFixed(2))
            };

            onChange(newLineItem, 2);
          }}
          value={value.quantity || 1}
          autoFocus={focusedIndex === 2}
        />
      </td>
      <td>
        <CurrencyInput
          required={isRequired}
          className="form-control"
          suffix={t("currencies.ils")}
          decimalsLimit={2}
          step={0.1}
          placeholder={t(
            "components.photographer.price-quotes-form.generic.line-items.price-placeholder"
          )}
          onValueChange={(price) => {
            const newLineItem = {
              ...value,
              price: Number(price)
            };

            onChange(newLineItem, 3);
          }}
          value={value.price || 0}
          autoFocus={focusedIndex === 3}
        />
      </td>
      <td>
        {((value.price !== undefined &&
          value.quantity !== undefined &&
          Number((value.price * value.quantity).toFixed(2))) ||
          0) + t("currencies.ils")}
      </td>
    </tr>
  );
}

export default PhotographerLineItemTemplate;
