import { useTranslation } from "react-i18next";
import {
  Event,
  PhotographerPriceQuote
} from "../../../../../generated/swagger/price-quotes";
import { Col, Modal, Row } from "react-bootstrap";
import PriceTable from "../../../../price-table";
import Moment from "react-moment";
import { photographerPriceQuoteEditorUrl } from "../../../../app-body";
import { useNavigate } from "react-router-dom";
import { Button } from "picky-style";

interface PriceQuoteModalProps {
  priceQuote: PhotographerPriceQuote;
  event: Event;
  close: () => void;
}

function PriceQuoteModal({ priceQuote, event, close }: PriceQuoteModalProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const status = () => {
    if (priceQuote?.["customer-response"]?.approved?.finalized) {
      return t("pages.events.price-quote.status.confirmed");
    } else if (priceQuote?.["customer-response"]?.rejected?.reason) {
      return t("pages.events.price-quote.status.rejected.title");
    }
    return t("pages.events.price-quote.status.waiting");
  };
  const totalPrice = priceQuote["total-price"] ?? 0;

  return (
    <Modal show size="lg">
      <Modal.Header>
        <Modal.Title className="modal-title">
          {t("components.photographer.price-quote-modal.title", {
            eventName: event.name
          })}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row style={{ textAlign: "start", marginTop: "1svh" }}>
          <h5 style={{ marginInlineStart: "1svw" }}>
            {t("components.photographer.price-quote-modal.doc-title")}
          </h5>
        </Row>
        <Row>
          {event.name && event.location && (
            <Col style={{ textAlign: "start", marginInlineStart: "1svw" }}>
              {t("pages.price-quote-customer.generic.price-quote-description")}
              {t("pages.events.enums.type." + event.event_type)}
              {t("pages.price-quote-customer.generic.for")
                .replace("{event name}", event.name)
                .replace("{event location}", event.location)}
            </Col>
          )}
          <Col style={{ textAlign: "end" }}>
            {t("pages.price-quote-customer.generic.expiration")}
            <Moment format="DD/MM/YYYY">
              {new Date((priceQuote?.["expiration-date"] || 0) * 1000)}
            </Moment>
          </Col>
        </Row>
        <PriceTable
          header={t("components.photographer.price-quote-modal.items-title")}
          maxHeight="250px"
          theadClassName="sticky-header"
          lineItems={priceQuote["line-items"] || []}
        />
        <br />
        <Row
          className="flex-space-between"
          style={{
            marginInlineStart: "auto",
            marginInlineEnd: "1.3svw",
            backgroundColor: "#2CBFC9",
            borderRadius: "20px",
            color: "white",
            height: "50px",
            width: "200px"
          }}
        >
          <Col style={{ alignSelf: "center", textAlign: "start" }}>
            <b>{t("pages.price-quote-customer.generic.total-price")}</b>
          </Col>

          <Col style={{ alignSelf: "center", textAlign: "end" }}>
            {totalPrice.toFixed(2) + t("currencies.ils")}
          </Col>
        </Row>

        <Row
          style={{ textAlign: "start", marginTop: "20px", alignItems: "end" }}
        >
          <Col>
            <h5 style={{ marginInlineStart: "30px" }}>
              {t("components.photographer.price-quote-modal.status", {
                status: status()
              })}
            </h5>
            {priceQuote?.["customer-response"]?.rejected?.reason && (
              <b style={{ marginInlineStart: "30px" }}>
                {t("pages.events.price-quote.status.rejected.reason", {
                  reason: priceQuote?.["customer-response"]?.rejected?.reason
                })}
              </b>
            )}
          </Col>
          <div style={{ marginInlineStart: "30px" }}>
            {t("components.photographer.price-quote-modal.produce-date")}
            <Moment format="DD/MM/YYYY">
              {new Date(priceQuote?.["update-time"] || 0)}
            </Moment>
          </div>
          <Col></Col>
          <Col
            style={{
              display: "flex",
              justifyContent: "end",
              marginInlineEnd: "30px"
            }}
          ></Col>
        </Row>
      </Modal.Body>
      <Modal.Footer style={{ margin: "auto" }}>
        <div
          style={{
            display: "flex",
            flexWrap: "wrap-reverse",
            justifyContent: "center"
          }}
        >
          <Button
            onClick={close}
            color="grey"
            text={t("components.photographer.price-quote-modal.actions.close")}
            $loadable={false}
          />

          <Button
            onClick={() => {
              event.id &&
                navigate(
                  photographerPriceQuoteEditorUrl.replace(
                    ":eventId",
                    event.id.toString()
                  )
                );
            }}
            text={t(
              "components.photographer.price-quote-modal.actions.continue"
            )}
            $loadable={false}
          />
        </div>
      </Modal.Footer>
    </Modal>
  );
}

export default PriceQuoteModal;
