import { Route, Routes } from "react-router-dom";
import AppBody from "./app-body";
import AppHeader from "./app-header";
import CustomerPriceQuotesView from "./pages/customer/customer-price-quotes-view";
import { useMemo } from "react";

export const customerPriceQuotesViewUrl =
  "/customer/price-quotes/:eventId/view";

function AppRouter() {
  const nonHeaderRoutes = () => {
    return [
      <Route
        key={"customer-price-quotes-view-url-route"}
        path={customerPriceQuotesViewUrl}
        element={<CustomerPriceQuotesView />}
      />
    ];
  };

  const routes = useMemo(() => {
    return (
      <Routes>
        {nonHeaderRoutes()}
        <Route
          path="*"
          element={
            <>
              <AppHeader />
              <AppBody />
            </>
          }
        />
      </Routes>
    );
  }, []);

  return routes;
}

export default AppRouter;
