import { useEffect, useState } from "react";
import { Col, Form, Modal, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import Moment from "react-moment";
import { getAuthorizationHeader } from "../../../../../amplify";
import { priceQuotesTemplateApi } from "../../../../../gateway/price-quotes/price-quotes-templates";
import {
  PhotographerPriceQuoteTemplate,
  PriceQuoteTemplateSummary
} from "../../../../../generated/swagger/price-quotes";
import filter from "../../../../smart-table/resources/filter.png";
import SmartTable, {
  SmartTableColumn
} from "../../../../smart-table/smart-table";
import {
  smartTableNumberRangeFilter,
  smartTableStringContainsFilter
} from "../../../../smart-table/smart-table-filter";
import {
  smartTableNumberSorter,
  smartTableStringSorter
} from "../../../../smart-table/smart-table-sort";
import ValidateModal from "../../../../validate-modal/validate-modal";
import { Button } from "picky-style";

interface PhotographerPriceQuotesTemplatePickerModalProps {
  show: boolean;
  onItemPick: (item: PhotographerPriceQuoteTemplate) => void;
  onAbort: () => void;
}

function PhotographerPriceQuotesTemplatePickerModal({
  show,
  onItemPick,
  onAbort
}: PhotographerPriceQuotesTemplatePickerModalProps) {
  const { t } = useTranslation();

  const [itemPicker, setItemPicker] = useState({
    data: [] as PriceQuoteTemplateSummary[],
    search: "",
    showFilterModal: false,
    selected: undefined as PriceQuoteTemplateSummary | undefined
  });

  const getElements = async (): Promise<PriceQuoteTemplateSummary[]> => {
    const authorization = await getAuthorizationHeader();
    const photographerPriceQuotesTemplates =
      //we don't expect pagination on early point, we will handle that later in the future
      await priceQuotesTemplateApi.photographer.getPhotographerPriceQuotesTemplates(
        authorization,
        0,
        1000
      );

    return photographerPriceQuotesTemplates;
  };

  useEffect(() => {
    getElements().then((priceQuoteTemplatesSummaries) => {
      setItemPicker((curr) => {
        return {
          ...curr,
          data: priceQuoteTemplatesSummaries,
          selected: undefined
        };
      });
    });
  }, []);

  const columns: SmartTableColumn<PriceQuoteTemplateSummary>[] = [
    {
      name: t("pages.price-quotes-templates.generic.name"),
      headerStyle: { textAlign: "start" },
      dataStyle: { textAlign: "start" },
      dataMapper: (priceQuoteTemplateSummary) => {
        return (
          <div
            onClick={() =>
              setItemPicker((curr) => {
                return { ...curr, selected: priceQuoteTemplateSummary };
              })
            }
            style={{ display: "flex", gap: "5px", alignItems: "center" }}
          >
            <input
              type="radio"
              onChange={(e) =>
                e.target.checked &&
                setItemPicker((curr) => {
                  return { ...curr, selected: priceQuoteTemplateSummary };
                })
              }
              checked={itemPicker.selected === priceQuoteTemplateSummary}
            />
            <label>{priceQuoteTemplateSummary.name}</label>
          </div>
        );
      },
      filterBy: smartTableStringContainsFilter(
        (data: PriceQuoteTemplateSummary) => data.name
      ),
      sortBy: smartTableStringSorter(
        (data: PriceQuoteTemplateSummary) => data.name
      )
    },
    {
      name: t("pages.price-quotes-templates.generic.last-update-time"),
      dataMapper: (template) => {
        return (
          <Moment format="DD/MM/YYYY">
            {new Date(template["last-update"] || 0)}
          </Moment>
        );
      },
      filterBy: smartTableNumberRangeFilter(
        (data: PriceQuoteTemplateSummary) => data["last-update"],
        true
      ),
      sortBy: smartTableNumberSorter(
        (data: PriceQuoteTemplateSummary) => data["last-update"]
      )
    },
    {
      name: t("pages.price-quotes-templates.generic.number-of-items"),
      dataMapper: (template) => {
        return template["items-count"];
      },
      filterBy: smartTableNumberRangeFilter(
        (data: PriceQuoteTemplateSummary) => data["items-count"]
      ),
      sortBy: smartTableNumberSorter(
        (data: PriceQuoteTemplateSummary) => data["items-count"]
      )
    },
    {
      name: t("pages.price-quotes-templates.generic.total-price"),
      dataMapper: (template) => {
        return t("currencies.ils") + template["total-price"]?.toFixed(2);
      },
      filterBy: smartTableNumberRangeFilter(
        (data: PriceQuoteTemplateSummary) => data["total-price"]
      ),
      sortBy: smartTableNumberSorter(
        (data: PriceQuoteTemplateSummary) => data["total-price"]
      )
    }
  ];

  const filteredData =
    itemPicker.search.length > 0
      ? itemPicker.data.filter((curr) => {
          const searchToLower = itemPicker.search.toLocaleLowerCase();

          return (
            curr.name?.toLocaleLowerCase()?.includes(searchToLower) || false
          );
        })
      : itemPicker.data;

  const isLoadDisabled = !itemPicker.selected || !itemPicker.selected.name;

  return (
    <Modal show={show} size="lg">
      <Modal.Header>
        <Modal.Title className="modal-title">
          {t(
            "components.photographer.price-quotes-template-picker-modal.title"
          )}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row className="align-items-center">
          <Col>
            <Form.Control
              type="search"
              className="search"
              placeholder={t(
                "components.photographer.price-quotes-template-picker-modal.search-placeholder"
              )}
              onChange={(e) =>
                setItemPicker((curr) => {
                  return { ...curr, search: e.target.value };
                })
              }
              value={itemPicker.search}
            />
          </Col>
          <Col xs="auto">
            <Button
              onClick={() =>
                setItemPicker((curr) => {
                  return {
                    ...curr,
                    showFilterModal: true
                  };
                })
              }
              text={
                <>
                  <img src={filter} className="table-page-filter-icon" />
                  {t("components.smart-table.actions.filter")}
                </>
              }
              $loadable={false}
              size="tiny"
              color="turquoise"
            />
          </Col>
        </Row>
        <br />
        <Row>
          <h5>
            <b>
              {t(
                "components.photographer.price-quotes-template-picker-modal.templates-title"
              )}
            </b>
          </h5>
        </Row>
        <div className="table-wrap">
          <SmartTable
            keyId="template-picker-modal-items"
            className="modal-table"
            data={filteredData}
            columns={columns}
            customFilter={{
              showSmartTableFilter: false,
              onFilterClose: () => {
                setItemPicker((curr) => {
                  return {
                    ...curr,
                    showFilterModal: false
                  };
                });
              },
              showFilterModal: itemPicker.showFilterModal
            }}
          />
        </div>
        <br />
        <div className="center">
          <Button
            text={t(
              "components.photographer.price-quotes-template-picker-modal.actions.cancel"
            )}
            onClick={onAbort}
            color="grey"
            size="small"
          />

          <ValidateModal
            title={t(
              "components.photographer.price-quotes-template-picker-modal.confirmation.title"
            )}
            confirmationMessage={t(
              "components.photographer.price-quotes-template-picker-modal.confirmation.message"
            )}
            locale={{
              cancel: t("generic.actions.cancel"),
              confirm: t("generic.actions.confirm")
            }}
            disabled={isLoadDisabled}
            text={t(
              "components.photographer.price-quotes-template-picker-modal.actions.load"
            )}
            size="small"
            onApprove={async () => {
              if (itemPicker.selected && itemPicker.selected.name) {
                const authorization = await getAuthorizationHeader();
                const priceQuoteTemplate =
                  await priceQuotesTemplateApi.photographer.getPhotographerPriceQuotesTemplate(
                    authorization,
                    itemPicker.selected.name
                  );

                if (priceQuoteTemplate) {
                  onItemPick(priceQuoteTemplate);

                  setItemPicker((curr) => {
                    return {
                      ...curr,
                      selected: undefined
                    };
                  });
                }
              } else {
                onAbort();
              }
            }}
          />
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default PhotographerPriceQuotesTemplatePickerModal;
