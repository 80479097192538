import { useState } from "react";
import { Col, Form, Modal, Row } from "react-bootstrap";
import {
  ItemType,
  LineItem
} from "../../../../../../generated/swagger/price-quotes";
import { itemTypes } from "../../../../../../data/price-quotes-constants";
import Select from "react-select";
import { useTranslation } from "react-i18next";
import CurrencyInput from "react-currency-input-field";
import { Button } from "picky-style";

interface PhotographerPriceQuoteItemModalProps {
  initialData: LineItem;
  onChange: (priceQuoteItem?: LineItem) => void;
}

function PhotographerPriceQuoteItemModal({
  initialData,
  onChange
}: PhotographerPriceQuoteItemModalProps) {
  const { t } = useTranslation();

  const [lineItem, setLineItem] = useState({ ...initialData });

  const action = initialData.name ? "edit" : "create";

  const getTitleName = (): string => {
    return action === "create"
      ? t("pages.price-quotes-items-templates.editor.new")
      : t("pages.price-quotes-items-templates.editor.edit");
  };

  const itemTypesOptions = itemTypes.map((itemType) => {
    return {
      label: t(`pages.price-quotes-items-templates.enums.type.${itemType}`),
      value: itemType
    };
  });

  const handleSubmit = (submitEvent: React.FormEvent) => {
    submitEvent.preventDefault();
    submitEvent.stopPropagation();
    onChange(lineItem);
  };

  return (
    <Modal show>
      <Modal.Header>
        <Modal.Title className="modal-title">{getTitleName()}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Row className="align-items-center">
            <Col xs={2} className="my-1 col-padding" style={{ flex: "1" }}>
              <Form.Control
                required
                type="text"
                placeholder={t(
                  "pages.price-quotes-items-templates.generic.name"
                )}
                onChange={(e) =>
                  setLineItem((curr) => {
                    return {
                      ...curr,
                      name: e.target.value
                    };
                  })
                }
                value={lineItem.name || ""}
              />
            </Col>
          </Row>
          <Row className="align-items-center">
            <Col xs={2} className="my-1 col-padding" style={{ flex: "2" }}>
              <Select
                required
                name="item-type-select"
                options={itemTypesOptions}
                placeholder={t(
                  "pages.price-quotes-items-templates.generic.item-type"
                )}
                onChange={(e) => {
                  setLineItem((curr) => {
                    return {
                      ...curr,
                      "item-type": e?.value as ItemType | undefined
                    };
                  });
                }}
                value={itemTypesOptions.filter(
                  (option) => option.value === lineItem["item-type"]
                )}
                noOptionsMessage={() => {
                  return <>{t("generic.empty-results")}</>;
                }}
              />
            </Col>
            <Col xs={2} className="my-1 col-padding" style={{ flex: "1" }}>
              <CurrencyInput
                required
                className="form-control"
                decimalsLimit={0}
                step={1}
                placeholder={t(
                  "pages.price-quotes-items-templates.generic.quantity"
                )}
                onValueChange={(value) =>
                  setLineItem((curr) => {
                    return {
                      ...curr,
                      quantity: Number(value)
                    };
                  })
                }
                defaultValue={lineItem.quantity || ""}
              />
            </Col>
            <Col xs={2} className="my-1 col-padding" style={{ flex: "1" }}>
              <CurrencyInput
                required
                className="form-control"
                suffix={t("currencies.ils")}
                decimalsLimit={2}
                step={0.1}
                placeholder={t(
                  "pages.price-quotes-items-templates.generic.price"
                )}
                onValueChange={(value) =>
                  setLineItem((curr) => {
                    return {
                      ...curr,
                      price: Number(value)
                    };
                  })
                }
                defaultValue={lineItem.price || ""}
              />
            </Col>
          </Row>
          <div className="center">
            <Button
              onClick={() => onChange(undefined)}
              text={t(
                "pages.price-quotes-items-templates.editor.actions.cancel"
              )}
              color="grey"
            />
            <Button
              text={t("pages.price-quotes-items-templates.editor.actions.save")}
              $isSubmit
            />
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
}

export default PhotographerPriceQuoteItemModal;
