export interface Sort {
  columnIndex: number;
  direction: SortDirection;
}

export enum SortDirection {
  None,
  ASC,
  DESC
}

export const nextSortDirection = (sortDirection: SortDirection) => {
  switch (sortDirection) {
    case SortDirection.None:
      return SortDirection.ASC;
    case SortDirection.ASC:
      return SortDirection.DESC;
    case SortDirection.DESC:
      return SortDirection.None;
  }
};

export const smartTableStringSorter = <T>(
  converter: (data: T) => string | undefined
): ((a: T, b: T) => number) => {
  const sortBy = (a: T, b: T): number => {
    const valueA = converter(a) || "";
    const valueB = converter(b) || "";

    if (valueA < valueB) return -1;
    if (valueA > valueB) return 1;

    return 0;
  };

  return sortBy;
};

export const smartTableNumberSorter = <T>(
  converter: (data: T) => number | undefined
): ((a: T, b: T) => number) => {
  const sortBy = (a: T, b: T): number => {
    const valueA = converter(a) || 0;
    const valueB = converter(b) || 0;

    if (valueA < valueB) return -1;
    if (valueA > valueB) return 1;

    return 0;
  };

  return sortBy;
};
