import { useState } from "react";
import { Modal } from "react-bootstrap";
import { getAuthorizationHeader } from "../../../../../../amplify";
import { useTranslation } from "react-i18next";
import { ReactMultiEmail } from "react-multi-email";
import "react-multi-email/dist/style.css";
import { priceQuotesApi } from "../../../../../../gateway/price-quotes/price-quotes";
import { Button } from "picky-style";

interface PhotographerPriceQuoteMailModalProps {
  eventId: number;
  mail?: string;
  show: boolean;
  closeModal: () => void;
}

function PhotographerPriceQuoteMailModal({
  eventId,
  mail,
  show,
  closeModal
}: PhotographerPriceQuoteMailModalProps) {
  const { t } = useTranslation();

  const [emails, setEmails] = useState<string[]>(mail ? [mail] : []);
  const [errorMessage, setErrorMessage] = useState(
    undefined as string | undefined
  );

  return (
    <Modal show={show}>
      <Modal.Header>
        <Modal.Title className="modal-title">
          {t("components.photographer.price-quote-mail-modal.generic.title")}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <ReactMultiEmail
            placeholder={t(
              "components.photographer.price-quote-mail-modal.generic.description"
            )}
            emails={emails}
            onChange={(emails: string[]) => {
              setEmails(emails);
            }}
            autoFocus={true}
            getLabel={(email, index, removeEmail) => {
              return (
                <div data-tag key={index}>
                  <div data-tag-item>{email}</div>
                  <span data-tag-handle onClick={() => removeEmail(index)}>
                    ×
                  </span>
                </div>
              );
            }}
          />
          <div className="center">
            <Button
              text={t(
                "components.photographer.price-quote-mail-modal.actions.cancel"
              )}
              onClick={(e) => closeModal()}
              color="grey"
            />
            <Button
              text={t(
                "components.photographer.price-quote-mail-modal.actions.send"
              )}
              disabled={emails.length === 0}
              onClick={async () => {
                const authorization = await getAuthorizationHeader();

                try {
                  await priceQuotesApi.photographer.sendMailPhotographerPriceQuote(
                    authorization,
                    eventId,
                    emails,
                    t("pages.price-quote-customer.generic.title")
                  );

                  closeModal();
                  setErrorMessage(undefined);
                } catch (e) {
                  setErrorMessage(t("errors.generic"));
                }
              }}
            />
          </div>
          {errorMessage && <p style={{ color: "red" }}>{errorMessage}</p>}
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default PhotographerPriceQuoteMailModal;
