import {
  Api,
  CreatePhotographerEventRequest,
  PhotographerEventSummary,
  UpdatePhotographerEventRequest
} from "../generated/swagger/events";
import { gateway } from "../constants";

const api = new Api({ baseUrl: gateway.events });

const getPhotographerEvents = (
  authorization: string,
  pageNumber: number,
  pageSize: number
): Promise<PhotographerEventSummary[]> => {
  return api.photographer
    .getPhotographerEvents(
      {
        "page-number": pageNumber,
        "page-size": pageSize
      },
      { headers: { authorization } }
    )
    .then((response) => response.data.events || []);
};

const getPhotographerEvent = (
  authorization: string,
  id: number
): Promise<PhotographerEventSummary | undefined> => {
  return api.photographer
    .getPhotographerEvent(
      { event_id: id },
      {
        headers: { authorization }
      }
    )
    .then((response) => response.data.event);
};

const createPhotographerEvent = (
  authorization: string,
  request: CreatePhotographerEventRequest
): Promise<PhotographerEventSummary> => {
  return api.photographer
    .createPhotographerEvent(request, { headers: { authorization } })
    .then((response) => response.data.event || {});
};

const updatePhotographerEvent = (
  authorization: string,
  id: number,
  request: UpdatePhotographerEventRequest
): Promise<PhotographerEventSummary> => {
  return api.photographer
    .updatePhotographerEvent({ event_id: id }, request, {
      headers: { authorization }
    })
    .then((response) => response.data.event || {});
};

const deletePhotographerEvent = (
  authorization: string,
  id: number
): Promise<void> => {
  return api.photographer
    .deletePhotographerEvent(
      { event_id: id },
      {
        headers: { authorization }
      }
    )
    .then((response) => response.data);
};

const archivePhotographerEvent = (
  authorization: string,
  id: number
): Promise<PhotographerEventSummary> => {
  return api.photographer
    .archivePhotographerEvent(
      { event_id: id },
      {
        headers: { authorization }
      }
    )
    .then((response) => response.data.event || {});
};

export const eventsApi = {
  photographer: {
    getPhotographerEvents,
    getPhotographerEvent,
    createPhotographerEvent,
    updatePhotographerEvent,
    deletePhotographerEvent,
    archivePhotographerEvent
  }
};
