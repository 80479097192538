import BigNav from "./navbar/big-nav";
import SmallNav from "./navbar/small-nav";
import WidthResponsive from "./width-responsive";

function AppHeader() {
  return (
    <div className="nav-bar">
      <WidthResponsive
        minWidth={850}
        bigComponent={<BigNav />}
        smallComponent={<SmallNav />}
      />
    </div>
  );
}

export default AppHeader;
