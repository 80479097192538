import SmartTableChoiceFilter from "./components/smart-table-choice-filter";
import SmartTableRangeFilter from "./components/smart-table-range-filter";
import SmartTableTextFilter from "./components/smart-table-text-filter";

export interface SmartTableFilter<T> {
  render: (
    filterData: any,
    setFilterData: (filterData: unknown) => void
  ) => JSX.Element;
  filter: (data: T, filterData: any) => boolean;
}

export const smartTableStringFilter = <T,>(
  converter: (data: T) => string | undefined,
  matcher: (filterData: string, data?: string) => boolean | undefined,
  isCaseSensitive?: boolean
): SmartTableFilter<T> => {
  return {
    filter: (data: T, filterData?: string) => {
      const parseCase = (value?: string): string | undefined => {
        if (isCaseSensitive) return value;
        return value?.toLocaleLowerCase();
      };

      const parsedFilterData = parseCase(filterData);

      return (
        !parsedFilterData ||
        matcher(parsedFilterData, parseCase(converter(data))) ||
        false
      );
    },
    render: (
      filterData: string | undefined,
      setFilterData: (filterData: string | undefined) => void
    ) => {
      return (
        <SmartTableTextFilter
          value={filterData}
          onValueChange={setFilterData}
        />
      );
    }
  };
};

export const smartTableStringStartsWithFilter = <T,>(
  converter: (data: T) => string | undefined,
  isCaseSensitive?: boolean
): SmartTableFilter<T> => {
  const matcher = (filterData: string, data?: string) =>
    data?.startsWith(filterData);

  return smartTableStringFilter(converter, matcher, isCaseSensitive);
};

export const smartTableStringContainsFilter = <T,>(
  converter: (data: T) => string | undefined,
  isCaseSensitive?: boolean
): SmartTableFilter<T> => {
  const matcher = (filterData: string, data?: string) =>
    data?.includes(filterData);

  return smartTableStringFilter(converter, matcher, isCaseSensitive);
};

export const smartTableNumberRangeFilter = <T,>(
  converter: (data: T) => number | undefined,
  isDate?: boolean
): SmartTableFilter<T> => {
  return {
    filter: (data: T, filterData?: { from?: number; to?: number }) => {
      const convertedData = converter(data);

      return (
        !filterData ||
        (!filterData.from && !filterData.to) ||
        (convertedData &&
          (!filterData.from || convertedData >= filterData.from) &&
          (!filterData.to || convertedData <= filterData.to)) ||
        false
      );
    },
    render: (
      filterData: { from?: number; to?: number } | undefined,
      setFilterData: (
        filterData: { from?: number; to?: number } | undefined
      ) => void
    ) => {
      return (
        <SmartTableRangeFilter
          value={filterData}
          onValueChange={setFilterData}
          isDate={!!isDate}
        />
      );
    }
  };
};

export const smartTableChoiceFilter = <T,>(
  converter: (data: T) => string | undefined,
  choices: { label: string; value: string }[],
  isMultiChoice?: boolean
): SmartTableFilter<T> => {
  return {
    filter: (data: T, filterData?: string[]) => {
      const convertedData = converter(data);

      return (
        !filterData ||
        (convertedData && filterData.includes(convertedData)) ||
        false
      );
    },
    render: (
      filterData: string[] | undefined,
      setFilterData: (filterData?: string[] | undefined) => void
    ) => {
      return (
        <SmartTableChoiceFilter
          value={filterData}
          onValueChange={setFilterData}
          choices={choices}
          isMultiChoice={!!isMultiChoice}
        />
      );
    }
  };
};
