import { useTranslation } from "react-i18next";
import Moment from "react-moment";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getAuthorizationHeader } from "../../../../amplify";
import { eventStatuses, eventTypes } from "../../../../data/event-constants";
import { eventsApi } from "../../../../gateway/events";
import {
  EventStatus,
  PhotographerEventSummary
} from "../../../../generated/swagger/events";
import { pageSelector } from "../../../../store";
import {
  photographerEventEditorNewUrl,
  photographerEventEditorUrl,
  photographerEventManageUrl
} from "../../../app-body";
import { SmartTableColumn } from "../../../smart-table/smart-table";
import {
  smartTableChoiceFilter,
  smartTableNumberRangeFilter,
  smartTableStringContainsFilter
} from "../../../smart-table/smart-table-filter";
import {
  smartTableNumberSorter,
  smartTableStringSorter
} from "../../../smart-table/smart-table-sort";
import TablePage, {
  ActionElement,
  EmptyTableData,
  FullTableData
} from "../../../table-page";
import ValidateModal from "../../../validate-modal/validate-modal";
import deleteIcon from "../../../../resources/delete.png";
import { getEventStatus } from "./utils/event-utils";
import { Button } from "picky-style";

function PhotographerEvents() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const pageState = useSelector(pageSelector);

  const getElements = async (): Promise<PhotographerEventSummary[]> => {
    const authorization = await getAuthorizationHeader();
    const photographerEventSummaries =
      //we dont expect pagination on early point, we will handle that later in the future
      await eventsApi.photographer.getPhotographerEvents(
        authorization,
        0,
        1000
      );

    return photographerEventSummaries;
  };

  const valueToKey = (
    photographerEventSummary: PhotographerEventSummary
  ): number => {
    return photographerEventSummary.id || -1;
  };

  const getEmptyTableData = (): EmptyTableData => {
    return {
      emptyTableTitle: t("pages.events.empty.title"),
      emptyTableSubTitle: t("pages.events.empty.sub-title"),
      emptyTableAddButtonClick: () => {
        navigate(photographerEventEditorNewUrl);
      }
    };
  };

  const getFullTableData = (): FullTableData<
    number,
    PhotographerEventSummary
  > => {
    const actionElements: ActionElement<number, PhotographerEventSummary>[] = [
      {
        buildElement: () => {
          return (
            <Button
              key="create-event-button"
              text={t("pages.events.actions.create")}
              onClick={() => navigate(photographerEventEditorNewUrl)}
              size="tiny"
              $loadable={false}
            />
          );
        }
      },
      {
        filter: (selectedValues) => {
          return selectedValues.length === 1;
        },
        buildElement: (selectedValues) => {
          const eventId = valueToKey(selectedValues[0]);

          return (
            <Button
              key="edit-event-button"
              text={t("pages.events.actions.edit")}
              onClick={() =>
                navigate(
                  photographerEventEditorUrl.replace("*", eventId.toString())
                )
              }
              size="tiny"
              $loadable={false}
            />
          );
        }
      },
      {
        filter: (selectedValues) => {
          return (
            selectedValues.length === 1 &&
            !selectedValues.find(
              (event) => event.event_status === EventStatus.Archived
            )
          );
        },
        buildElement: (selectedValues, currData, updateState) => {
          return (
            <ValidateModal
              key={"archive-event-button"}
              title={t("pages.events.confirmation.archive.title")}
              confirmationMessage={t(
                "pages.events.confirmation.archive.message"
              )}
              locale={{
                cancel: t("generic.actions.cancel"),
                confirm: t("generic.actions.confirm")
              }}
              text={t("pages.events.actions.archive")}
              onApprove={async () => {
                const authorization = await getAuthorizationHeader();
                const alteredEvents = [] as PhotographerEventSummary[];

                for (let i = 0; i < selectedValues.length; i++) {
                  alteredEvents.push(
                    await eventsApi.photographer.archivePhotographerEvent(
                      authorization,
                      valueToKey(selectedValues[i])
                    )
                  );
                }

                const clonedMap = new Map(currData);
                alteredEvents.forEach((alteredEvent) =>
                  clonedMap.set(valueToKey(alteredEvent), alteredEvent)
                );

                updateState(clonedMap, new Set<number>());
              }}
            />
          );
        }
      },
      {
        filter: (selectedValues) => {
          return (
            selectedValues.length === 1 &&
            !selectedValues.find((event) => {
              const eventStatus = event.event_status;

              return (
                eventStatus !== EventStatus.Draft &&
                eventStatus !== EventStatus.Archived
              );
            })
          );
        },
        buildElement: (selectedValues, currData, updateState) => {
          return (
            <ValidateModal
              key={"delete-event-button"}
              title={t("pages.events.confirmation.delete.title")}
              confirmationMessage={t(
                "pages.events.confirmation.delete.message"
              )}
              locale={{
                cancel: t("generic.actions.cancel"),
                confirm: t("generic.actions.confirm")
              }}
              text={<img src={deleteIcon} className={"delete-icon"} />}
              color="red"
              onApprove={async () => {
                const authorization = await getAuthorizationHeader();

                for (let i = 0; i < selectedValues.length; i++) {
                  await eventsApi.photographer.deletePhotographerEvent(
                    authorization,
                    valueToKey(selectedValues[i])
                  );
                }

                const clonedMap = new Map(currData);
                selectedValues.forEach((event) =>
                  clonedMap.delete(valueToKey(event))
                );

                updateState(clonedMap, new Set<number>());
              }}
            />
          );
        }
      }
    ];

    const columns: SmartTableColumn<PhotographerEventSummary>[] = [
      {
        name: t("pages.events.generic.name"),
        dataMapper: (event) => {
          return event.name;
        },
        filterBy: smartTableStringContainsFilter(
          (data: PhotographerEventSummary) => data.name
        ),
        sortBy: smartTableStringSorter(
          (data: PhotographerEventSummary) => data.name
        )
      },
      {
        name: t("pages.events.generic.event-time"),
        dataMapper: (event) => {
          return (
            <Moment format="DD/MM/YYYY">
              {new Date((event.event_time || 0) * 1000)}
            </Moment>
          );
        },
        filterBy: smartTableNumberRangeFilter(
          (data: PhotographerEventSummary) => data.event_time,
          true
        ),
        sortBy: smartTableNumberSorter(
          (data: PhotographerEventSummary) => data.event_time
        )
      },
      {
        name: t("pages.events.generic.event-type"),
        dataMapper: (event) => {
          return t(`pages.events.enums.type.${event.event_type || "unknown"}`);
        },
        filterBy: smartTableChoiceFilter(
          (data: PhotographerEventSummary) => data.event_type,
          eventTypes.map((eventType) => {
            return {
              label: t(`pages.events.enums.type.${eventType}`),
              value: eventType
            };
          }),
          true
        ),
        sortBy: smartTableStringSorter((data: PhotographerEventSummary) =>
          t(`pages.events.enums.type.${data.event_type}`)
        )
      },
      {
        name: t("pages.events.generic.location"),
        dataMapper: (event) => {
          return event.location;
        },
        showCondition: () => !pageState.isMobile,
        filterBy: smartTableStringContainsFilter(
          (data: PhotographerEventSummary) => data.location
        ),
        sortBy: smartTableStringSorter(
          (data: PhotographerEventSummary) => data.location
        )
      },
      {
        name: t("pages.events.generic.setup-location"),
        dataMapper: (event) => {
          return event.setup_location;
        },
        showCondition: () => !pageState.isMobile,
        filterBy: smartTableStringContainsFilter(
          (data: PhotographerEventSummary) => data.setup_location
        ),
        sortBy: smartTableStringSorter(
          (data: PhotographerEventSummary) => data.setup_location
        )
      },
      {
        name: t("pages.events.generic.number-of-invites"),
        dataMapper: (event) => {
          return event.number_of_invites;
        },
        showCondition: () => !pageState.isMobile,
        filterBy: smartTableNumberRangeFilter(
          (data: PhotographerEventSummary) => data.number_of_invites
        ),
        sortBy: smartTableNumberSorter(
          (data: PhotographerEventSummary) => data.number_of_invites
        )
      },
      {
        name: t("pages.events.generic.status"),
        dataMapper: (event) => {
          return getEventStatus(t, event.event_status, event.event_time);
        },
        filterBy: smartTableChoiceFilter(
          (data: PhotographerEventSummary) => data.event_status,
          eventStatuses.map((eventStatus) => {
            return {
              label: t(`pages.events.enums.status.${eventStatus}`),
              value: eventStatus
            };
          }),
          true
        ),
        sortBy: smartTableStringSorter(
          (data: PhotographerEventSummary) =>
            data.event_status as string | undefined
        ),
        defaultFilterData: eventStatuses.filter(
          (eventStatus) => eventStatus !== EventStatus.Archived
        )
      }
    ];

    const onDataClick = (data: PhotographerEventSummary) => {
      navigate(
        photographerEventManageUrl.replace(
          ":eventId",
          valueToKey(data).toString()
        )
      );
    };

    const onSearch = (data: PhotographerEventSummary, search: string) => {
      const date = new Date((data.event_time || 0) * 1000);
      const dateString =
        "0" +
        date.getDate() +
        "/" +
        "0" +
        (date.getMonth() + 1) +
        "/" +
        date.getFullYear();
      return (
        data.name?.toLocaleLowerCase()?.includes(search) ||
        data.customer_email?.toLocaleLowerCase()?.includes(search) ||
        dateString.includes(search) ||
        false
      );
    };

    return {
      actionElements,
      checkBoxColumn: true,
      columns,
      onDataClick,
      onSearch
    };
  };

  return (
    <TablePage
      getElements={getElements}
      valueToKey={valueToKey}
      getEmptyTableData={getEmptyTableData}
      getFullTableData={getFullTableData}
    />
  );
}

export default PhotographerEvents;
