import { ItemType, DiscountType } from "../generated/swagger/price-quotes";

export const itemTypes = [
  ItemType.CrewMember,
  ItemType.LinkedEvent,
  ItemType.Product,
  ItemType.Other
];

export const discountTypes = [DiscountType.Percentage, DiscountType.Fixed];
