import { Dropdown, Row, Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { LineItem } from "../../../../../../generated/swagger/price-quotes";
import { useState } from "react";
import threeDotsIcon from "../../../../../../resources/three-dots.png";
import editIcon from "../../../../../../resources/edit-line-items.png";
import trashIcon from "../../../../../../resources/trash-icon.png";
import PhotographerPriceQuoteItemModal from "./photographer-price-quote-item-modal";

interface PhotographerMobileLineItemTableProps {
  lineItems: LineItem[];
  showAddLineItemModal: boolean;
  closeAddLineItemModal: () => void;
  onChange: (lineItems: LineItem[]) => void;
}

function PhotographerMobileLineItemTable({
  lineItems,
  showAddLineItemModal,
  closeAddLineItemModal,
  onChange
}: PhotographerMobileLineItemTableProps) {
  const { t } = useTranslation();
  const [state, setState] = useState({
    dropDownIndex: -1,
    itemIndex: -1
  });

  const renderMobileLineItems = () =>
    lineItems?.map((data, index) => {
      const totalPrice =
        (data.price &&
          data.quantity &&
          Number((data.price * data.quantity).toFixed(2))) ||
        0;

      const showDropDown = state.dropDownIndex === index;

      return (
        <tr key={`line-item-row-${index}`}>
          <td></td>
          <td>{data.name}</td>
          <td>
            {t(
              `pages.price-quotes-items-templates.enums.type.${data["item-type"]}`
            )}
          </td>
          <td>{data.quantity}</td>
          <td>{data.price}</td>
          <td>{totalPrice + t("currencies.ils")}</td>
          <td>
            <Dropdown
              show={showDropDown}
              onToggle={() => {
                setState((currState) => {
                  return {
                    ...currState,
                    dropDownIndex: showDropDown ? -1 : index
                  };
                });
              }}
            >
              <Dropdown.Toggle
                id={`dropdown-custom-components-${index}`}
                style={{
                  border: "none",
                  background: "none",
                  width: "20px"
                }}
              >
                <img
                  src={threeDotsIcon}
                  alt="Toggle Button"
                  onClick={() => {
                    setState((currState) => {
                      return {
                        ...currState,
                        dropDownIndex: index
                      };
                    });
                  }}
                />
              </Dropdown.Toggle>

              <Dropdown.Menu
                style={{ borderRadius: "20px", textAlign: "start" }}
              >
                <Dropdown.Item
                  eventKey="1"
                  onClick={() => {
                    setState((currState) => {
                      return {
                        ...currState,
                        itemIndex: index
                      };
                    });
                  }}
                >
                  <>
                    <img
                      style={{
                        height: "24px",
                        width: "24px",
                        padding: "1px",
                        marginLeft: "20px"
                      }}
                      src={editIcon}
                      className="clickable"
                    />
                    {t("pages.price-quote-editor.actions.edit-item")}
                  </>
                </Dropdown.Item>
                <Row
                  className="divider"
                  style={{
                    maxWidth: "97%",
                    margin: "auto"
                  }}
                ></Row>
                <Dropdown.Item
                  eventKey="2"
                  onClick={() => {
                    const newLineItems = [...lineItems];
                    newLineItems.splice(index, 1);

                    onChange(newLineItems);
                  }}
                >
                  <>
                    <img
                      style={{
                        height: "22px",
                        width: "22px",
                        padding: "1px",
                        marginLeft: "22px"
                      }}
                      src={trashIcon}
                      className="clickable"
                    />
                    {t("pages.price-quote-editor.actions.delete-item")}
                  </>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </td>
        </tr>
      );
    });

  const showItemModal = showAddLineItemModal || state.itemIndex !== -1;

  return (
    <>
      {showItemModal && (
        <PhotographerPriceQuoteItemModal
          initialData={(() => {
            return state.itemIndex < 0 || state.itemIndex >= lineItems.length
              ? {}
              : lineItems[state.itemIndex];
          })()}
          onChange={(priceQuoteItem) => {
            if (priceQuoteItem) {
              const newLineItems = [...lineItems];

              // new line
              if (state.itemIndex === -1) {
                newLineItems.push(priceQuoteItem);
              } else {
                newLineItems[state.itemIndex] = priceQuoteItem;
              }

              onChange(newLineItems);
            }

            closeAddLineItemModal();

            setState((currState) => {
              return {
                ...currState,
                itemIndex: -1
              };
            });
          }}
        />
      )}

      <Table className="table-no-padding table-header-bg">
        <thead>
          <tr>
            <th scope="col" style={{ width: "2%" }}></th>
            <th
              className="label-margin-right"
              scope="col"
              style={{ width: "48%", color: "white" }}
            >
              {t(
                "components.photographer.price-quotes-form.generic.line-items.name"
              )}
            </th>
            <th scope="col" style={{ width: "17%", color: "white" }}>
              {t(
                "components.photographer.price-quotes-form.generic.line-items.type"
              )}
            </th>
            <th scope="col" style={{ width: "11%", color: "white" }}>
              {t(
                "components.photographer.price-quotes-form.generic.line-items.quantity"
              )}
            </th>
            <th scope="col" style={{ width: "11%", color: "white" }}>
              {t(
                "components.photographer.price-quotes-form.generic.line-items.price"
              )}
            </th>
            <th scope="col" style={{ width: "11%", color: "white" }}>
              {t(
                "components.photographer.price-quotes-form.generic.line-items.total"
              )}
            </th>
            <th></th>
          </tr>
        </thead>
        <tbody style={{ verticalAlign: "middle" }}>
          {renderMobileLineItems()}
        </tbody>
      </Table>
    </>
  );
}
export default PhotographerMobileLineItemTable;
