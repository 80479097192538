import { useDispatch } from "react-redux";
import logo from "../../resources/logo-orange.png";
import { showHamburgerMenu } from "../../store/reducers/small-nav-reducer";

function SmallNav() {
  const dispatch = useDispatch();

  return (
    <>
      <div
        className="navbar-hamburger-icon"
        onClick={() => {
          dispatch(showHamburgerMenu(true));
        }}
      ></div>
      <img src={logo} className="nav-bar-logo-small" alt="Picky Logo" />
    </>
  );
}

export default SmallNav;
