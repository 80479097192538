import { Table } from "react-bootstrap";
import { LineItem } from "../generated/swagger/price-quotes";
import { useTranslation } from "react-i18next";
import { CSSProperties } from "react";

interface PriceTableProps {
  offset?: number;
  header?: JSX.Element;
  maxHeight?: string; //only supply if you wish to have scroll bar
  theadClassName?: string;
  lineItems: LineItem[];
  additionalData?: JSX.Element;
}

function PriceTable({
  offset,
  header,
  maxHeight,
  theadClassName,
  lineItems,
  additionalData
}: PriceTableProps) {
  const { t } = useTranslation();

  const divStyle: CSSProperties = {};

  if (maxHeight) {
    divStyle["maxHeight"] = maxHeight;
    divStyle["overflow"] = "auto";
  }

  return (
    <>
      <div style={divStyle}>
        <Table
          size="sm"
          style={{
            width: "95%",
            margin: "auto",
            textAlign: "right"
          }}
          className="table-page-table"
        >
          <thead className={theadClassName}>
            {header && (
              <tr>
                <td colSpan={5}>{header}</td>
              </tr>
            )}
            <tr style={{ backgroundColor: "#2CBFC9" }}>
              <th style={{ width: "1%" }}></th>
              <th>{t("pages.price-quote-customer.generic.name")}</th>
              <th
                style={{
                  width: "60px"
                }}
              >
                {t("pages.price-quote-customer.generic.quantity")}
              </th>
              <th
                style={{
                  textAlign: "center",
                  width: "100px",
                  minWidth: "100px",
                  maxWidth: "100px"
                }}
              >
                {t("pages.price-quote-customer.generic.price")}
              </th>
              <th
                style={{
                  textAlign: "center",
                  width: "100px",
                  minWidth: "100px"
                }}
              >
                {t("pages.price-quote-customer.generic.total")}
              </th>
            </tr>
          </thead>
          <tbody>
            {lineItems?.map((item, i) => {
              return (
                <tr key={`price-table-row-${i + (offset || 0)}`}>
                  <td
                    style={{ textAlign: "center", backgroundColor: "#F4F4F4" }}
                  >
                    {++i + (offset || 0)}
                  </td>
                  <td>{item.name}</td>
                  <td>{item.quantity}</td>
                  <td
                    style={{
                      width: "auto",
                      textAlign: "center"
                    }}
                  >
                    {item.price + t("currencies.ils")}
                  </td>
                  <td
                    style={{
                      width: "auto",
                      textAlign: "center"
                    }}
                  >
                    {(item.price || 0) * (item.quantity || 0) +
                      t("currencies.ils")}
                  </td>
                </tr>
              );
            })}
            {additionalData && (
              <tr style={{ borderBottom: "hidden" }}>
                <td colSpan={5}>{additionalData}</td>
              </tr>
            )}
          </tbody>
        </Table>
      </div>
    </>
  );
}

export default PriceTable;
