import { useSelector } from "react-redux";
import { accountSelector } from "../../store";
import logo from "../../resources/logo.png";
import { useTranslation } from "react-i18next";
import { useAuthHook } from "../../hooks/auth-hook";
import { useNavigate } from "react-router";
import { profileSettingsUrl } from "../app-body";
import { Button } from "picky-style";

function BigNav() {
  const { t } = useTranslation();
  const account = useSelector(accountSelector);
  const navigate = useNavigate();
  const { customerLogin, photographerLogin } = useAuthHook();

  const fullName = () => {
    if (account?.given_name && account.family_name) {
      return `${account.given_name} ${account.family_name}`;
    }
    return account?.name;
  };

  if (account) {
    return (
      <>
        <div className="right-menu" style={{ backgroundColor: "#2cbfc9" }}>
          <img src={logo} className="nav-bar-logo" alt="Picky Logo" />
        </div>

        <div className="big-nav-login-container">
          <div className="big-nav-login">
            <label className="big-nav-account-label">{fullName()}</label>
            <img
              src={account.avatar}
              alt="Account Avatar"
              className="clickable"
              onClick={() => navigate(profileSettingsUrl)}
              width={61}
              height={61}
              referrerPolicy="no-referrer"
              style={{ borderRadius: "50%" }}
            />
          </div>
        </div>
      </>
    );
  } else {
    return (
      <>
        <div className="right-menu">
          <img src={logo} className="nav-bar-logo" alt="Picky Logo" />
        </div>
        <div className="big-nav-login">
          <Button
            onClick={photographerLogin}
            text={t("components.menu.auth.photographer-login")}
            $primary={false}
            size="small"
            $wide
          />
          <Button
            onClick={customerLogin}
            text={t("components.menu.auth.customer-login")}
            size="small"
            $wide
          />
        </div>
      </>
    );
  }
}

export default BigNav;
