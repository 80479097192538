import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import { AccountType } from "../generated/swagger/auth";
import { accountSelector, pageSelector } from "../store";
import MobilePageTitle from "./mobile-page-title";
import BigSideNav from "./navbar/big-side-nav";
import SmallSideNav from "./navbar/small-side-nav";
import CustomerEvents from "./pages/customer/customer-events";
import Home from "./pages/home";
import Login from "./pages/login";
import NotFound from "./pages/not-found";
import PhotographerEventEditor from "./pages/photographer/events/photographer-event-editor";
import PhotographerEventManage from "./pages/photographer/events/manage/photographer-event-manage";
import PhotographerEvents from "./pages/photographer/events/photographer-events";
import PhotographerPriceQuoteEditor from "./pages/photographer/price-quotes/editor/photographer-price-quote-editor";
import PhotographerPriceQuotesItemsTemplates from "./pages/photographer/price-quotes/items-templates/photographer-items-templates";
import PhotographerPriceQuotesTemplates from "./pages/photographer/price-quotes/templates/photographer-price-quotes-templates";
import PhotographerPriceQuotesTemplatesEditor from "./pages/photographer/price-quotes/templates/photographer-price-quotes-templates-editor";
import ProfileSettings from "./pages/profile-settings";

interface EnforcerProps {
  Component: React.FC;
  pageName?: string;
}

export const homePageUrl = "/";
export const loginPageUrl = "/login";
export const profileSettingsUrl = "/profile-settings";
export const photographerEventsUrl = "/photographer/events";
export const photographerEventEditorUrl = "/photographer/events/editor/*";
export const photographerEventEditorNewUrl = "/photographer/events/editor";
export const photographerEventManageUrl = "/photographer/events/:eventId";
export const photographerPriceQuoteEditorUrl =
  "/photographer/events/:eventId/price-quote";
export const photographerPriceQuotesItemsTemplatesUrl =
  "/photographer/price-quotes/items-template";
export const photographerPriceQuotesTemplatesUrl =
  "/photographer/price-quotes/templates";
export const photographerPriceQuotesTemplatesEditorUrl =
  "/photographer/price-quotes/templates/editor/*";
export const photographerPriceQuotesTemplatesEditorNewUrl =
  "/photographer/price-quotes/templates/editor";
export const customerEventsUrl = "/customer/events";

const photographerHomePage = photographerEventsUrl;
const customerHomePage = customerEventsUrl;

function AppBody() {
  const { t } = useTranslation();
  const location = useLocation();
  const account = useSelector(accountSelector);
  const pageState = useSelector(pageSelector);

  const showSideBar = account && !pageState.isMobile;
  const className = showSideBar ? "page-content-with-right-menu" : "";
  const isRegisterComplete = (() => {
    if (account?.type === AccountType.Photographer) {
      return (
        account.email &&
        account.phone &&
        account.photographer_data?.business_name &&
        account.photographer_data?.business_id
      );
    } else if (account?.type === AccountType.Customer) {
      return account.email && account.phone && account.customer_data;
    } else return true;
  })();

  const GuestEnforcer = ({ Component }: EnforcerProps) => {
    if (account) {
      if (account.type === AccountType.Customer) {
        return (
          <Navigate to={customerHomePage} replace state={{ from: location }} />
        );
      } else if (account.type === AccountType.Photographer) {
        return (
          <Navigate
            to={photographerHomePage}
            replace
            state={{ from: location }}
          />
        );
      } else {
        console.error("unknown account type", account.type);
        return <Navigate to="/" replace state={{ from: location }} />;
      }
    }

    return <Component />;
  };

  const PhotographerEnforcer = ({ Component, pageName }: EnforcerProps) => {
    if (!account || account.type !== AccountType.Photographer) {
      return <Navigate to="/" replace state={{ from: location }} />;
    } else if (
      !isRegisterComplete &&
      location.pathname !== profileSettingsUrl
    ) {
      return (
        <Navigate to={profileSettingsUrl} replace state={{ from: location }} />
      );
    }
    return (
      <>
        <MobilePageTitle pageName={pageName} />
        <Component />
      </>
    );
  };

  const CustomerEnforcer = ({ Component }: EnforcerProps) => {
    if (!account || account.type !== AccountType.Customer) {
      return <Navigate to="/" replace state={{ from: location }} />;
    } else if (
      !isRegisterComplete &&
      location.pathname !== profileSettingsUrl
    ) {
      return (
        <Navigate to={profileSettingsUrl} replace state={{ from: location }} />
      );
    }

    return <Component />;
  };

  const routes = useMemo(
    () => (
      <Routes>
        <Route
          path={homePageUrl}
          element={<GuestEnforcer Component={Home} />}
        />
        <Route
          path={loginPageUrl}
          element={<GuestEnforcer Component={Login} />}
        />
        <Route
          path={profileSettingsUrl}
          element={(() => {
            if (account?.type === AccountType.Photographer) {
              return (
                <PhotographerEnforcer
                  Component={ProfileSettings}
                  pageName={t("components.menu.photographer.profile-settings")}
                />
              );
            } else if (account?.type === AccountType.Customer) {
              return (
                <CustomerEnforcer
                  Component={ProfileSettings}
                  pageName={t("components.menu.customer.profile-settings")}
                />
              );
            } else return <></>;
          })()}
        />
        <Route
          path={photographerEventsUrl}
          element={
            <PhotographerEnforcer
              Component={PhotographerEvents}
              pageName={t("components.menu.photographer.events")}
            />
          }
        />
        <Route
          path={photographerEventEditorUrl}
          element={<PhotographerEnforcer Component={PhotographerEventEditor} />}
        />
        <Route
          path={photographerEventManageUrl}
          element={<PhotographerEnforcer Component={PhotographerEventManage} />}
        />
        <Route
          path={photographerPriceQuoteEditorUrl}
          element={
            <PhotographerEnforcer Component={PhotographerPriceQuoteEditor} />
          }
        />
        <Route
          path={photographerPriceQuotesItemsTemplatesUrl}
          element={
            <PhotographerEnforcer
              Component={PhotographerPriceQuotesItemsTemplates}
              pageName={t(
                "components.menu.photographer.price-quotes-items-templates"
              )}
            />
          }
        />
        <Route
          path={photographerPriceQuotesTemplatesEditorUrl}
          element={
            <PhotographerEnforcer
              Component={PhotographerPriceQuotesTemplatesEditor}
            />
          }
        />
        <Route
          path={photographerPriceQuotesTemplatesUrl}
          element={
            <PhotographerEnforcer
              Component={PhotographerPriceQuotesTemplates}
              pageName={t(
                "components.menu.photographer.price-quotes-templates"
              )}
            />
          }
        />
        <Route
          path={customerEventsUrl}
          element={<CustomerEnforcer Component={CustomerEvents} />}
        />
        <Route path="/404" element={<NotFound />} />
        <Route path="*" element={<Navigate replace to="/404" />} />
      </Routes>
    ),
    [location.key, location.pathname, account?.email, account?.type]
  );

  return (
    <div className="page-body">
      {showSideBar && <BigSideNav />}
      {!showSideBar && <SmallSideNav />}
      <div className={className}>{routes}</div>
    </div>
  );
}

export default AppBody;
