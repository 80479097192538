import { decode } from "base64-arraybuffer";
import { saveAs } from "file-saver";
import JSZip from "jszip";

export interface Base64RestResponse {
  data: string;
}

export const saveRestResponseFile = (
  base64: Base64RestResponse,
  fileName: string
) => {
  const arraybuffer = decode(base64.data);
  const blob = new Blob([arraybuffer]);

  saveAs(blob, fileName);
};
export interface FilesToZipped {
  name: string;
  url: string;
}

export const saveFilesAsZip = async (
  files: FilesToZipped[],
  zipFileName: string
) => {
  const zip = new JSZip();

  const asyncFiles = files.map(async (file) => {
    if (!file.name || !file.url) {
      return;
    }
    const response = await fetch(file.url, {
      method: "GET",
      mode: "cors"
    });

    zip.file(file.name, response.blob());
  });

  await Promise.all(asyncFiles);

  const zipBlob = await zip.generateAsync({ type: "blob" });
  saveAs(zipBlob, zipFileName);
};

export const base64ToByteArray = async (base64: string): Promise<number[]> =>
  Array.from(
    new Uint8Array(
      await (
        await fetch(
          `data:application/octet-stream;base64,${base64.split(",")[1]}`
        )
      ).arrayBuffer()
    )
  );
