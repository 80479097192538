import { useEffect, useState } from "react";
import { Col, Form, Modal, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { getAuthorizationHeader } from "../../../../../../amplify";
import { itemTypes } from "../../../../../../data/price-quotes-constants";
import { priceQuotesItemsApi } from "../../../../../../gateway/price-quotes/price-quotes-items-templates";
import { PriceQuoteItem } from "../../../../../../generated/swagger/price-quotes";
import filter from "../../../../../smart-table/resources/filter.png";
import SmartTable, {
  SmartTableColumn
} from "../../../../../smart-table/smart-table";
import {
  smartTableChoiceFilter,
  smartTableNumberRangeFilter,
  smartTableStringContainsFilter
} from "../../../../../smart-table/smart-table-filter";
import {
  smartTableNumberSorter,
  smartTableStringSorter
} from "../../../../../smart-table/smart-table-sort";
import { Button } from "picky-style";

interface PhotographerPriceQuotesItemPickerModalProps {
  show: boolean;
  onItemsPick: (items: PriceQuoteItem[]) => void;
}

function PhotographerPriceQuotesItemPickerModal({
  show,
  onItemsPick
}: PhotographerPriceQuotesItemPickerModalProps) {
  const { t } = useTranslation();

  const [itemPicker, setItemPicker] = useState({
    data: new Map<string, PriceQuoteItem>(),
    search: "",
    showFilterModal: false,
    selected: new Set<string>()
  });

  const getElements = async (): Promise<PriceQuoteItem[]> => {
    const authorization = await getAuthorizationHeader();
    const photographerPriceQuotesItemsTemplates =
      //we don't expect pagination on early point, we will handle that later in the future
      await priceQuotesItemsApi.photographer.getPhotographerPriceQuotesItemsTemplates(
        authorization,
        0,
        1000
      );

    return photographerPriceQuotesItemsTemplates;
  };

  const valueToKey = (
    photographerPriceQuotesItemsTemplates: PriceQuoteItem
  ): string => {
    return photographerPriceQuotesItemsTemplates.name || "";
  };

  useEffect(() => {
    getElements().then((priceQuoteItems) => {
      setItemPicker((curr) => {
        return {
          ...curr,
          data: new Map<string, PriceQuoteItem>(
            priceQuoteItems.map((priceQuoteItem) => [
              valueToKey(priceQuoteItem),
              priceQuoteItem
            ])
          )
        };
      });
    });
  }, []);

  const onSelectedValue = (checked: boolean, value: PriceQuoteItem) => {
    const key = valueToKey(value);
    const clonedSelected = new Set(itemPicker.selected);

    checked ? clonedSelected.add(key) : clonedSelected.delete(key);

    setItemPicker((curr) => {
      return {
        ...curr,
        selected: clonedSelected
      };
    });
  };

  const columns: SmartTableColumn<PriceQuoteItem>[] = [
    {
      name: t("pages.price-quotes-items-templates.generic.name"),
      headerStyle: { textAlign: "start" },
      dataStyle: { textAlign: "start" },
      dataMapper: (item) => {
        return (
          <div
            onClick={() =>
              onSelectedValue(!itemPicker.selected.has(valueToKey(item)), item)
            }
            style={{ display: "flex", gap: "5px", alignItems: "center" }}
          >
            <input
              type="checkbox"
              onChange={(e) => onSelectedValue(e.target.checked, item)}
              checked={itemPicker.selected.has(valueToKey(item))}
            />
            <label>{item.name}</label>
          </div>
        );
      },
      filterBy: smartTableStringContainsFilter(
        (data: PriceQuoteItem) => data.name
      ),
      sortBy: smartTableStringSorter((data: PriceQuoteItem) => data.name)
    },
    {
      name: t("pages.price-quotes-items-templates.generic.item-type"),
      dataMapper: (item) => {
        return t(
          `pages.price-quotes-items-templates.enums.type.${
            item["item-type"] || "unknown"
          }`
        );
      },
      filterBy: smartTableChoiceFilter(
        (data: PriceQuoteItem) => data["item-type"],
        itemTypes.map((itemType) => {
          return {
            label: t(
              `pages.price-quotes-items-templates.enums.type.${
                itemType || "unknown"
              }`
            ),
            value: itemType
          };
        }),
        true
      ),
      sortBy: smartTableStringSorter((data: PriceQuoteItem) =>
        t(
          `pages.price-quotes-items-templates.enums.type.${
            data["item-type"] || "unknown"
          }`
        )
      )
    },
    {
      name: t("pages.price-quotes-items-templates.generic.price"),
      dataMapper: (item) => {
        return String(item.price) + t("currencies.ils");
      },
      filterBy: smartTableNumberRangeFilter(
        (data: PriceQuoteItem) => data.price
      ),
      sortBy: smartTableNumberSorter((data: PriceQuoteItem) => data.price)
    }
  ];

  const data = [...itemPicker.data.values()];
  const filteredData =
    itemPicker.search.length > 0
      ? data.filter((curr) => {
          const searchToLower = itemPicker.search.toLocaleLowerCase();

          return (
            curr.name?.toLocaleLowerCase()?.includes(searchToLower) ||
            (curr["item-type"] &&
              t(
                `pages.price-quotes-items-templates.enums.type.${
                  curr["item-type"] || "unknown"
                }`
              )
                .toLocaleLowerCase()
                .includes(searchToLower)) ||
            false
          );
        })
      : data;

  return (
    <Modal show={show} size="lg">
      <Modal.Header>
        <Modal.Title className="modal-title">
          {t("components.photographer.price-quotes-item-picker-modal.title")}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row className="align-items-center">
          <Col>
            <Form.Control
              type="search"
              className="search"
              placeholder={t(
                "components.photographer.price-quotes-item-picker-modal.search-placeholder"
              )}
              onChange={(e) =>
                setItemPicker((curr) => {
                  return { ...curr, search: e.target.value };
                })
              }
              value={itemPicker.search}
            />
          </Col>
          <Col xs="auto">
            <Button
              $loadable={false}
              color="turquoise"
              text={
                <>
                  <img src={filter} className="table-page-filter-icon" />
                  {t("components.smart-table.actions.filter")}
                </>
              }
              size="tiny"
              onClick={() =>
                setItemPicker((curr) => {
                  return {
                    ...curr,
                    showFilterModal: true
                  };
                })
              }
            />
          </Col>
        </Row>
        <br />
        <Row>
          <h5>
            <b>
              {t(
                "components.photographer.price-quotes-item-picker-modal.line-items.title"
              )}
            </b>
          </h5>
        </Row>
        <div className="table-wrap">
          <SmartTable
            keyId="item-picker-modal-line-items"
            className="modal-table"
            data={filteredData}
            columns={columns}
            customFilter={{
              showSmartTableFilter: false,
              onFilterClose: () => {
                setItemPicker((curr) => {
                  return {
                    ...curr,
                    showFilterModal: false
                  };
                });
              },
              showFilterModal: itemPicker.showFilterModal
            }}
          />
        </div>
        <br />
        <div className="center">
          <Button
            text={t(
              "components.photographer.price-quotes-item-picker-modal.actions.cancel"
            )}
            onClick={() => onItemsPick([])}
            color="grey"
          />
          <Button
            text={t(
              "components.photographer.price-quotes-item-picker-modal.actions.add"
            )}
            onClick={() => {
              onItemsPick(
                [...itemPicker.selected].map((key) => {
                  return { ...itemPicker.data.get(key)!, quantity: 1 };
                })
              );

              setItemPicker((curr) => {
                return {
                  ...curr,
                  selected: new Set<string>()
                };
              });
            }}
          />
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default PhotographerPriceQuotesItemPickerModal;
