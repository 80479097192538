import { Dispatch } from "@reduxjs/toolkit";
import { Amplify, Auth } from "aws-amplify";
import { Account, AccountType } from "./generated/swagger/auth";
import { setAccount } from "./store/reducers/account-reducer";
import { cognito } from "./constants";
import isEqual from "lodash/isEqual";

const config = {
  Auth: {
    identityPoolId: cognito.identityPoolId,
    region: cognito.region,
    identityPoolRegion: cognito.identityPoolRegion,
    userPoolId: cognito.userPoolId,
    userPoolWebClientId: cognito.userPoolWebClientId,
    oauth: {
      domain: cognito.domain,
      scope: [
        "phone",
        "email",
        "profile",
        "openid",
        "aws.cognito.signin.user.admin"
      ],
      redirectSignIn: cognito.redirectSignIn,
      redirectSignOut: cognito.redirectSignOut,
      responseType: "code" // or 'token', note that REFRESH token will only be generated when the responseType is code
    }
  }
};

Amplify.configure(config);
Auth.configure(config);

export const getCurrentAccount = (
  dispatch: Dispatch,
  currAccount: Account | undefined,
  accountType: AccountType
) => {
  return Auth.currentUserInfo().then((userInfo) => {
    const attributes = userInfo.attributes;

    const account: Account = {
      email: attributes.email,
      phone: attributes.phone_number,
      name: attributes.name,
      avatar: attributes.picture,
      given_name: attributes.given_name,
      family_name: attributes.family_name,
      type: accountType,
      photographer_data: {
        business_id: attributes["custom:business_id"],
        business_name: attributes["custom:business_name"],
        business_logo: attributes["custom:business_logo"]
      },
      customer_data: {}
    };

    if (!isEqual(account, currAccount)) {
      dispatch(setAccount(account));
    }
    return account;
  });
};

export const getAuthorizationHeader = async (): Promise<string> => {
  try {
    const jwtToken = (await Auth.currentSession()).getIdToken().getJwtToken();

    return jwtToken;
  } catch (e) {
    Auth.signOut();
    throw e;
  }
};

export const updateCognitoAttributes = async (
  dispatch: Dispatch,
  account: Account,
  attributes: {
    [key: string]: string | undefined;
  }
): Promise<Account | undefined> => {
  return Auth.currentAuthenticatedUser().then((user) => {
    return Auth.updateUserAttributes(user, attributes).then(() =>
      getCurrentAccount(dispatch, account, account.type)
    );
  });
};
