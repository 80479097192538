import { Hub, Auth } from "aws-amplify";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCurrentAccount } from "../amplify";
import { AccountType } from "../generated/swagger/auth";
import { accountSelector } from "../store";
import { logout } from "../store/reducers/account-reducer";

interface CognitoProps {
  children?: React.ReactNode;
}

function Cognito({ children }: React.PropsWithChildren<CognitoProps>) {
  const dispatch = useDispatch();
  const account = useSelector(accountSelector);
  const [isReady, setReady] = useState(account === undefined);

  const getAccountType = (): AccountType => {
    return (localStorage.getItem("accountType") ||
      AccountType.Customer) as AccountType;
  };

  useEffect(() => {
    const unsubscribe = Hub.listen("auth", ({ payload: { event } }) => {
      switch (event) {
        case "signIn":
          getCurrentAccount(dispatch, account, getAccountType());
          break;
        case "signOut":
          dispatch(logout());
          break;
      }
    });

    if (account) {
      Auth.currentAuthenticatedUser()
        .then(() => {
          getCurrentAccount(dispatch, account, account.type).then(() =>
            setReady(true)
          );
        })
        .catch(() => {
          dispatch(logout());
          setReady(true);
        });
    } else if (!isReady) {
      setReady(true);
    }

    return unsubscribe;
  }, []);

  return <>{isReady && children}</>;
}

export default Cognito;
