import { Form, Dropdown, Button } from "react-bootstrap";
import { DayPicker } from "react-day-picker";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { he } from "date-fns/locale";
import "react-day-picker/dist/style.css";
import moment from "moment";

interface FormDateInputProps {
  required?: boolean;
  disabled?: boolean;
  value?: number;
  placeholder?: string;
  onChange: (newValue?: number) => void;
}

function FormDateInput({
  required,
  disabled,
  value,
  placeholder,
  onChange
}: FormDateInputProps) {
  const { t } = useTranslation();
  const [openDatePicker, setOpenDatePicker] = useState(false);

  return (
    <Form.Group
      onBlur={(e) => {
        if (!openDatePicker) return;

        const isChild = e.currentTarget.contains(e.relatedTarget);
        !isChild && setOpenDatePicker(false);
      }}
    >
      <Form.Control
        required={required}
        disabled={disabled}
        type="text"
        placeholder={placeholder}
        value={value ? moment(value * 1000).format("DD/MM/YYYY") : ""}
        onClick={() => !openDatePicker && setOpenDatePicker(true)}
        onChange={() => {
          // do nothing, this change should come from the outside via props
        }}
      />
      <Dropdown.Menu show={openDatePicker} className="dropdown-inside">
        <Dropdown.Item>
          <DayPicker
            locale={he}
            mode="single"
            selected={value ? new Date(value) : new Date()}
            onSelect={(newValue) => {
              onChange((newValue?.getTime() || Date.now()) / 1000);
              setOpenDatePicker(false);
            }}
            captionLayout="dropdown"
            fromYear={1970}
            toYear={2100}
            footer={
              <Button
                onClick={() => {
                  onChange(undefined);
                  setOpenDatePicker(false);
                }}
              >
                {t("generic.actions.clear")}
              </Button>
            }
          />
        </Dropdown.Item>
      </Dropdown.Menu>
    </Form.Group>
  );
}

export default FormDateInput;
