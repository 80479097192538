import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import { showHamburgerMenu } from "../../store/reducers/small-nav-reducer";

interface NavLinkProps {
  isMobile: boolean;
  name: string;
  url: string;
  icon: string;
  iconClicked: string;
}

function NavIconLink({ isMobile, name, url, icon, iconClicked }: NavLinkProps) {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const isClicked = location.pathname === url;
  const iconSrc = isClicked ? iconClicked : icon;
  const className = isClicked ? "clicked" : undefined;

  return (
    <a
      className={className}
      href="#"
      onClick={(e) => {
        e.preventDefault();
        navigate(url);

        if (isMobile) {
          dispatch(showHamburgerMenu(false));
        }
      }}
    >
      <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
        <img src={iconSrc} width="17px" height="17px" />
        <div style={{ justifyContent: "center" }}>{name}</div>
      </div>
    </a>
  );
}

export default NavIconLink;
