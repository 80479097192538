import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export const smallNavSlice = createSlice({
  name: "smallNavStore",
  initialState: {
    showHamburgerMenu: false
  },
  reducers: {
    showHamburgerMenu: (state, action: PayloadAction<boolean>) => {
      state.showHamburgerMenu = action.payload;
    }
  }
});

export const { showHamburgerMenu } = smallNavSlice.actions;
