import { Upload } from "antd";
import type { UploadFile } from "antd";
import { Modal, ModalBody } from "react-bootstrap";
import uploadIcon from "../resources/upload-icon.png";
import { Button } from "picky-style";
import md5 from "blueimp-md5";
import { base64ToByteArray } from "../utils/base64ToFile";

export interface UploadFileModalLocale {
  upload: string;
  types: string;
  genericError: string;
  typeError: string;
  sizeError: string;
  approve: string;
  cancel: string;
}
interface UploadFileModalProps {
  title: string;
  fileList?: UploadFile[];
  locale: UploadFileModalLocale;
  maxCount?: number;
  onRemove?: (id: string) => void | Promise<void>;
  onUpload: (files: File) => Promise<boolean>;
  onApprove: () => Promise<void> | void;
  onClose: () => void;
}

const UploadFileModal = ({
  title,
  fileList,
  locale,
  maxCount,
  onRemove,
  onUpload,
  onApprove,
  onClose
}: UploadFileModalProps) => {
  const fileUploadComponent = (() => {
    const icon = uploadIcon;
    const text = locale.upload;
    const types = locale.types;

    return (
      <div
        style={{
          textAlign: "center"
        }}
      >
        <img src={icon} />
        <br />
        <br />
        <b>{text}</b>
        <br />
        <b>{types}</b>
      </div>
    );
  })();

  return (
    <Modal show>
      <Modal.Header>
        <Modal.Title className="modal-title">{title}</Modal.Title>
      </Modal.Header>
      <ModalBody>
        <Upload.Dragger
          style={{ backgroundColor: "#F8F8FF" }}
          defaultFileList={fileList}
          beforeUpload={(file) => {
            return onUpload(file).then((suc) => !suc);
          }}
          multiple={true}
          listType="picture"
          maxCount={maxCount || 5}
          onRemove={(file) => {
            if (!file.uid.includes("rc-upload")) onRemove && onRemove(file.uid);
            else {
              const reader = new FileReader();
              reader.onloadend = () => {
                const base64 = reader.result as string;
                const byteArray = base64ToByteArray(base64);
                onRemove && onRemove(md5(byteArray as unknown as string));
              };
              reader.readAsDataURL(file.originFileObj!);
            }
          }}
        >
          {fileUploadComponent}
        </Upload.Dragger>
      </ModalBody>
      <Modal.Footer style={{ margin: "auto" }}>
        <div style={{ display: "flex" }}>
          <Button onClick={onClose} color="grey" text={locale.cancel} />
          <Button
            onClick={onApprove}
            text={locale.approve}
            disabled={fileList?.length === 0}
          />
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default UploadFileModal;
