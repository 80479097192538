import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface PageState {
  isReady: boolean;
  isMobile: boolean;
}

const initialState: PageState = {
  isReady: false,
  isMobile: false
};

export const pageSlice = createSlice({
  name: "pageStore",
  initialState,
  reducers: {
    isMobile: (state, action: PayloadAction<boolean>) => {
      state.isMobile = action.payload;
      state.isReady = true;
    }
  }
});

export const { isMobile } = pageSlice.actions;
