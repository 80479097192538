import { useTranslation } from "react-i18next";
import Moment from "react-moment";
import { useNavigate } from "react-router-dom";
import { getAuthorizationHeader } from "../../../../../amplify";
import { priceQuotesTemplateApi } from "../../../../../gateway/price-quotes/price-quotes-templates";
import { PriceQuoteTemplateSummary } from "../../../../../generated/swagger/price-quotes";
import {
  photographerPriceQuotesTemplatesEditorNewUrl,
  photographerPriceQuotesTemplatesEditorUrl
} from "../../../../app-body";
import { SmartTableColumn } from "../../../../smart-table/smart-table";
import {
  smartTableNumberRangeFilter,
  smartTableStringContainsFilter
} from "../../../../smart-table/smart-table-filter";
import {
  smartTableNumberSorter,
  smartTableStringSorter
} from "../../../../smart-table/smart-table-sort";
import TablePage, {
  ActionElement,
  EmptyTableData,
  FullTableData
} from "../../../../table-page";
import ValidateModal from "../../../../validate-modal/validate-modal";
import deleteIcon from "../../../../../resources/delete.png";
import { Button } from "picky-style";

function PhotographerPriceQuotesTemplates() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const getElements = async (): Promise<PriceQuoteTemplateSummary[]> => {
    const authorization = await getAuthorizationHeader();
    const photographerPriceQuotesTemplatesSummaries =
      //we don't expect pagination on early point, we will handle that later in the future
      await priceQuotesTemplateApi.photographer.getPhotographerPriceQuotesTemplates(
        authorization,
        0,
        1000
      );

    return photographerPriceQuotesTemplatesSummaries;
  };

  const valueToKey = (
    photographerPriceQuoteTemplateSummary: PriceQuoteTemplateSummary
  ): string => {
    return photographerPriceQuoteTemplateSummary.name || "";
  };

  const getEmptyTableData = (): EmptyTableData => {
    return {
      emptyTableTitle: t("pages.price-quotes-templates.empty.title"),
      emptyTableSubTitle: t("pages.price-quotes-templates.empty.sub-title"),
      emptyTableAddButtonClick: () => {
        navigate(photographerPriceQuotesTemplatesEditorNewUrl);
      }
    };
  };

  const getFullTableData = (): FullTableData<
    string,
    PriceQuoteTemplateSummary
  > => {
    const actionElements: ActionElement<string, PriceQuoteTemplateSummary>[] = [
      {
        buildElement: () => {
          return (
            <Button
              key="create-price-quote-template-button"
              text={t("pages.price-quotes-templates.actions.create")}
              onClick={() =>
                navigate(photographerPriceQuotesTemplatesEditorNewUrl)
              }
              size="tiny"
              $loadable={false}
            />
          );
        }
      },
      {
        filter: (selectedValues) => {
          return selectedValues.length === 1;
        },
        buildElement: (selectedValues) => {
          const templateName = valueToKey(selectedValues[0]);

          return (
            <Button
              key="edit-price-quote-template-button"
              text={t("pages.price-quotes-templates.actions.edit")}
              onClick={() =>
                navigate(
                  photographerPriceQuotesTemplatesEditorUrl.replace(
                    "*",
                    templateName
                  )
                )
              }
              size="tiny"
              $loadable={false}
            />
          );
        }
      },
      {
        filter: (selectedValues) => {
          return selectedValues.length === 1;
        },
        buildElement: (selectedValues, currData, updateState) => {
          return (
            <ValidateModal
              key={"delete-template-button"}
              title={t(
                "pages.price-quotes-templates.confirmation.delete.title"
              )}
              confirmationMessage={t(
                "pages.price-quotes-templates.confirmation.delete.message"
              )}
              locale={{
                cancel: t("generic.actions.cancel"),
                confirm: t("generic.actions.confirm")
              }}
              text={<img src={deleteIcon} className={"delete-icon"} />}
              color="red"
              onApprove={async () => {
                const authorization = await getAuthorizationHeader();

                for (let i = 0; i < selectedValues.length; i++) {
                  await priceQuotesTemplateApi.photographer.deletePhotographerPriceQuotesTemplate(
                    authorization,
                    valueToKey(selectedValues[i])
                  );
                }

                const clonedMap = new Map(currData);
                selectedValues.forEach((template) =>
                  clonedMap.delete(valueToKey(template))
                );

                updateState(clonedMap, new Set<string>());
              }}
            />
          );
        }
      }
    ];

    const columns: SmartTableColumn<PriceQuoteTemplateSummary>[] = [
      {
        name: t("pages.price-quotes-templates.generic.name"),
        dataMapper: (template) => {
          return template.name;
        },
        filterBy: smartTableStringContainsFilter(
          (data: PriceQuoteTemplateSummary) => data.name
        ),
        sortBy: smartTableStringSorter(
          (data: PriceQuoteTemplateSummary) => data.name
        )
      },
      {
        name: t("pages.price-quotes-templates.generic.last-update-time"),
        dataMapper: (template) => {
          return (
            <Moment format="DD/MM/YYYY">
              {new Date(template["last-update"] || 0)}
            </Moment>
          );
        },
        filterBy: smartTableNumberRangeFilter(
          (data: PriceQuoteTemplateSummary) => data["last-update"],
          true
        ),
        sortBy: smartTableNumberSorter(
          (data: PriceQuoteTemplateSummary) => data["last-update"]
        )
      },
      {
        name: t("pages.price-quotes-templates.generic.number-of-items"),
        dataMapper: (template) => {
          return template["items-count"];
        },
        filterBy: smartTableNumberRangeFilter(
          (data: PriceQuoteTemplateSummary) => data["items-count"]
        ),
        sortBy: smartTableNumberSorter(
          (data: PriceQuoteTemplateSummary) => data["items-count"]
        )
      },
      {
        name: t("pages.price-quotes-templates.generic.total-price"),
        dataMapper: (template) => {
          return t("currencies.ils") + template["total-price"]?.toFixed(2);
        },
        filterBy: smartTableNumberRangeFilter(
          (data: PriceQuoteTemplateSummary) => data["total-price"]
        ),
        sortBy: smartTableNumberSorter(
          (data: PriceQuoteTemplateSummary) => data["total-price"]
        )
      }
    ];

    const onSearch = (data: PriceQuoteTemplateSummary, search: string) => {
      return data.name?.toLocaleLowerCase()?.includes(search) || false;
    };

    return {
      actionElements,
      checkBoxColumn: true,
      columns,
      onSearch
    };
  };

  return (
    <TablePage
      getElements={getElements}
      valueToKey={valueToKey}
      getEmptyTableData={getEmptyTableData}
      getFullTableData={getFullTableData}
    />
  );
}

export default PhotographerPriceQuotesTemplates;
