function CustomerEvents() {
  return (
    <>
      <table align="center">
        <tr>
          <td>האירועים שלי</td>
        </tr>
      </table>
    </>
  );
}

export default CustomerEvents;
