import { Col, Form, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { PhotographerEventSummary as PhotographerEventSummarySwagger } from "../../../../../generated/swagger/events";
import FormDateInput from "../../../../form-date-input";
import { formatPhoneNumber } from "react-phone-number-input";

export interface PhotographerEventSummaryProps {
  eventSummary: PhotographerEventSummarySwagger;
}

function PhotographerEventSummary({
  eventSummary
}: PhotographerEventSummaryProps) {
  const { t } = useTranslation();

  return (
    <div style={{ width: "95%", margin: "auto" }}>
      <Row className="align-items-center">
        <Col xs={3} className="my-1 col-padding">
          <Form.Group className="align-right">
            <Form.Label className="label-margin-right">
              <b>{t("pages.events.generic.name")}</b>
            </Form.Label>
            <Form.Control
              disabled
              type="text"
              value={eventSummary.name || ""}
            />
          </Form.Group>
        </Col>
        <Col xs={3} className="my-1 col-padding">
          <Form.Group className="align-right">
            <Form.Label className="label-margin-right">
              <b>{t("pages.events.generic.location")}</b>
            </Form.Label>

            <Form.Control
              disabled
              type="text"
              value={eventSummary.location || ""}
            />
          </Form.Group>
        </Col>
        <Col xs={3} className="my-1 col-padding">
          <Form.Group className="align-right">
            <Form.Label className="label-margin-right">
              <b>{t("pages.events.generic.setup-location")}</b>
            </Form.Label>
            <Form.Control
              disabled
              type="text"
              value={eventSummary.setup_location || ""}
            />
          </Form.Group>
        </Col>
        <Col xs={3} className="my-1 col-padding">
          <Form.Group className="align-right">
            <Form.Label className="label-margin-right">
              <b>{t("pages.events.generic.event-time")}</b>
            </Form.Label>
            <FormDateInput
              disabled
              placeholder={t("pages.events.generic.event-time")}
              value={eventSummary.event_time}
              onChange={() => {
                // do nothing - this is a view only component
              }}
            />
          </Form.Group>
        </Col>
      </Row>

      <Row className="align-items-center">
        <Col xs={3} className="my-1 col-padding">
          <Form.Group className="align-right">
            <Form.Label className="label-margin-right">
              <b>{t("pages.events.generic.number-of-invites")}</b>
            </Form.Label>
            <Form.Control
              disabled
              type="number"
              value={eventSummary.number_of_invites || ""}
            />
          </Form.Group>
        </Col>
        <Col xs={3} className="my-1 col-padding">
          <Form.Group className="align-right">
            <Form.Label className="label-margin-right">
              <b>{t("pages.events.generic.customer-phone")}</b>
            </Form.Label>
            <Form.Control
              disabled
              type="text"
              value={formatPhoneNumber(eventSummary.customer_phone || "")}
            />
          </Form.Group>
        </Col>
        <Col xs={3} className="my-1 col-padding">
          <Form.Group className="align-right">
            <Form.Label className="label-margin-right">
              <b>{t("pages.events.generic.customer-email")}</b>
            </Form.Label>
            <Form.Control
              disabled
              type="email"
              placeholder={t("pages.events.generic.customer-email")}
              value={eventSummary.customer_email || ""}
            />
          </Form.Group>
        </Col>
        <Col xs={3} className="my-1 col-padding">
          <Form.Group className="align-right">
            <Form.Label className="label-margin-right">
              <b>{t("pages.events.generic.event-type")}</b>
            </Form.Label>
            <Form.Control
              disabled
              name="event-type-select"
              placeholder={t("pages.events.generic.event-type")}
              value={t(`pages.events.enums.type.${eventSummary.event_type}`)}
            />
          </Form.Group>
        </Col>
      </Row>
    </div>
  );
}

export default PhotographerEventSummary;
